<h2 mat-dialog-title>Canal {{ data?.nombre || data?.numero }}</h2>
<mat-dialog-content class="mat-typography column-center">
  @for (c of data?.ids; track c) {
    <div>
      <span class="key">ID:</span>
      <span class="value" style="margin-left: 1%">{{ c.id }}</span>
    </div>
    <div>
      <span class="key">Width:</span>
      <span class="value" style="margin-left: 1%">{{ c.width }}</span>
    </div>
    <div>
      <span class="key">Height:</span>
      <span class="value" style="margin-left: 1%">{{ c.height }}</span>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <app-button
    matType="mat-fab extended"
    color="warn"
    mat-dialog-close
    text="Volver"
    icon="arrow_back"
  ></app-button>
</mat-dialog-actions>
