import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ICliente,
  IListado,
  IQueryParam,
  IProveedor,
  IFilter,
  TipoRecordatorio,
  IRecordatorio,
  ICreateRecordatorio,
  IUpdateRecordatorio,
  IActivo,
} from 'modelos/src';
import { firstValueFrom, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RecordatoriosService } from '../recordatorios.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';

@Component({
  selector: 'app-crear-editar-recordatorio',
  templateUrl: './crear-editar-recordatorio.component.html',
  styleUrl: './crear-editar-recordatorio.component.scss',
  standalone: false,
})
export class CrearEditarRecordatorioComponent implements OnInit, OnDestroy {
  public id?: string;
  public title?: string;
  public name = CrearEditarRecordatorioComponent.name;
  public form?: FormGroup;
  public tipo?: string[];
  public tipos: TipoRecordatorio[] = ['km', 'fecha'];
  public recordatorio: IRecordatorio;
  public vehiculos?: IActivo[] = [];
  public vehiculos$?: Subscription;
  public proveedores?: IProveedor[] = [];
  public proveedores$?: Subscription;

  public clientes?: ICliente[];
  public clientes$?: Subscription;

  public filter: IFilter<IProveedor> = {};

  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };
  public filterVehiculos: IFilter<IActivo> = {};
  public queryVehiculos: IQueryParam = {
    filter: JSON.stringify(this.filterVehiculos),
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };
  public loading = true;
  constructor(
    @Optional()
    public dialogRef: MatDialogRef<CrearEditarRecordatorioComponent>,
    private route: ActivatedRoute,
    private listados: ListadosService,
    public helper: HelperService,
    public dialog: MatDialog,
    private service: RecordatoriosService,
    private dialogService: DialogService,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      tipo: new FormControl(this.recordatorio?.tipo, Validators.required),
      idCliente: new FormControl(
        this.recordatorio?.idCliente,
        Validators.required,
      ),
      idActivo: new FormControl(
        this.recordatorio?.idActivo,
        Validators.required,
      ),
      fechaLimite: new FormControl(this.recordatorio?.fechaLimite),
      kmLimite: new FormControl(
        this.recordatorio?.kmLimite,
        Validators.pattern('^[0-9]*$'),
      ),
      detallesDelMantenimiento: new FormControl(
        this.recordatorio?.detallesDelMantenimiento,
        Validators.required,
      ),
      repetible: new FormControl(this.recordatorio?.repetible),
      frecuencia: new FormControl(this.recordatorio?.frecuencia),
    });
  }

  private async listarVehiculos() {
    this.vehiculos$?.unsubscribe();
    this.vehiculos$ = this.listados
      .subscribe<IListado<IActivo>>('vehiculos', this.queryVehiculos)
      .subscribe((data) => {
        this.vehiculos = data.datos;
        console.log(`listado de vehiculos`, data);
      });
    await this.listados.getLastValue('vehiculos', this.queryVehiculos);
  }

  private async listarProveedores() {
    this.proveedores$?.unsubscribe();
    this.proveedores$ = this.listados
      .subscribe<IListado<IProveedor>>('proveedores', this.query)
      .subscribe((data) => {
        this.proveedores = data.datos;
        console.log(`listado de proveedores`, data);
      });
    await this.listados.getLastValue('proveedores', this.query);
  }

  private async listarClientes() {
    const query: IQueryParam = {
      select: 'nombre',
      includeChildren: true,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  //
  tieneKm(): boolean {
    if (this.tipo?.includes('km') && !this.tipo?.includes('fecha')) return true;
    else return false;
  }

  repetible(): boolean {
    return this.form?.value.repetible;
  }

  toggleOpcion() {
    if (this.tipo.length == 0) {
      this.form.get('kmLimite').enable();
      this.form.get('fechaLimite').enable();
      this.form.patchValue({ repetible: false });
    } else {
      if (this.tipo.includes('km')) {
        this.form.get('kmLimite').enable();
      } else {
        this.form.get('kmLimite').disable();
        this.form.patchValue({ repetible: false });
      }
      if (this.tipo.includes('fecha')) {
        this.form.get('fechaLimite').enable();
        this.form.patchValue({ repetible: false });
      } else {
        this.form.get('fechaLimite').disable();
      }
    }
  }

  private async getRecordatorio(id: string) {
    this.recordatorio = await this.service.getById(id);
  }

  public async onSubmit() {
    const res = await this.dialogService.confirm(
      `${this.title} Recordatorio`,
      `¿Desea ${this.title.toLowerCase()} el recordatorio?`,
    );
    if (!res) return;
    this.loading = true;
    try {
      const data = this.getData();
      if (data) {
        if (this.id) {
          // Update
          await this.service.update(this.id, data);
          this.helper.notifSuccess('Recordatorio actualizado');
          HelperService.volver();
        } else {
          // Create
          await this.service.create(data);
          this.helper.notifSuccess('Recordatorio creado');
          HelperService.volver();
        }
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public getData(): ICreateRecordatorio | IUpdateRecordatorio {
    const date = new Date(this.form?.value.fechaLimite);
    if (!date) {
      return null;
    }
    if (date && date.getTime() <= Date.now()) {
      this.helper.notifError(
        'La fecha del recordatorio no puede ser menor o igual a la fecha de hoy',
      );
      return null;
    }
    const data: ICreateRecordatorio | IUpdateRecordatorio = this.form?.value;
    return data;
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    this.title = this.id ? 'Editar Recordatorio' : 'Crear Recordatorio';

    await this.listarVehiculos();
    await this.listarProveedores();
    await this.listarClientes();
    this.createForm();
    if (this.id) {
      await this.getRecordatorio(this.id);
      this.tipo = this.form.get('tipo').value;
      this.toggleOpcion();
    } else
      this.form.get('tipo').valueChanges.subscribe(() => {
        this.tipo = this.form.get('tipo').value;
        this.toggleOpcion();
      });
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.proveedores$?.unsubscribe();
    this.vehiculos$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
