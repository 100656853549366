/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-var */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import Hls from 'hls.js';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-videogular',
  templateUrl: './videogular.component.html',
  styleUrls: ['./videogular.component.scss'],
  standalone: true,
  imports: [CommonModule, VgCoreModule, VgControlsModule, MatProgressSpinner],
})
export class VideogularComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor() {}

  @Input() containerSize!: { width: number; height: number };

  private videoPlayer: HTMLVideoElement;
  public name = signal(this.generateRandomNumber(1, 10000000000000).toString());
  public loading = signal(true);

  @Input() url: string;

  ngOnInit() {}

  ngOnDestroy() {
    this.videoPlayer.src = '';
  }

  ngOnChange() {}
  ngAfterViewInit(): void {
    this.videoPlayer = document.getElementById(this.name()) as HTMLVideoElement;
    const hls = new Hls();
    const hlsUrl = this.url;
    if (Hls.isSupported()) {
      hls.loadSource(hlsUrl);
      hls.attachMedia(this.videoPlayer);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        this.loading.set(false);
        this.videoPlayer.play();
      });
    } else if (this.videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
      // Para Safari y navegadores con soporte nativo de HLS
      this.videoPlayer.src = hlsUrl;
      this.videoPlayer.addEventListener('loadedmetadata', () => {
        this.loading.set(false);
        this.videoPlayer.play();
      });
    }
  }

  generateRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
