@if (!this.loading) {
  <!-- Título -->
  <div class="row-center">
    <span class="title1">{{ camara?.identificacion }}</span>
  </div>
  <mat-tab-group
    [(selectedIndex)]="selectedTab"
    (selectedTabChange)="tabChanged($event)"
  >
    <!-- Info -->
    <mat-tab label="Info">
      <ng-template matTabContent>
        <app-detalles-camara-info></app-detalles-camara-info>
      </ng-template>
    </mat-tab>
    <!-- Info -->
    <mat-tab label="Camaras">
      <ng-template matTabContent>
        <br />
        <div
          style="
            height: 70vh;
            display: flex;
            justify-content: center;
            overflow-y: scroll;
            overflow-x: hidden;
          "
        >
          <app-camaras-multiple [data]="[camara._id]"></app-camaras-multiple>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
}
<!-- Botones -->
<div class="row-flex-end" style="gap: 10px">
  <app-button
    matType="mat-fab extended"
    color="warn"
    (click)="this.camaras()"
    text="Volver"
    icon="arrow_back"
    z
  ></app-button>
</div>
