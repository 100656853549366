<mat-card>
  <!-- Headers -->
  <mat-card-header>
    @if (dialogRef) {
      <mat-card-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
        @if (vehiculo) {
          <span class="titulo">Editar {{ vehiculo?.identificacion }}</span>
        } @else {
          <span class="titulo">Crear Vehículo</span>
        }
      </mat-card-title>
    } @else {
      <mat-card-title>
        @if (vehiculo) {
          <span class="titulo">Editar {{ vehiculo?.identificacion }}</span>
        } @else {
          <span class="titulo">Crear Vehículo</span>
        }
      </mat-card-title>
    }
  </mat-card-header>
  @if (form) {
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-card-content
        style="margin: 2em 0; overflow: auto"
        [class.cardContentDialog]="dialogRef"
        [class.cardContentFullscreen]="!dialogRef"
      >
        <!-- identificacion - patente - estado -->
        <section class="layout">
          <div class="grow1">
            <mat-form-field>
              <mat-label>Identificación</mat-label>
              <input matInput formControlName="identificacion" />
            </mat-form-field>
          </div>

          <div class="grow1" [formGroup]="vehiculoForm">
            <mat-form-field>
              <mat-label>Estado</mat-label>
              <mat-select formControlName="estado">
                @for (e of estados; track e) {
                  <mat-option [value]="e">{{ e }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          @if (!helper.esClienteFinal() && !vehiculo) {
            <!-- Clientes -->
            <div style="width: 25%; display: flex">
              <ng-select
                [items]="clientes"
                bindLabel="nombre"
                bindValue="_id"
                formControlName="idCliente"
                placeholder="Cliente"
                [notFoundText]="'No hay clientes creados'"
                style="width: 95%"
                multiple="false"
                searchable="true"
                appendTo="body"
                (change)="clienteElegido($event)"
              >
              </ng-select>
              @if (helper.verModuloAdministracion()) {
                <app-button
                  style="margin-left: 5px"
                  matType="mat-fab"
                  color="accent"
                  icon="add"
                  (click)="crearCliente()"
                ></app-button>
              }
            </div>
          }
          <div [formGroup]="vehiculoForm" style="width: 25%">
            <ng-select
              [items]="tipos"
              formControlName="tipo"
              placeholder="Tipo"
              [notFoundText]="'No hay tipos disponibles'"
            >
            </ng-select>
          </div>
        </section>
        <!-- Tracker - Grupo - Chofer -->
        <section class="layout">
          <div class="grow1">
            <ng-select
              [items]="funciones"
              formControlName="funcion"
              placeholder="Función"
              [notFoundText]="'No hay funciones disponibles'"
            >
            </ng-select>
          </div>
          <div class="grow1">
            <div style="width: 100%; display: flex">
              <ng-select
                style="width: 90%"
                [items]="trackers"
                bindLabel="nombre"
                bindValue="_id"
                formControlName="idTracker"
                placeholder="Tracker"
                [notFoundText]="'No hay trackers disponibles'"
              >
              </ng-select>
              <app-button
                style="margin-left: 5px"
                [disabled]="!this.form.value.idCliente"
                matType="mat-fab"
                color="accent"
                icon="add"
                (click)="form?.value?.idCliente ? crearTracker() : null"
              ></app-button>
            </div>
          </div>
          <div class="grow1">
            <div style="width: 100%; display: flex">
              <ng-select
                style="width: 90%"
                [items]="grupos"
                bindLabel="nombre"
                bindValue="_id"
                formControlName="idGrupo"
                placeholder="Grupo"
              >
              </ng-select>
              <app-button
                style="margin-left: 5px"
                [disabled]="!this.form.value.idCliente"
                matType="mat-fab"
                color="accent"
                icon="add"
                (click)="form?.value?.idCliente ? crearTracker() : null"
              ></app-button>
            </div>
          </div>
          <div class="grow1" [formGroup]="vehiculoForm" style="display: flex">
            <ng-select
              style="width: 90%"
              [items]="choferes"
              bindLabel="usuario"
              bindValue="_id"
              formControlName="idChofer"
              placeholder="Chofer"
            >
            </ng-select>
            <app-button
              style="margin-left: 5px"
              [disabled]="!this.form.value.idCliente"
              matType="mat-fab"
              color="accent"
              icon="add"
              (click)="form?.value?.idCliente ? crearChofer() : null"
            ></app-button>
          </div>
        </section>
        <section>
          <div [formGroup]="vehiculoForm" class="layout">
            <mat-form-field>
              <mat-label>Patente</mat-label>
              <input matInput formControlName="patente" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Marca</mat-label>
              <input matInput formControlName="marca" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Modelo</mat-label>
              <input matInput formControlName="modelo" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Año</mat-label>
              <input matInput formControlName="anio" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Identificación Externa</mat-label>
              <input matInput formControlName="idExterno" />
            </mat-form-field>
          </div>
        </section>
      </mat-card-content>
      <mat-divider></mat-divider>
      <!-- Botones  -->
      <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
        <app-button
          matType="mat-fab extended"
          color="warn"
          (click)="volver()"
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="!this.form.valid || loading"
          color="accent"
          text="Guardar"
          icon="save"
          type="submit"
        ></app-button>
      </mat-card-actions>
    </form>
  }
</mat-card>
