/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ICategoriaEvento,
  IConfigEventoUsuario,
  ICreateConfigEventoUsuario,
  IFilter,
  IGeoJSONPoint,
  IListado,
  IQueryParam,
  IReporte,
  IUpdateConfigEventoUsuario,
  IUsuario,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../../auxiliares/auxiliares.module';
import { ListadosService } from '../../../../../auxiliares/servicios';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ConfigEventoUsuarioService } from '../../../../../auxiliares/servicios/http/config-evento-usuario.service';
import { TiposEventosService } from '../../../../../auxiliares/servicios/http/tipos-eventos.service';
import { ParamsService } from '../../../../../auxiliares/servicios/params.service';

@Component({
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-modo-estacionado',
  templateUrl: './modo-estacionado.component.html',
  styleUrls: ['./modo-estacionado.component.scss'],
})
export class ModoEstacionadoComponent implements OnInit, OnDestroy {
  public loading = false;
  public form?: FormGroup;
  public height = 'unset';
  public maxHeight = 'unset';

  // Datos de input al componente
  @Input() public edit?: IConfigEventoUsuario;
  @Input() public idEntidad?: string;

  // Entidades
  public categoriaEvento: ICategoriaEvento;

  public usuarios: IUsuario[] = [];

  // Listado Continuo
  public categoriasEvento$: Subscription;
  public tiposEventos$?: Subscription;
  public usuarios$?: Subscription;
  public configEventoUsuario$?: Subscription;
  public reportes$?: Subscription;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      idEntidad: string;
    },
    @Optional()
    public dialogRef: MatDialogRef<ModoEstacionadoComponent>,
    private service: ConfigEventoUsuarioService,
    private listadosService: ListadosService,
    private tipoEventoService: TiposEventosService,
    private paramsService: ParamsService,
    public helper: HelperService,
    public route: ActivatedRoute,
  ) {}

  get estacionadoForm() {
    return this.form
      ?.get('condicion')
      ?.get('activo')
      ?.get('estacionado') as FormGroup;
  }

  private createForm(): void {
    const condicion = new FormGroup({
      activo: new FormGroup({
        estacionado: new FormGroup({
          ubicacionEstacionado: new FormControl(),
          distanciaDeAviso: new FormControl(20),
        }),
      }),
    });

    this.form = new FormGroup({
      activa: new FormControl(false),
      tipoEnvio: new FormControl('Notificacion Push'),
      tipoEntidad: new FormControl('Activo'),
      idCategoriaEvento: new FormControl(Validators.required),
      condicion,
      agrupacion: new FormControl('Entidad'),
      idEntidad: new FormControl(this.idEntidad || this.dialogData?.idEntidad),
      idsUsuarios: new FormControl(),
      generarSoloUnaVez: new FormControl(true),
      notificar: new FormControl(true),
      atender: new FormControl(true),
    });
  }

  //
  public volver() {
    if (this.dialogRef) {
      this.dialogRef.close();
    } else {
      HelperService.volver();
    }
  }

  public searchUsuario = (term: string, item: IUsuario) => {
    if (item.usuario?.toLowerCase().includes(term.toLowerCase())) return true;
    if (
      item.datosPersonales?.nombre?.toLowerCase().includes(term.toLowerCase())
    )
      return true;
    if (
      item.datosPersonales?.telefono?.toLowerCase().includes(term.toLowerCase())
    )
      return true;
    if (item.datosPersonales?.email?.toLowerCase().includes(term.toLowerCase()))
      return true;
    return false;
  };

  // Listados

  public async listarCategorias(): Promise<void> {
    const filter: IFilter<ICategoriaEvento> = {
      default: true,
      nombre: 'BUR',
    };
    const query: IQueryParam = { filter: JSON.stringify(filter) };
    this.categoriasEvento$?.unsubscribe();
    this.categoriasEvento$ = this.listadosService
      .subscribe<IListado<ICategoriaEvento>>('categoriaEventos', query)
      .subscribe((data) => {
        this.categoriaEvento = data.datos[0];
        console.log(`listado de categoriaEventos`, data);
      });
    await this.listadosService.getLastValue('categoriaEventos', query);
    if (this.categoriaEvento)
      this.form.patchValue({ idCategoriaEvento: this.categoriaEvento?._id });
  }

  private async listarUsuarios(): Promise<void> {
    const query: IQueryParam = {
      select: 'usuario datosPersonales.telefono permisos',
      sort: 'usuario',
    };
    this.usuarios$?.unsubscribe();
    this.usuarios$ = this.listadosService
      .subscribe<IListado<IUsuario>>('usuarios', query)
      .subscribe((data) => {
        this.usuarios = data.datos;
        console.log(`listado de usuarios`, data);
      });
    await this.listadosService.getLastValue('usuarios', query);
  }

  private async listarConfigEventoUsuario(): Promise<void> {
    if (this.idEntidad || this.dialogData?.idEntidad) {
      const id = this.idEntidad || this.dialogData?.idEntidad;
      const filter: IFilter<IConfigEventoUsuario> = {
        idEntidad: id,
      };
      const query: IQueryParam = { filter: JSON.stringify(filter) };
      this.configEventoUsuario$?.unsubscribe();
      this.configEventoUsuario$ = this.listadosService
        .subscribe<
          IListado<IConfigEventoUsuario>
        >('configEventoUsuarios', query)
        .subscribe((data) => {
          this.edit = data.datos[0];
          console.log(`listado de configEventoUsuario`, data);
        });
      await this.listadosService.getLastValue('configEventoUsuarios', query);
      this.estacionadoForm.patchValue({
        distanciaDeAviso:
          this.edit?.condicion?.activo?.estacionado?.distanciaDeAviso,
      });
      this.form.patchValue({ activa: this.edit?.activa });
      this.form.patchValue({ idCategoriaEvento: this.edit?.idCategoriaEvento });
      this.form.patchValue({ idEntidad: this.edit?.idEntidad });
      this.form.patchValue({ idsUsuarios: this.edit?.idsUsuarios });
    }
  }

  private async ultimoReporteUbicacion(id: string): Promise<IGeoJSONPoint> {
    const filter: IFilter<IReporte> = {
      idActivo: id,
    };
    const query: IQueryParam = { filter: JSON.stringify(filter) };
    let res = null;
    this.reportes$?.unsubscribe();
    this.reportes$ = this.listadosService
      .subscribe<IListado<IReporte>>('ultimoReportes', query)
      .subscribe((data) => {
        console.log(`Listado de reportes`, data);
        if (data.datos[0]) {
          res = data.datos[0].geojson;
        }
      });
    await this.listadosService.getLastValue('ultimoReportes', query);
    return res;
  }
  //
  private async getData() {
    const data: IUpdateConfigEventoUsuario | ICreateConfigEventoUsuario =
      this.form?.value;
    data.activa = !data.activa;
    data.condicion.activo.estacionado.ubicacionEstacionado =
      await this.ultimoReporteUbicacion(data.idEntidad);
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = await this.getData();
      if (!data.condicion?.activo?.estacionado?.ubicacionEstacionado) {
        this.helper.notifWarn('El vehiculo no tiene ningun reporte');
        this.volver();
        return;
      }
      if (this.edit?._id) {
        await this.service.update(this.edit?._id, data);
        data.activa
          ? this.helper.notifSuccess('Modo Estacionamiento Iniciado')
          : this.helper.notifSuccess('Modo Estacionamiento Finalizado');
      } else {
        await this.service.create(data);
        data.activa
          ? this.helper.notifSuccess('Modo Estacionamiento Iniciado')
          : this.helper.notifSuccess('Modo Estacionamiento Finalizado');
      }
      this.volver();
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }
  //

  async ngOnInit(): Promise<void> {
    this.createForm();
    await this.listarConfigEventoUsuario();
    if (this.edit) {
      await this.listarUsuarios();
    } else {
      await Promise.all([this.listarUsuarios(), this.listarCategorias()]);
    }
  }

  ngOnDestroy(): void {
    this.categoriasEvento$?.unsubscribe();
    this.usuarios$?.unsubscribe();
    this.configEventoUsuario$?.unsubscribe();
    this.tiposEventos$.unsubscribe();
  }
}
