<mat-card>
  <!-- Headers -->
  <mat-card-header>
    @if (dialogRef) {
      <mat-card-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
        {{ title }}
      </mat-card-title>
    } @else {
      <mat-card-title>
        {{ title }}
      </mat-card-title>
    }
  </mat-card-header>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-card-content
      style="margin: 2em 0; overflow: auto; overflow-x: hidden"
      [class.cardContentDialog]="dialogRef"
      [class.cardContentFullscreen]="!dialogRef"
    >
      <!-- Nombre - Color - Duración -->
      <section class="layout">
        <div style="width: 25%">
          <mat-form-field style="width: 100%">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="nombre" />
          </mat-form-field>
        </div>
        <div style="width: 25%">
          <mat-form-field style="width: 100%">
            <mat-label>Color</mat-label>
            <input matInput readonly formControlName="color" />
            <ngx-colors
              class="suffix"
              matSuffix
              ngx-colors-trigger
              formControlName="color"
              (change)="onColorChange($event, 'color')"
            >
            </ngx-colors>
          </mat-form-field>
        </div>
        <div style="width: 25%">
          <mat-form-field style="width: 100%">
            <mat-label>Duración</mat-label>
            <input matInput formControlName="duracion" />
            <mat-hint> En minutos </mat-hint>
          </mat-form-field>
        </div>
        <div style="width: 25%">
          <mat-form-field style="width: 100%">
            <mat-label>Identificación Externa</mat-label>
            <input matInput formControlName="idExterno" />
          </mat-form-field>
        </div>
      </section>

      <section class="layout">
        <div style="width: 50%; display: flex">
          <ng-select
            [items]="grupos"
            bindLabel="nombre"
            bindValue="_id"
            formControlName="idGrupo"
            placeholder="Grupos"
            style="width: 100%"
            (change)="onGrupoChange($event)"
          >
          </ng-select>
          <app-button
            style="margin-left: 5px"
            matType="mat-fab"
            color="accent"
            icon="add"
            (click)="crearGrupo()"
          ></app-button>
        </div>
        <div style="width: 50%; display: flex">
          <ng-select
            [items]="terminales"
            bindLabel="identificacion"
            bindValue="_id"
            formControlName="idsUbicaciones"
            placeholder="Terminales"
            multiple="true"
            style="width: 100%"
            (change)="onTerminalesChange($event)"
          >
          </ng-select>
          <app-button
            style="margin-left: 5px"
            matType="mat-fab"
            color="accent"
            icon="add"
            (click)="crearTerminal()"
          ></app-button>
        </div>
      </section>

      <!-- Destino - Por -->
      <section class="layout">
        <div style="width: 50%">
          <mat-form-field style="width: 100%">
            <mat-label>Destino</mat-label>
            <input matInput formControlName="destino" />
          </mat-form-field>
        </div>
        <div style="width: 50%">
          <mat-form-field style="width: 100%">
            <mat-label>Por</mat-label>
            <input matInput formControlName="por" />
          </mat-form-field>
        </div>
      </section>

      <!-- MAPA: EL MEJOR -->
      <div
        class="mat-elevation-z4"
        style="padding: 0.5em; border-radius: 5px; margin-bottom: 0.5em"
      >
        <span class="recorrido-titulo">Recorrido</span>
        <br />
        <br />
        <app-mapa-paradas-ol
          [formulario]="form"
          [terminales]="terminalesSeleccionadas"
        ></app-mapa-paradas-ol>
      </div>

      <!-- FRANJA HORARIA -->
      <div
        class="mat-elevation-z4"
        style="padding: 0.5em; border-radius: 5px"
        formArrayName="franjaHoraria"
      >
        <span class="recorrido-titulo">Franja Horaria</span>
        <br />
        <br />
        @for (f of franjaHoraria.controls; track f; let i = $index) {
          <section
            class="layout"
            [formGroupName]="i"
            style="align-items: baseline"
          >
            <div class="row-center grow1" style="gap: 8px">
              <!-- Días -->
              <mat-form-field style="width: 25%">
                <mat-label>Día</mat-label>
                <mat-select formControlName="dia">
                  @for (d of dias; track d) {
                    <mat-option [value]="dias.indexOf(d)">{{ d }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <!-- Desde -->
              <mat-form-field style="width: 25%">
                <mat-label>Desde</mat-label>
                <input
                  matInput
                  [owlDateTime]="dt1"
                  formControlName="desde"
                  [disabled]="true"
                />
                <button
                  mat-icon-button
                  matSuffix
                  [owlDateTimeTrigger]="dt1"
                  type="button"
                >
                  <mat-icon>schedule</mat-icon>
                </button>
                <owl-date-time
                  [pickerType]="'timer'"
                  [startAt]="hoyALas0"
                  #dt1
                ></owl-date-time>
              </mat-form-field>

              <!-- Hasta -->
              <mat-form-field style="width: 25%">
                <mat-label>Hasta</mat-label>
                <input
                  matInput
                  [owlDateTime]="dt2"
                  formControlName="hasta"
                  [disabled]="true"
                />
                <button
                  mat-icon-button
                  matSuffix
                  [owlDateTimeTrigger]="dt2"
                  type="button"
                >
                  <mat-icon>schedule</mat-icon>
                </button>
                <owl-date-time
                  [pickerType]="'timer'"
                  [startAt]="hoyALas0"
                  #dt2
                ></owl-date-time>
              </mat-form-field>

              <!-- Frecuencia -->
              <mat-form-field style="width: 25%">
                <mat-label>Frecuencia</mat-label>
                <input
                  matInput
                  placeholder="15"
                  formControlName="frecuenciaMinutos"
                  type="number"
                />
                <mat-hint> En minutos </mat-hint>
              </mat-form-field>
            </div>
            <!-- Eliminar -->
            <div>
              <button
                mat-mini-fab
                color="warn"
                type="button"
                (click)="deleteFranja(i)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </section>
        }
        <div class="row-center">
          <app-button
            [matType]="'mat-fab extended'"
            color="accent"
            (click)="addFranja()"
            text="Agregar Franja Horaria"
            icon="add"
          ></app-button>
        </div>
      </div>
    </mat-card-content>
    <mat-divider></mat-divider>
    <!-- Botones  -->
    <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid || loading"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </mat-card-actions>

    <!-- DEBUG -->
    <!-- <pre>{{ form.value | json }}</pre> -->
  </form>
</mat-card>
