@if (!loading) {
  @if (dialogRef && paramsService.getParams()["dialog"]) {
    <mat-card
      style="
        border: 1px solid black;
        overflow-x: hidden;
        height: 100%;
        position: relative;
      "
    >
      <!-- Header -->
      <mat-card-header style="border-radius: 2px 2px 0 0">
        <mat-card-title
          cdkDrag
          cdkDragHandle
          cdkDragRootElement=".cdk-overlay-pane"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span style="padding-left: 8px">
            {{ camaras[0]?.identificacion }}
          </span>

          <div (click)="closeDialog()" class="icon-close">
            <mat-icon>close</mat-icon>
          </div>
        </mat-card-title>
      </mat-card-header>

      <!-- Content -->
      <mat-card-content
        style="
          margin: 1em 0;
          overflow: auto;
          justify-content: center;
          display: flex;
          overflow-x: hidden;
        "
      >
        <mat-divider></mat-divider>
        <!--  -->
        <div>
          <div
            style="
              display: flex;
              flex-direction: row;
              gap: 0.5em;
              margin-top: 1em;
            "
          >
            @if (!maximizado) {
              <section class="camaras-todos">
                @if (camaras && camaras[0]) {
                  @for (camara of camaras; track camara) {
                    @for (canal of camara.canales; track canal) {
                      @if (canal?.ids) {
                        <div style="display: flex; position: relative">
                          <div
                            (click)="select(camara, canal.numero)"
                            style="display: flex; align-items: center"
                          >
                            <app-stream-camara
                              [esDialog]="false"
                              [camara]="camara"
                              [canal]="canal.numero"
                              [mainStream]="false"
                            ></app-stream-camara>
                          </div>
                          <div
                            style="position: absolute; z-index: 1000"
                            class="letra"
                          >
                            {{ canal.numero }}
                          </div>
                        </div>
                      }
                    }
                  }
                }
              </section>
            }
            @if (maximizado) {
              <div (click)="select()" style="height: 100%; display: flex">
                <app-stream-camara
                  [esDialog]="false"
                  [camara]="camaraActual"
                  [canal]="canalActual"
                  [mainStream]="true"
                ></app-stream-camara>
                <div style="position: absolute; z-index: 1000" class="letra">
                  {{ canalActual }}
                </div>
              </div>
            }
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  } @else {
    @if (!maximizado) {
      <section class="camaras-todos">
        @if (camaras && camaras[0]) {
          @for (camara of camaras; track camara) {
            @for (canal of camara.canales; track canal) {
              @if (canal?.ids) {
                <div style="display: flex; position: relative">
                  <div
                    (click)="select(camara, canal.numero)"
                    style="display: flex; align-items: center"
                  >
                    <app-stream-camara
                      [esDialog]="false"
                      [camara]="camara"
                      [canal]="canal.numero"
                      [mainStream]="false"
                    ></app-stream-camara>
                  </div>
                  <div style="position: absolute; z-index: 1000" class="letra">
                    {{ canal.numero }}
                  </div>
                </div>
              }
            }
          }
        }
      </section>
    }
    @if (maximizado) {
      <div (click)="select()" style="height: 100%; display: flex">
        <app-stream-camara
          [esDialog]="false"
          [camara]="camaraActual"
          [canal]="canalActual"
          [mainStream]="true"
        ></app-stream-camara>
        <div style="position: absolute; z-index: 1000" class="letra">
          {{ canalActual }}
        </div>
      </div>
    }
  }
}
