/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { Feature, Map, View } from 'ol';
import { IGeoJSONPoint, IReporte } from 'modelos/src';
import { Geometry, LineString, Point } from 'ol/geom';
import { OpenLayersService } from '../../../../auxiliares/servicios/openLayers.service';
import VectorSource from 'ol/source/Vector';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';

@Component({
  selector: 'app-mapa-vehiculos',
  templateUrl: './mapa-vehiculos.component.html',
  styleUrl: './mapa-vehiculos.component.scss',
  standalone: false,
})
export class MapaVehiculosComponent implements OnInit, OnChanges {
  @Input() public mapaIndex?: number;
  public ctrl = false;
  //Vehiculos
  @Input() reportes?: IReporte[] = [];
  public reporteSeleccionado?: IReporte;
  @Output() reporteSeleccionadoVer? = new EventEmitter<IReporte>();

  /// OPENLAYERS
  public map?: Map;
  // Layer de Markers
  public markerVectorOptions = OpenLayersService.vehiculosNormalesVectorLayer();
  public orientationVectorOptions =
    OpenLayersService.vehiculosCompassVectorLayer();
  /// Layer de Polylines
  public polylineVectorOptions = OpenLayersService.polylineVectorLayer();
  /// Layer de Paradas
  public paradasVectorOptions = OpenLayersService.paradasChicasVectorLayer();
  public circleVectorOptions = OpenLayersService.circleVectorLayer();
  /// Layer Polygon
  public polygonVectorOptions = OpenLayersService.polygonsVectorLayer();
  /// Centro
  private puntos: [number, number][] = [];
  // Cant Mapas
  public loadingMapa: boolean = true;
  public siguendoA?: string;
  constructor() {}

  private addMarkers() {
    const source = this.markerVectorOptions.getSource();
    if (!source) return;
    source.clear();
    const orientationSource = this.orientationVectorOptions.getSource();
    if (!orientationSource) return;
    orientationSource.clear();
    if (this.reportes) {
      for (const reporte of this.reportes) {
        if (!reporte.geojson?.coordinates) return;
        // Solo mostrar los vehiculos que estén en los recorridos seleccionados

        this.puntos.push(reporte.geojson.coordinates);
        // marker
        const coord = OpenLayersService.lonLatToCoordinate(
          reporte.geojson.coordinates[0],
          reporte.geojson.coordinates[1],
        );
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(coord),
          data: reporte,
          tipo: 'colectivo',
        });
        source.addFeature(feature);
        // orientation
        if (reporte.orientacion) {
          const radian = (reporte.orientacion * Math.PI) / 180;
          const featureOrientation: Feature<Geometry> = new Feature({
            geometry: new Point(coord),
            data: reporte,
            tipo: 'vehiculo',
          });
          const style = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: 'assets/map/brujula.png',
              height: 40,
              width: 40,
              rotation: radian,
            }),
          });
          featureOrientation.setStyle(style);
          orientationSource.addFeature(featureOrientation);
        } else {
          const featureOrientation: Feature<Geometry> = new Feature({
            geometry: new Point(coord),
            data: reporte,
            tipo: 'vehiculo',
          });
          const style = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: 'assets/map/circle.png',
              height: 40,
              width: 40,
            }),
          });
          featureOrientation.setStyle(style);
          orientationSource.addFeature(featureOrientation);
        }
      }
    }
  }

  private async initMap() {
    const position = await OpenLayersService.getCurrentPosition();
    this.map = new Map({
      interactions: OpenLayersService.interactions(),
      target: `map${this.mapaIndex}`,
      controls: [],
      layers: [
        OpenLayersService.mapTile(),
        this.polylineVectorOptions,
        this.orientationVectorOptions,
        this.markerVectorOptions,
        this.paradasVectorOptions,
        this.circleVectorOptions,
        this.polygonVectorOptions,
      ],
      view: new View({
        center: position,
        zoom: 16,
      }),
    });

    this.map.on('error', (e) => {
      console.error('Error en el mapa', e);
    });
    this.handleClick();
    const sourceres: VectorSource<Feature<Geometry>> = new VectorSource();
    if (this.reportes?.length) {
      const sourceMarker = this.markerVectorOptions.getSource();
      sourceMarker.forEachFeature((f) => sourceres.addFeature(f));
    }

    if (sourceres) {
      this.setBounds(sourceres);
    }
  }

  private handleClick() {
    if (!this.map) return;
    this.reporteSeleccionado = undefined;
    this.map.on('singleclick', async (evt) => {
      const feature = this.map?.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          const geometry = feature.getGeometry();
          if (geometry instanceof Point) {
            if (feature.get('tipo') === 'colectivo') {
              /// ES UN COLECTIVO
              return feature;
            }
            /// ES UNA PARADA
            return null;
          } else if (geometry instanceof LineString) {
            return null;
          }
          return null;
        },
      );

      if (!feature) return;
      const data = feature.get('data') as IReporte;
      if (feature.get('tipo') === 'colectivo') {
        this.reporteSeleccionado = data;
        console.log('reporte seleccionado', this.reporteSeleccionado);
        if (!this.reporteSeleccionado?.geojson?.coordinates) return;
      }
    });
  }

  private setBounds(source: VectorSource<Feature<Geometry>> | null) {
    if (!source || source.getFeatures().length === 0) return;
    const extent = source.getExtent();
    if (!extent) return;
    this.map?.getView().fit(extent, { padding: [50, 50, 50, 50] });
    if (this.map?.getView().getZoom() > 17) {
      this.map?.getView().setZoom(17);
    }
  }

  public onClose() {
    this.reporteSeleccionado = undefined;
  }

  private centerMap(geojson: IGeoJSONPoint, zoom?: number) {
    if (!geojson?.coordinates) return;
    const coord = OpenLayersService.lonLatToCoordinate(
      geojson.coordinates[0],
      geojson.coordinates[1],
    );
    this.map?.getView().setCenter(coord);
    if (zoom) {
      this.map?.getView().setZoom(zoom);
    }
  }

  public indiceMap() {
    return `map${this.mapaIndex}`;
  }

  public reporteVer(event: any) {
    if (this.reporteSeleccionado && event) {
      this.reporteSeleccionadoVer.emit(this.reporteSeleccionado);
    }
  }

  public siguendoAlActivo(event: any) {
    if (event && this.reporteSeleccionado) {
      this.siguendoA = this.reporteSeleccionado.idActivo;
      if (this.reporteSeleccionado.geojson) {
        this.centerMap(this.reporteSeleccionado.geojson, 17);
        this.map.on('pointerdrag', () => {
          if (this.siguendoA) {
            this.siguendoA = null;
          }
        });
      }
    } else {
      this.siguendoA = null;
      this.map.on('movestart', () => {});
    }
  }

  ///
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reportes']) {
      this.addMarkers();
      if (this.siguendoA) {
        console.log('siguendo a :', this.siguendoA);
        const siguendo = this.reportes.find(
          (r) => r.idActivo === this.siguendoA,
        );

        if (siguendo) this.centerMap(siguendo.geojson, 17);
      }
    }
  }
  async ngOnInit() {
    await this.initMap();
    this.addMarkers();
  }
}
