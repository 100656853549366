/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { DialogService } from '../dialog/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  public promptEvent: any;
  private timer?: NodeJS.Timeout;
  private interval = 1 * (1000 * 60); // 1 minuto

  constructor(
    private swUpdate: SwUpdate,
    private dialogService: DialogService,
  ) {
    if (swUpdate.isEnabled) {
      // Verifica si la app esta instalada
      window.addEventListener('beforeinstallprompt', (event) => {
        console.log('App no instalada');
        event.preventDefault();
        this.promptEvent = event;
      });

      // Verifica si hay nuevas versiones de la app cada X min
      this.checkVersion();
      this.timer = setInterval(this.checkVersion.bind(this), this.interval);
    } else {
      console.log('Service Worker No Disponible');
    }
  }

  private async checkVersion() {
    const newVersion = await this.swUpdate.checkForUpdate();
    if (newVersion) {
      console.log('SW - Nueva versión de app detectada');
      clearInterval(this.timer!);
      this.timer = undefined;
      await this.promptReaload();
    } else {
      console.log('SW - No hay nueva versión');
    }
  }

  private async promptReaload() {
    const confirm = await this.dialogService.confirm(
      'Nueva versión disponible',
      '¿Recargar ahora para aplicarla?',
    );
    if (confirm) {
      window.location.reload();
    }
  }

  public installPwa(): void {
    this.promptEvent.prompt();
    this.promptEvent.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        this.promptEvent = null;
      }
    });
  }
}
