import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { ParamsService } from '../../../auxiliares/servicios/params.service';
import { CamarasService } from '../../../auxiliares/servicios/http/camara.service';
import { ICamara } from 'modelos/src';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../auxiliares/auxiliares.module';

@Component({
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-stream-camara',
  templateUrl: './stream-camara.component.html',
  styleUrl: './stream-camara.component.scss',
  standalone: true,
})
export class StreamCamaraComponent implements OnInit, OnChanges {
  public loading: boolean = true;
  public dragBoundary = document.getElementById('router');

  @Input() public camara?: ICamara;
  @Input() public canal?: string;
  @Input() public esDialog?: boolean = true;
  @Input() public mainStream?: boolean = true;
  public streams: string[] = [];

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<StreamCamaraComponent>,
    public helper: HelperService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private cdk: OverlayContainer,
    private el: ElementRef,
    private paramsService: ParamsService,
    private camarasService: CamarasService,
  ) {}

  //

  public closeDialog() {
    this.dialogRef.close();
  }

  public bringToFront(): void {
    const dialogsContainer = Array.from(
      this.cdk.getContainerElement().childNodes,
    );
    const dialogContainerTagName = 'MAT-DIALOG-CONTAINER';
    const parentEl = this.el.nativeElement.closest(dialogContainerTagName);
    const dialogId = parentEl?.getAttribute('id');

    const focusedDialog = (dialogsContainer as HTMLElement[]).find(
      (el: HTMLElement) => {
        return el.querySelector(`#${dialogId}`);
      },
    );

    if (focusedDialog) {
      // appendChild moves the element to the end of the container
      this.cdk.getContainerElement().appendChild(focusedDialog);
    } else {
      console.error('Dialog not found with id:', dialogId);
    }
  }

  async getWsPorts() {
    if (!this.canal) {
      //CAMARA DE LA FARMACIA
      const canales = this.camara.canales.map((canal) => canal.numero);
      this.canal = canales[2];
    }
    const idCamara = this.camara._id;
    const res = await this.camarasService.live(idCamara, this.canal);
    if (this.mainStream) {
      this.streams = [res.mainStream];
    } else {
      this.streams = [res.subStream];
    }
  }

  async ngOnInit() {
    this.camara = !this.camara
      ? this.paramsService.getParams()?.['camara']
      : this.camara;
    this.canal = !this.canal
      ? this.paramsService.getParams()?.['canal']
      : this.canal;
    await this.getWsPorts();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['canal'] || changes['camara']) await this.getWsPorts();
  }
}
