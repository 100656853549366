import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IQueryParam, IListado, ICamara } from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';

@Component({
  selector: 'app-detalles-camara-info',
  templateUrl: './detalles-camara-info.component.html',
  styleUrl: './detalles-camara-info.component.scss',
  standalone: false,
})
export class DetallesCamaraInfoComponent implements OnInit {
  public id: string | null = null;
  public title = '';
  public camara: ICamara;
  public camaras$: Subscription;

  public loading = true;
  private query: IQueryParam = {
    includeChildren: true,
    populate: JSON.stringify({ path: 'cliente', select: 'nombre' }),
  };
  constructor(
    public helper: HelperService,
    private listados: ListadosService,
    private route: ActivatedRoute,
  ) {}

  public async listar(): Promise<void> {
    this.camaras$?.unsubscribe();
    this.camaras$ = this.listados
      .subscribe<IListado<ICamara>>('camaras', this.query)
      .subscribe((data) => {
        this.camara = data.datos[0];
        console.log(`listado de camaras`, data);
      });
    await this.listados.getLastValue('camaras', this.query);
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    await Promise.all([this.listar()]);
    this.loading = false;
  }
}
