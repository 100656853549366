<mat-card>
  <!-- Headers -->
  <mat-card-header>
    <mat-card-title>
      @if (id) {
        Editar {{ vehiculo?.identificacion }}
      } @else {
        Crear Colectivo
      }
    </mat-card-title>
  </mat-card-header>
  @if (form) {
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-card-content
        style="margin: 2em 0; overflow: auto"
        [class.cardContentDialog]="dialogRef"
        [class.cardContentFullscreen]="!dialogRef"
      >
        <!-- identificacion - patente - estado -->
        <section class="layout">
          <div class="grow1">
            <mat-form-field>
              <mat-label>Identificación</mat-label>
              <input matInput formControlName="identificacion" />
            </mat-form-field>
          </div>
          <div class="grow1" [formGroup]="vehiculoForm">
            <mat-form-field>
              <mat-label>Estado</mat-label>
              <mat-select formControlName="estado">
                @for (e of estados; track e) {
                  <mat-option [value]="e">{{ e }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          @if (!helper.esClienteFinal()) {
            <!-- Clientes -->
            <div style="width: 32.8%; display: flex">
              <ng-select
                [items]="clientes"
                bindLabel="nombre"
                bindValue="_id"
                formControlName="idCliente"
                placeholder="Cliente"
                [notFoundText]="'No hay clientes creados'"
                style="width: 100%"
                multiple="false"
                searchable="true"
                appendTo="body"
                (change)="clienteElegido($event)"
              >
              </ng-select>
              @if (helper.verModuloAdministracion()) {
                <app-button
                  style="margin-left: 5px"
                  matType="mat-fab"
                  color="accent"
                  icon="add"
                  (click)="crearCliente()"
                ></app-button>
              }
            </div>
          }
        </section>
        <!-- Tracker - Grupo - Chofer -->
        <section class="layout">
          <div class="grow1">
            <div style="width: 100%; display: flex">
              <ng-select
                [items]="trackers"
                bindLabel="nombre"
                bindValue="_id"
                formControlName="idTracker"
                placeholder="Tracker"
                [notFoundText]="'No hay trackers disponibles'"
                style="width: 100%"
              >
              </ng-select>
              <app-button
                style="margin-left: 5px"
                [disabled]="!this.form.value.idCliente"
                matType="mat-fab"
                color="accent"
                icon="add"
                (click)="form?.value?.idCliente ? crearTracker() : null"
              ></app-button>
            </div>
          </div>
          <div class="grow1">
            <div style="width: 100%; display: flex">
              <ng-select
                [items]="grupos"
                bindLabel="nombre"
                bindValue="_id"
                formControlName="idGrupo"
                placeholder="Grupo"
                style="width: 100%"
              >
              </ng-select>
              <app-button
                style="margin-left: 5px"
                [disabled]="!this.form.value.idCliente"
                matType="mat-fab"
                color="accent"
                icon="add"
                (click)="form?.value?.idCliente ? crearGrupo() : null"
              ></app-button>
            </div>
          </div>
          <div class="grow1" [formGroup]="vehiculoForm" style="display: flex">
            <ng-select
              [items]="choferes"
              bindLabel="usuario"
              bindValue="_id"
              formControlName="idChofer"
              placeholder="Chofer"
              style="width: 100%"
            >
            </ng-select>
            <app-button
              style="margin-left: 5px"
              [disabled]="!this.form.value.idCliente"
              matType="mat-fab"
              color="accent"
              icon="add"
              (click)="form?.value?.idCliente ? crearChofer() : null"
            ></app-button>
          </div>
        </section>
        <section class="layout" [formGroup]="vehiculoForm">
          <div class="grow1">
            <mat-form-field>
              <mat-label>Patente</mat-label>
              <input matInput formControlName="patente" />
            </mat-form-field>
          </div>
          <div class="grow1">
            <mat-form-field>
              <mat-label>Marca</mat-label>
              <input matInput formControlName="marca" />
            </mat-form-field>
          </div>
          <div class="grow1">
            <mat-form-field>
              <mat-label>Modelo</mat-label>
              <input matInput formControlName="modelo" />
            </mat-form-field>
          </div>
          <div class="grow1">
            <mat-form-field>
              <mat-label>Año</mat-label>
              <input matInput formControlName="anio" />
            </mat-form-field>
          </div>
          <div class="grow1">
            <mat-form-field>
              <mat-label>Identificación Externa</mat-label>
              <input matInput formControlName="idExterno" />
            </mat-form-field>
          </div>
        </section>

        <!-- Recorridos - recorrido actual -->
        <section class="layout" [formGroup]="vehiculoForm">
          <div class="grow1">
            <ng-select
              [items]="recorridos"
              bindLabel="nombre"
              bindValue="_id"
              formControlName="idsRecorridos"
              placeholder="Recorridos"
              style="width: 100%"
              multiple="true"
            >
            </ng-select>
          </div>
          <div class="grow1">
            <div style="width: 100%; display: flex">
              <ng-select
                [items]="recorridos"
                bindLabel="nombre"
                bindValue="_id"
                formControlName="idRecorrido"
                placeholder="Recorrido Actual"
                style="width: 100%"
              >
              </ng-select>
              <app-button
                style="margin-left: 5px"
                [disabled]="!this.form.value.idCliente"
                matType="mat-fab"
                color="accent"
                icon="add"
                (click)="form?.value?.idCliente ? crearRecorrido() : null"
              ></app-button>
            </div>
          </div>
        </section>
      </mat-card-content>
      <mat-divider></mat-divider>
      <!-- Botones -->
      <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
        <app-button
          matType="mat-fab extended"
          color="warn"
          (click)="this.helper.volver()"
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="!this.form.valid || loading"
          color="accent"
          text="Guardar"
          icon="save"
          type="submit"
        ></app-button>
      </mat-card-actions>
    </form>
  }
</mat-card>
