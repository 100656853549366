import { MarkerMapa } from './../../../../../auxiliares/mapa-facil/interfaces';
import { Component, OnInit } from '@angular/core';
import {
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  IReporte,
  ITracker,
} from 'modelos/src';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { ActivatedRoute } from '@angular/router';
import { MapaFacilData } from '../../../../../auxiliares/mapa-facil/interfaces';
import { OpenLayersService } from '../../../../../auxiliares/servicios/openLayers.service';

@Component({
  selector: 'app-detalles-tracker-info',
  templateUrl: './detalles-tracker-info.component.html',
  styleUrl: './detalles-tracker-info.component.scss',
  standalone: false,
})
export class DetallesTrackerInfoComponent implements OnInit {
  public id: string | null = null;
  public title = '';
  public tracker: ITracker;
  public reporte: IReporte;
  public trackers$: Subscription;
  public reportes$: Subscription;
  public datoMapa?: MapaFacilData;

  public loading = true;
  private query: IQueryParam = {};
  constructor(
    public helper: HelperService,
    private listados: ListadosService,
    private route: ActivatedRoute,
  ) {}

  public async listar(): Promise<void> {
    const filter: IFilter<ITracker> = this.query.filter
      ? JSON.parse(this.query.filter)
      : {};
    const polulate: IPopulate[] = [
      {
        path: 'cliente',
        select: 'nombre',
      },
      {
        path: 'modelo',
        select: 'modelo marca',
      },
      {
        path: 'activo',
        select: 'identificacion',
      },
    ];
    filter._id = this.id;
    this.query.filter = JSON.stringify(filter);
    this.query.populate = JSON.stringify(polulate);
    this.trackers$?.unsubscribe();
    this.trackers$ = this.listados
      .subscribe<IListado<ITracker>>('trackers', this.query)
      .subscribe((data) => {
        this.tracker = data.datos[0];
        console.log(`listado de trackers`, data);
      });
    await this.listados.getLastValue('trackers', this.query);
  }

  public async UltimoReporte(): Promise<void> {
    const query: IQueryParam = {
      filter: JSON.stringify({ idTracker: this.tracker?._id }),
      includeChildren: true,
    };

    this.reportes$?.unsubscribe();
    this.reportes$ = this.listados
      .subscribe<IListado<IReporte>>('ultimoReportes', query)
      .subscribe((data) => {
        console.log(`Listado de reportes`, data);
        if (data.datos[0]) {
          this.reporte = data.datos[0];
          const cordinate = OpenLayersService.lonLatToCoordinate(
            this.reporte?.geojson?.coordinates[0],
            this.reporte?.geojson?.coordinates[1],
          );
          const marker: MarkerMapa = {
            label: 'puntoSimple',
            coor: cordinate,
          };
          const mapaFacil: MapaFacilData = { markers: [marker] };
          this.datoMapa = mapaFacil;
        }
      });
    await this.listados.getLastValue('ultimoReportes', query);
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    await this.listar();
    await this.UltimoReporte();
    this.loading = false;
  }
}
