import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ICliente,
  ICreateServicio,
  IListado,
  IQueryParam,
  IUpdateServicio,
  IServicio,
  TipoServicio,
  IProveedor,
  IActivo,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiciosService } from '../../servicios/servicios.service';
import { CrearEditarProveedorComponent } from '../../proveedores/crear-editar-proveedor/crear-editar-proveedor.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';

@Component({
  selector: 'app-crear-editar-servicio',
  templateUrl: './crear-editar-servicio.component.html',
  styleUrl: './crear-editar-servicio.component.scss',
  standalone: false,
})
export class CrearEditarServicioComponent implements OnInit, OnDestroy {
  public name = CrearEditarServicioComponent.name;
  public form?: FormGroup;
  public title?: string;
  public tipos: TipoServicio[] = ['Gasto', 'Mantenimiento'];
  public id?: string | null;
  public servicio?: IServicio;
  public vehiculos?: IActivo[] = [];
  public vehiculos$?: Subscription;
  public proveedores?: IProveedor[] = [];
  public proveedores$?: Subscription;

  public clientes?: ICliente[];
  public clientes$?: Subscription;

  get datosPersonales() {
    return this.form?.get('datosPersonales') as FormGroup;
  }

  public queryVehiculos: IQueryParam = {
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };
  public loading: boolean = true;
  constructor(
    @Optional()
    public dialogRef: MatDialogRef<CrearEditarServicioComponent>,
    private route: ActivatedRoute,
    private listados: ListadosService,
    public helper: HelperService,
    public dialog: MatDialog,
    private navigator: Router,
    private service: ServiciosService,
    private dialogService: DialogService,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      tipo: new FormControl(this.servicio?.tipo, Validators.required),
      idCliente: new FormControl(this.servicio?.idCliente, Validators.required),
      idActivo: new FormControl(this.servicio?.idActivo, Validators.required),
      idProveedor: new FormControl(this.servicio?.idProveedor),
      fechaRealizacion: new FormControl(this.servicio?.fechaRealizacion),
      nombreChofer: new FormControl(this.servicio?.nombreChofer),
      detalles: new FormControl(this.servicio?.detalles),
      kmDelMantenimiento: new FormControl(this.servicio?.kmDelMantenimiento),
      costo: new FormControl(this.servicio?.costo),
    });
  }

  private async listarVehiculos() {
    this.vehiculos$?.unsubscribe();
    this.vehiculos$ = this.listados
      .subscribe<IListado<IActivo>>('vehiculos', {})
      .subscribe((data) => {
        this.vehiculos = data.datos;
        console.log(`listado de vehiculos`, data);
      });
    await this.listados.getLastValue('vehiculos', {});
  }

  private async listarProveedores() {
    this.proveedores$?.unsubscribe();
    this.proveedores$ = this.listados
      .subscribe<IListado<IProveedor>>('proveedores', this.id!)
      .subscribe((data) => {
        this.proveedores = data.datos;
        console.log(`listado de proveedores`, data);
      });
    await this.listados.getLastValue('proveedores', this.id!);
  }

  private async getServicio() {
    if (this.id) this.servicio = await this.service.getById(this.id);
  }

  private async listarClientes() {
    const query: IQueryParam = {
      select: 'nombre',
      includeChildren: true,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  //
  public async onSubmit() {
    const res = await this.dialogService.confirm(
      `${this.title} Servicio`,
      `¿Desea ${this.title} el servicio?`,
    );
    if (!res) return;
    this.loading = true;
    try {
      const data = this.getData();
      if (this.id) {
        // Update
        await this.service.update(this.id, data);
        this.helper.notifSuccess('Servicio actualizado');
        HelperService.volver();
      } else {
        // Create
        await this.service.create(data);
        this.helper.notifSuccess('Servicio creado');
        HelperService.volver();
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public crearProveedor() {
    this.dialog.open(CrearEditarProveedorComponent, {});
  }

  public crearRecordatorio() {
    this.navigator.navigate(['/', 'main', 'servicios', 'recordatorio']);
  }

  public getData(): ICreateServicio | IUpdateServicio {
    const data: ICreateServicio | IUpdateServicio = this.form?.value;
    return data;
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    this.title = this.id ? 'Editar Servicio' : 'Crear Servicio';
    await this.listarVehiculos();
    await this.listarProveedores();
    if (this.id) {
      await this.getServicio();
    } else {
      await this.listarClientes();
    }
    this.createForm();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.proveedores$?.unsubscribe();
    this.vehiculos$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
