<mat-card>
  @if (form) {
    <!-- Headers -->
    <mat-card-header>
      @if (dialogRef) {
        <mat-card-title
          cdkDrag
          cdkDragHandle
          cdkDragRootElement=".cdk-overlay-pane"
        >
          @if (id) {
            Editar {{ cliente?.nombre }}
          } @else {
            Crear Cliente
          }
        </mat-card-title>
      } @else {
        <mat-card-title>
          @if (id) {
            Editar {{ cliente?.nombre }}
          } @else {
            Crear Cliente
          }
        </mat-card-title>
      }
    </mat-card-header>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <br />
      <mat-card-content
        style="margin: 2em 0; overflow: auto"
        [class.cardContentDialog]="dialogRef"
        [class.cardContentFullscreen]="!dialogRef"
      >
        <!-- Nombre y Tipo -->
        @if (!editandoPropio || !id) {
          @if (!editandoPropio) {
            <!-- Nombre -->
            <mat-form-field style="width: 100%">
              <mat-label>Nombre del Cliente</mat-label>
              <input matInput required formControlName="nombre" />
            </mat-form-field>

            <!-- NumeroCliente -->
            <mat-form-field style="width: 100%">
              <mat-label>Numero de Cliente</mat-label>
              <input matInput formControlName="numeroCliente" />
            </mat-form-field>

            <!-- Estado del cliente -->
            <mat-form-field>
              <mat-label>Estado del Cliente</mat-label>
              <mat-select formControlName="estadoDeCuenta">
                @for (s of estadoCuenta; track s) {
                  <mat-option [value]="s">{{ s }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
          <!-- Tipo (solo al crear) -->
          @if (!id) {
            <mat-form-field style="width: 100%">
              <mat-label>Tipo de Cliente</mat-label>
              <mat-select formControlName="tipoCliente" required>
                @for (t of tipoCliente; track t) {
                  <mat-option [value]="t">{{ t }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
        }
        <br />
        @if (!editandoPropio) {
          <!-- Servicios -->
          <mat-card-header>
            <mat-card-title>Servicios</mat-card-title>
          </mat-card-header>
          <div class="column-center" style="gap: 10px">
            <div class="row-space-around" style="width: 100%">
              <ng-select
                [items]="servicios"
                formControlName="idServiciosContratados"
                bindLabel="nombre"
                bindValue="_id"
                style="width: 100%"
                multiple
                (change)="this.calcularCostoTotal()"
              >
                <ng-template ng-label-tmp let-item="item">
                  <img height="15" width="15" [src]="item.icono" />
                  {{ item.nombre }}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <img height="15" width="15" [src]="item.icono" />
                  {{ item.nombre }}
                </ng-template>
              </ng-select>
            </div>
            <div class="row-space-around" style="width: 100%">
              Facturación Mensual: {{ costoTotal() | currency: "ARS" }}
            </div>
          </div>
          <br />
        } @else {
          <mat-card-header>
            <mat-card-title>Servicios</mat-card-title>
          </mat-card-header>
          <div class="column-center" style="gap: 10px">
            <app-mostrar-servicios
              [cliente]="cliente"
              orientacion="horizontal"
              [linea]="true"
              [size]="50"
              [gap]="8"
            ></app-mostrar-servicios>
          </div>
          <br />
        }
        <!-- Configuraciones -->
        <ng-container [formGroup]="config">
          <!-- Módulos -->
          @if (!editandoPropio || this.clientePropio?.nivel === 0) {
            <mat-card-header>
              <mat-card-title>Módulos</mat-card-title>
            </mat-card-header>
            <div class="row-space-around">
              @if (
                this.clientePropio?.config?.moduloColectivos?.activo ||
                this.clientePropio?.nivel === 0
              ) {
                <div
                  class="column-center bordecito"
                  style="gap: 10px; align-items: flex-start"
                  [formGroup]="moduloColectivos"
                >
                  <span class="subtitulo">Colectivos</span>
                  @if (
                    this.clientePropio?.config?.moduloColectivos?.activo ===
                      true || this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="activo">Activo</mat-checkbox>
                  }
                  @if (
                    this.clientePropio?.config?.moduloColectivos
                      ?.crearDispositivos === true ||
                    this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="crearDispositivos"
                      >Crear Dispositivos</mat-checkbox
                    >
                  }
                  @if (
                    this.clientePropio?.config?.moduloColectivos
                      ?.derivarEventos === true ||
                    this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="derivarEventos"
                      >Derivar Eventos</mat-checkbox
                    >
                  }
                  @if (
                    this.clientePropio?.config?.moduloColectivos
                      ?.compartirFlota === true ||
                    this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="compartirFlota"
                      >Compartir Flota</mat-checkbox
                    >
                  }
                </div>
              }
              @if (
                this.clientePropio?.config?.moduloAlarmasDomiciliarias
                  ?.activo || this.clientePropio?.nivel === 0
              ) {
                <div
                  class="column-center bordecito"
                  style="gap: 10px; align-items: flex-start"
                  [formGroup]="moduloAlarmasDomiciliarias"
                >
                  <span class="subtitulo">Alarmas Domiciliarias</span>
                  @if (
                    this.clientePropio?.config?.moduloAlarmasDomiciliarias
                      ?.activo === true || this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="activo">Activo</mat-checkbox>
                  }
                  @if (
                    this.clientePropio?.config?.moduloAlarmasDomiciliarias
                      ?.crearDispositivos === true ||
                    this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="crearDispositivos">
                      Crear Dispositivos
                    </mat-checkbox>
                  }
                  @if (
                    this.clientePropio?.config?.moduloAlarmasDomiciliarias
                      ?.derivarEventos === true ||
                    this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="derivarEventos"
                      >Derivar Eventos</mat-checkbox
                    >
                  }
                  @if (
                    this.clientePropio?.config?.moduloAlarmasDomiciliarias
                      ?.compartirAlarmas === true ||
                    this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="compartirAlarmas">
                      Compartir Alarmas
                    </mat-checkbox>
                  }
                </div>
              }
              @if (
                this.clientePropio?.config?.moduloActivos?.activo ||
                this.clientePropio?.nivel === 0
              ) {
                <div
                  class="column-center bordecito"
                  style="gap: 10px; align-items: flex-start"
                  [formGroup]="moduloActivos"
                >
                  <span class="subtitulo">Activos</span>
                  @if (
                    this.clientePropio?.config?.moduloActivos?.activo ===
                      true || this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="activo">Activo</mat-checkbox>
                  }
                  @if (
                    this.clientePropio?.config?.moduloActivos
                      ?.crearDispositivos === true ||
                    this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="crearDispositivos"
                      >Crear Dispositivos</mat-checkbox
                    >
                  }
                  @if (
                    this.clientePropio?.config?.moduloActivos
                      ?.derivarEventos === true ||
                    this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="derivarEventos"
                      >Derivar Eventos</mat-checkbox
                    >
                  }
                  @if (
                    this.clientePropio?.config?.moduloActivos
                      ?.compartirActivos === true ||
                    this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="compartirActivos"
                      >Compartir Activos</mat-checkbox
                    >
                  }
                </div>
              }

              @if (
                this.clientePropio?.config?.moduloVehiculos?.activo ||
                this.clientePropio?.nivel === 0
              ) {
                <div
                  class="column-center bordecito"
                  style="gap: 10px; align-items: flex-start"
                  [formGroup]="moduloVehiculos"
                >
                  <span class="subtitulo">Vehículos</span>
                  @if (
                    this.clientePropio?.config?.moduloVehiculos?.activo ===
                      true || this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="activo">Activo</mat-checkbox>
                  }
                  @if (
                    this.clientePropio?.config?.moduloVehiculos
                      ?.crearVehiculos === true ||
                    this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="crearVehiculos"
                      >Crear Vehículos</mat-checkbox
                    >
                  }
                  @if (
                    this.clientePropio?.config?.moduloVehiculos
                      ?.derivarEventos === true ||
                    this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="derivarEventos"
                      >Derivar Eventos</mat-checkbox
                    >
                  }
                  @if (
                    this.clientePropio?.config?.moduloVehiculos
                      ?.compartirVehiculos === true ||
                    this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="compartirVehiculos"
                      >Compartir Vehículos</mat-checkbox
                    >
                  }
                </div>
              }
              @if (
                this.clientePropio?.config?.moduloAdministracion?.activo ||
                this.clientePropio?.nivel === 0
              ) {
                <div
                  class="column-center bordecito"
                  style="gap: 10px; align-items: flex-start"
                  [formGroup]="moduloAdministracion"
                >
                  <span class="subtitulo">Administración</span>
                  @if (
                    this.clientePropio?.config?.moduloAdministracion?.activo ===
                      true || this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="activo">Activo</mat-checkbox>
                  }
                  @if (
                    this.clientePropio?.config?.moduloAdministracion
                      ?.crearUsuarios === true ||
                    this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="crearUsuarios">
                      Crear Usuarios
                    </mat-checkbox>
                  }
                  @if (
                    this.clientePropio?.config?.moduloAdministracion
                      ?.crearServicios === true ||
                    this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="crearServicios">
                      Crear Servicios
                    </mat-checkbox>
                  }
                  @if (
                    this.clientePropio?.config?.moduloAdministracion
                      ?.crearApikeys === true || this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="crearApikeys">
                      Crear Apikeys
                    </mat-checkbox>
                  }
                </div>
              }
              @if (
                this.clientePropio?.config?.moduloEventosTecnicos?.activo ||
                this.clientePropio?.nivel === 0
              ) {
                <div
                  class="column-center bordecito"
                  style="gap: 10px; align-items: flex-start"
                  [formGroup]="moduloEventosTecnicos"
                >
                  <span class="subtitulo">Servicios Técnicos</span>
                  @if (
                    this.clientePropio?.config?.moduloEventosTecnicos
                      ?.activo === true || this.clientePropio?.nivel === 0
                  ) {
                    <mat-checkbox formControlName="activo">Activo</mat-checkbox>
                  }
                </div>
              }
              @if (
                !this.clientePropio?.config?.moduloColectivos?.activo &&
                !this.clientePropio?.config?.moduloAlarmasDomiciliarias
                  ?.activo &&
                !this.clientePropio?.config?.moduloActivos?.activo &&
                !this.clientePropio?.config?.moduloAdministracion?.activo &&
                !this.clientePropio?.config?.moduloEventosTecnicos?.activo
              ) {
                <div class="row-center">
                  <span class="no-info"> No hay módulos activados </span>
                </div>
              }
            </div>
            <br />
          }
          <!-- Imágenes -->
          <mat-card-header>
            <mat-card-title>Imágenes</mat-card-title>
          </mat-card-header>
          <div [formGroup]="imagenes">
            <div class="column-center" style="gap: 10px">
              <div class="row-space-around" style="width: 100%">
                <app-upload-file
                  [(url)]="urlIcono"
                  textoBoton="Subir Ícono"
                  (urlChange)="previewTema()"
                ></app-upload-file>
                @if (urlIcono) {
                  <img [src]="urlIcono" alt="ícono" width="100px" />
                } @else {
                  <span class="no-info row-center">
                    Sin imágen
                    <mat-icon color="warn">close</mat-icon>
                  </span>
                }
              </div>
              <div class="row-space-around" style="width: 100%">
                <app-upload-file
                  [(url)]="urlBanner"
                  textoBoton="Subir Banner"
                  (urlChange)="previewTema()"
                ></app-upload-file>
                @if (urlBanner) {
                  <img [src]="urlBanner" alt="banner" height="100px" />
                } @else {
                  <span class="no-info row-center">
                    Sin imágen
                    <mat-icon color="warn">close</mat-icon>
                  </span>
                }
                <app-upload-file
                  [(url)]="urlBannerModoOscuro"
                  textoBoton="Subir Banner Modo Oscuro"
                  (urlChange)="previewTema()"
                ></app-upload-file>
                @if (urlBanner) {
                  <img
                    [src]="urlBannerModoOscuro"
                    alt="banner"
                    height="100px"
                  />
                } @else {
                  <span class="no-info row-center">
                    Sin imágen
                    <mat-icon color="warn">close</mat-icon>
                  </span>
                }
              </div>
            </div>
          </div>
          <br />
          <!-- Tema -->

          <mat-card-header>
            <mat-card-title> Tema </mat-card-title>
          </mat-card-header>
          <div [formGroup]="tema">
            <section class="layout">
              <!-- Colores -->
              <div class="grow1 column-center" style="gap: 15px">
                <mat-form-field style="width: 100%">
                  <mat-label>Primario</mat-label>
                  <input matInput readonly formControlName="primaryColor" />
                  <ngx-colors
                    class="suffix"
                    matSuffix
                    ngx-colors-trigger
                    formControlName="primaryColor"
                    (change)="
                      onColorChange($event, 'primaryColor'); previewTema()
                    "
                  >
                  </ngx-colors>
                </mat-form-field>

                <mat-form-field style="width: 100%">
                  <mat-label>Acento</mat-label>
                  <input matInput readonly formControlName="accentColor" />
                  <ngx-colors
                    class="suffix"
                    matSuffix
                    ngx-colors-trigger
                    formControlName="accentColor"
                    (change)="
                      onColorChange($event, 'accentColor'); previewTema()
                    "
                  >
                  </ngx-colors>
                </mat-form-field>

                <mat-form-field style="width: 100%">
                  <mat-label>Alerta</mat-label>
                  <input matInput readonly formControlName="warnColor" />
                  <ngx-colors
                    class="suffix"
                    matSuffix
                    ngx-colors-trigger
                    formControlName="warnColor"
                    (change)="onColorChange($event, 'warnColor'); previewTema()"
                  >
                  </ngx-colors>
                  <mat-hint>Usualmente es un rojo</mat-hint>
                </mat-form-field>
              </div>
            </section>
          </div>
        </ng-container>
        <br />
      </mat-card-content>
      <!-- Botones  -->
      <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
        <app-button
          matType="mat-fab extended"
          color="warn"
          (click)="
            this.rollbackTema();
            dialogRef ? dialogRef.close() : this.helper.volver()
          "
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="!this.form.valid"
          color="accent"
          text="Guardar"
          icon="save"
          type="submit"
        ></app-button>
      </mat-card-actions>
    </form>
  }
</mat-card>
