import { Injectable } from '@angular/core';
import {
  IQueryParam,
  IListado,
  ILogDespacho,
  ICreateLogDespacho,
  IUpdateLogDespacho,
} from 'modelos/src';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { HttpClientService } from '../../../../auxiliares/servicios/http.service';

@Injectable({
  providedIn: 'root',
})
export class LogsDespachosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<ILogDespacho>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/logdespachos`, { params });
  }

  public getById(id: string): Promise<ILogDespacho> {
    return this.http.get(`/logdespachos/${id}`);
  }

  public create(dato: ICreateLogDespacho): Promise<ILogDespacho> {
    return this.http.post(`/logdespachos`, dato);
  }

  public update(id: string, dato: IUpdateLogDespacho): Promise<ILogDespacho> {
    return this.http.put(`/logdespachos/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/logdespachos/${id}`);
  }
}
