<mat-card>
  <!-- Headers -->
  <mat-card-header>
    @if (dialogRef) {
      <mat-card-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
        {{ title }}
      </mat-card-title>
    } @else {
      <mat-card-title>
        {{ title }}
      </mat-card-title>
    }
  </mat-card-header>

  <form
    *ngIf="form"
    id="form"
    [formGroup]="form"
    autocomplete="off"
    (ngSubmit)="onSubmit()"
  >
    <!-- Form -->
    <mat-card-content
      style="margin: 2em 0; overflow: auto"
      [class.cardContentDialog]="dialogRef"
      [class.cardContentFullscreen]="!dialogRef"
    >
      <!-- Tipo de Entidades -->
      @if (!params.tipoEntidad) {
        <mat-form-field>
          <mat-label>Tipo de Entidades</mat-label>
          <mat-select formControlName="tipoEntidad" required>
            <mat-option
              *ngFor="let dato of tiposEntidades"
              [value]="dato.value"
            >
              {{ dato.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      }

      <!-- Agrupación -->
      @if (!params.tipoEntidad) {
        <h3>AGRUPACIÓN DE {{ form?.value?.tipoEntidad | uppercase }}</h3>
        <mat-form-field>
          <mat-label>Agrupación</mat-label>
          <mat-select formControlName="agrupacion" required>
            <mat-option
              *ngFor="let dato of tiposAgrupaciones"
              [value]="dato.value"
            >
              {{ dato.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      }

      <!-- Global -->
      @if (form?.value?.agrupacion === "Global") {
        <h3>SE NOTIFICARÁ DE TODOS LOS DISPOSITIVOS</h3>
      }

      <!-- Grupo -->
      @if (!params.idGrupo && form?.value?.agrupacion === "Grupo") {
        <h3>
          SE NOTIFICARÁ DE TODOS LOS DISPOSITIVOS DE LA GRUPO SELECCIONADA
        </h3>
        <mat-form-field>
          <mat-label>Grupos</mat-label>
          <mat-select formControlName="idGrupo" required>
            @for (dato of grupos; track dato) {
              <!-- @if (dato.categoria === tipoEntidad) { -->
              <mat-option [value]="dato._id">
                {{ dato.nombre }}
              </mat-option>
            }
            <!-- } -->
          </mat-select>
        </mat-form-field>
      }

      <!-- Activos -->
      @if (
        !params.idEntidad &&
        form?.value?.agrupacion === "Entidad" &&
        form?.value?.tipoEntidad === "Activo"
      ) {
        <h3>SE NOTIFICARÁ SOLO DEL ACTIVO SELECCIONADO</h3>
        <ng-select
          [items]="activos"
          placeholder="Activo"
          loadingText="Cargando..."
          typeToSearchText="Buscar..."
          notFoundText="No se encontraron resultados"
          formControlName="idEntidad"
          [appendTo]="'body'"
          [bindValue]="'_id'"
          [bindLabel]="'identificacion'"
        >
        </ng-select>
      }
      <!-- Vehiculos -->
      @if (
        !params.idEntidad &&
        form?.value?.agrupacion === "Entidad" &&
        form?.value?.tipoEntidad === "Vehiculo"
      ) {
        <h3>SE NOTIFICARÁ SOLO DEL VEHÍCULO SELECCIONADO</h3>
        <ng-select
          [items]="vehiculos"
          placeholder="Vehículo"
          loadingText="Cargando..."
          typeToSearchText="Buscar..."
          notFoundText="No se encontraron resultados"
          formControlName="idEntidad"
          [appendTo]="'body'"
          [bindValue]="'_id'"
          [bindLabel]="'identificacion'"
        >
        </ng-select>
      }
      <!-- Alarmas -->
      @if (
        !params.idEntidad &&
        form?.value?.agrupacion === "Entidad" &&
        form?.value?.tipoEntidad === "Alarma"
      ) {
        <h3>SE NOTIFICARÁ SOLO DE LA ALARMA SELECCIONADA</h3>
        <ng-select
          [items]="alarmas"
          placeholder="Alarma"
          loadingText="Cargando..."
          typeToSearchText="Buscar..."
          notFoundText="No se encontraron resultados"
          formControlName="idEntidad"
          [appendTo]="'body'"
          [bindValue]="'_id'"
          [bindLabel]="'nombre'"
          (change)="changeAlarma($event)"
        >
        </ng-select>
      }

      <!-- CONDICIONES -->
      <h3>CONDICIÓN DE GENERACIÓN DE EVENTO</h3>
      <!-- Tipo de condicion -->
      @if (!params.tipoCondicion) {
        <mat-form-field [formGroup]="form">
          <mat-label>Condición</mat-label>
          <mat-select formControlName="tipoCondicion" required>
            @for (dato of tiposCondicion; track dato) {
              @if (dato.tipo === tipoEntidad) {
                <mat-option [value]="dato.valor">
                  {{ dato.valor }}
                </mat-option>
              }
            }
          </mat-select>
        </mat-form-field>
      }
      @switch (tipoEntidad) {
        <!-- Configuración de Activo y vehiculo -->
        @case (
          tipoEntidad === "Activo" || tipoEntidad === "Vehiculo"
            ? tipoEntidad
            : null
        ) {
          <!-- Condicion Ubicacion -->
          @if (tipoCondicion === "Ubicación") {
            <div
              [formGroup]="configUbicacionActivo"
              style="display: flex; flex-direction: row; align-items: baseline"
            >
              <!-- Ubicacion -->
              <ng-select
                [items]="ubicacions"
                formControlName="idUbicacion"
                [multiple]="false"
                placeholder="Ubicación"
                style="width: 100%"
                bindLabel="identificacion"
                bindValue="_id"
                appendTo="body"
                notFoundText="No se encontraron resultados"
              >
              </ng-select>
              <!-- Check dentro -->
              <mat-checkbox formControlName="dentro" color="primary">
                Notificar si entra
              </mat-checkbox>
              <!-- Check fuera -->
              <mat-checkbox formControlName="fuera" color="primary">
                Notificar si sale
              </mat-checkbox>
            </div>
          }

          <!-- Condicion Velocidad -->
          @if (tipoCondicion === "Velocidad") {
            <div
              [formGroup]="configVelocidadActivo"
              style="display: flex; flex-direction: row; align-items: baseline"
            >
              <!-- superior a -->
              <mat-form-field>
                <mat-label>Superior a </mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="superior a"
                  required
                />
                <span matSuffix style="padding-right: 1em">Km/h</span>
              </mat-form-field>
            </div>
          }

          <!-- Condicion Detenido -->
          @if (tipoCondicion === "Detenido") {
            <div
              [formGroup]="configDetenidoActivo"
              style="display: flex; flex-direction: row; align-items: baseline"
            >
              <!-- Mas de -->
              <mat-form-field>
                <mat-label>Mas de </mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="mas de"
                  required
                />
                <span matSuffix style="padding-right: 1em">Minutos</span>
              </mat-form-field>
            </div>
          }
        }
        @case ("Alarma") {
          <div [formGroup]="condicionAlarma">
            <!-- LLega evento -->
            @if (tipoCondicion === "Llega evento") {
              <ng-select
                formControlName="tipoCodigo"
                [items]="opcionesDeEventoAlarma"
                placeholder="Opcion de Codigo"
                (change)="changeTipoCodigo()"
              >
              </ng-select>
              @if (condicionAlarma.value.tipoCodigo === "Codigo Unico") {
                <ng-select
                  formControlName="llega evento"
                  [items]="codigosAlarma"
                  bindValue="codigo"
                  placeholder="Código"
                  [notFoundText]="'No hay Códigos'"
                  style="width: 100%"
                  multiple="false"
                  [searchFn]="searchCodigo"
                  searchable="true"
                  appendTo="body"
                  (change)="setCodigo($event)"
                >
                  <ng-template ng-label-tmp let-item="item">
                    {{ item.codigo }} - {{ item.descripcion }}
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.codigo }} - {{ item.descripcion }}
                  </ng-template>
                </ng-select>
              }
            }

            <!-- No llega evento -->
            @if (tipoCondicion === "No llega evento") {
              <ng-select
                formControlName="tipoCodigo"
                [items]="opcionesDeEventoAlarma"
                placeholder="Opcion de Codigo"
                (change)="changeTipoCodigo()"
              >
              </ng-select>

              @if (condicionAlarma.value.tipoCodigo === "Codigo Unico") {
                <ng-select
                  formControlName="no llega evento"
                  [items]="codigosAlarma"
                  bindValue="codigo"
                  placeholder="Código"
                  [notFoundText]="'No hay Códigos'"
                  style="width: 100%"
                  multiple="false"
                  [searchFn]="searchCodigo"
                  searchable="true"
                  appendTo="body"
                  (change)="setCodigo($event)"
                >
                  <ng-template ng-label-tmp let-item="item">
                    {{ item.codigo }} - {{ item.descripcion }}
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.codigo }} - {{ item.descripcion }}
                  </ng-template>
                </ng-select>
              }
            }
            @if (mostrarZona) {
              <div class="row-flex-start" [formGroup]="configZona">
                <!-- Parti -->
                <mat-form-field style="width: 49%; margin-right: 2%">
                  <mat-label>Partición</mat-label>
                  <input matInput formControlName="particion" type="number" />
                </mat-form-field>
                <!-- Zona -->
                <mat-form-field style="width: 49%">
                  <mat-label>Zona</mat-label>
                  <input matInput formControlName="zona" type="number" />
                </mat-form-field>
              </div>
            }
          </div>
        }
      }
      <h3>INFORMACION DEL EVENTO</h3>
      <!-- Categoria -->
      @if (!params.categoriaEvento) {
        <ng-select
          [items]="categoriasEvento"
          bindLabel="nombre"
          bindValue="_id"
          formControlName="idCategoriaEvento"
          placeholder="Categoría"
          [notFoundText]="'No hay categorías creadas'"
          style="width: 100%"
          multiple="false"
          searchable="true"
          appendTo="body"
          required
        >
        </ng-select>
      }

      <!-- Tipo de Evento -->
      @if (!params.tipoEvento && form?.value?.tipoEntidad) {
        <ng-select
          [items]="
            form.value.tipoEntidad === 'Alarma'
              ? tiposEventosAlarmas
              : tiposEventosActivos
          "
          bindLabel="nombre"
          bindValue="_id"
          formControlName="idTipoEvento"
          placeholder="Tipo de Evento"
          [notFoundText]="'No hay tipo de eventos creados'"
          style="width: 100%"
          multiple="false"
          searchable="true"
          appendTo="body"
          [addTag]="addTag.bind(this)"
          required
        >
        </ng-select>
      }

      <!-- FRECUENCIA -->
      <h3>FRECUENCIA</h3>
      <!-- Tipo de frecuencias -->
      <mat-form-field style="margin-bottom: 1em">
        <mat-label>Frecuencia</mat-label>
        <mat-select formControlName="frecuencia" required>
          <mat-option
            *ngFor="let dato of tiposFrecuencias"
            [value]="dato.value"
          >
            {{ dato.label }}
          </mat-option>
        </mat-select>
        <mat-hint>
          <span *ngIf="form?.value?.frecuencia === 'Unica'">
            Se generará el evento una sola vez
          </span>
          <span *ngIf="form?.value?.frecuencia === 'Continua'">
            Se generarán eventos de manera continua por tiempo indeterminado
          </span>
          <span *ngIf="form?.value?.frecuencia === 'Unica en un periodo'">
            Se generará el evento una sola vez dentro del periodo de tiempo
            seleccionado
          </span>
          <span *ngIf="form?.value?.frecuencia === 'Continua en un periodo'">
            Se generarán eventos de manera continua dentro del periodo de tiempo
            seleccionado
          </span>
          <span *ngIf="form?.value?.frecuencia === 'Cronograma'">
            Se generarán eventos solo los días selecionados en el rango horario
            establecido
          </span>
        </mat-hint>
      </mat-form-field>

      @if (
        form?.value?.frecuencia === "Unica en un periodo" ||
        form?.value?.frecuencia === "Continua en un periodo"
      ) {
        <app-date-range-selector
          [(dateRange)]="range"
          [maxDate]="null"
          [placeholder]="'Periodo de tiempo'"
        ></app-date-range-selector>
      }

      <!-- Generar si no se cumple -->
      @if (form?.value?.frecuencia === "Unica en un periodo") {
        <mat-checkbox formControlName="generarSiNoSeCumple" color="primary">
          Generar evento si no se cumple la condición en el periodo de tiempo
        </mat-checkbox>
      }

      @if (form?.value?.frecuencia === "Cronograma") {
        <!-- Dias -->
        <mat-form-field>
          <mat-label>Días</mat-label>
          <mat-select formControlName="dias" multiple>
            <mat-option *ngFor="let dato of dias" [value]="dato">
              {{ dato }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="row-center" style="gap: 8px">
          <!-- Hora desde -->
          <mat-form-field>
            <mat-label>Desde</mat-label>
            <input
              matInput
              placeholder="12:15"
              formControlName="horaInicio"
              pattern="^(?:[01]\d|2[0-3]):[0-5]\d$"
            />
            <mat-icon matSuffix>schedule</mat-icon>
          </mat-form-field>

          <!-- Hora hasta -->
          <mat-form-field>
            <mat-label>Hasta</mat-label>
            <input
              matInput
              placeholder="18:00"
              formControlName="horaFin"
              pattern="^(?:[01]\d|2[0-3]):[0-5]\d$"
            />
            <mat-icon matSuffix>schedule</mat-icon>
          </mat-form-field>
        </div>
      }

      <!-- NOTIFICAR -->
      <h3>ATENCIÓN Y NOTIFICACIÓN</h3>
      <!-- Requiere atencion -->
      <div>
        <mat-checkbox formControlName="atender" color="primary">
          Requiere Atención -
          <small>
            Si no se activa el evento pasará directamente al historial
          </small>
        </mat-checkbox>
      </div>

      <!-- Notificacion Push -->
      <div>
        <mat-checkbox formControlName="notificar" color="primary">
          Notificación Push -
          <small>Si no se activa solo se genera el evento</small>
        </mat-checkbox>
      </div>

      @if (form?.value?.notificar) {
        <!-- USUARIOS -->
        <ng-select
          [items]="usuarios"
          placeholder="Usuarios"
          loadingText="Cargando..."
          typeToSearchText="Buscar..."
          notFoundText="No se encontraron resultados"
          formControlName="idsUsuarios"
          [multiple]="true"
          [searchFn]="searchUsuario"
          [appendTo]="'body'"
          [closeOnSelect]="false"
          [bindValue]="'_id'"
        >
          <ng-template ng-label-tmp let-item="item">
            <span>{{ item.usuario }} </span>
          </ng-template>

          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <div style="padding-bottom: 5px">
              <span>{{ item.usuario }} </span>
              <span style="font-size: 0.7em">
                ({{ item.datosPersonales?.telefono }})
              </span>
            </div>
          </ng-template>
        </ng-select>

        <!-- MEDIO DE NOTIFICACION -->
        <!-- <mat-form-field>
          <mat-label>Medio de envío</mat-label>
          <mat-select formControlName="tipoEnvio">
            <mat-option *ngFor="let dato of tiposEnvio" [value]="dato">
              {{ dato }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
      }
      <br />
    </mat-card-content>

    <mat-divider></mat-divider>

    <!-- Botones -->
    <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid || loading"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </mat-card-actions>
  </form>
</mat-card>
