import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IListado,
  IPopulate,
  IQueryParam,
  IActivo,
  IReporte,
  ITracker,
  ICliente,
  IFilter,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ActivosService } from '../../../../../auxiliares/servicios/http/activos.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { fromLonLat } from 'ol/proj';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { MapaFacilDialogComponent } from '../../../../../auxiliares/mapa-facil-dialog/mapa-facil-dialog.component';
import { MapaFacilData } from '../../../../../auxiliares/mapa-facil/interfaces';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import {
  CrearEditarEventosTecnicosComponent,
  ICEETData,
} from '../../../administracion/eventos-tecnicos/crear-editar-eventos-tecnicos/crear-editar-eventos-tecnicos.component';
import { LoginService } from '../../../../login/login.service';
import { ModoEstacionadoComponent } from '../modo-estacionado/modo-estacionado.component';
import { ParamsService } from '../../../../../auxiliares/servicios/params.service';

interface IActivoTabla extends IActivo {
  ultimoReporte?: IReporte;
  color?: string;
}

@Component({
  selector: 'app-listado-vehiculos',
  templateUrl: './listado-vehiculos.component.html',
  styleUrl: './listado-vehiculos.component.scss',
  standalone: false,
})
export class ListadoVehiculosComponent implements OnInit, OnDestroy {
  public clientePropio = LoginService.getCliente();
  //// Tabla nueva
  public name = ListadoVehiculosComponent.name;
  public activos: IActivoTabla[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<IActivoTabla>[];
  public reportes: IReporte[] = [];

  //Subs
  public reportes$?: Subscription;
  public trackers$?: Subscription;
  public activos$?: Subscription;
  public clientes$?: Subscription;
  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;
  //

  public botonCrear: ICrearBoton = {
    mostrar:
      this.helper.puedeEditar() &&
      HelperService.getConfigCliente().moduloActivos?.crearDispositivos,
    tooltip: 'Crear Activo',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['identificacion'],
  };
  // FILTROS

  public populate: IPopulate[] = [
    {
      path: 'cliente',
      select: 'nombre',
    },
    {
      path: 'tracker',
      select: 'nombre tipo',
    },
  ];
  public tracker: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idTracker',
    },
    label: 'Tracker',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarTrackers$',
    searchOn: ['nombre'],
  };
  private cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  public filtros: IFiltroTabla[] = [this.tracker];
  public query: IQueryParam = {
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: true,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: ActivosService,
    public dialog: MatDialog,
    private navigator: Router,
    private route: ActivatedRoute,
    private paramsService: ParamsService,
  ) {}
  // Listars
  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      Promise.all([
        this.listar(),
        this.listarTrackers(),
        this.listarClientes(),
      ]);
    } else {
      await this.listar();
    }
  }

  public async listar(): Promise<void> {
    this.activos$?.unsubscribe();
    this.activos$ = this.listados
      .subscribe<IListado<IActivo>>('vehiculos', this.query)
      .subscribe(async (data) => {
        this.totalCount = data.totalCount;
        this.activos = data.datos;
        await this.completaUltimoReporte();
        console.log(`listado de vehiculos `, data);
      });
    await this.listados.getLastValue('vehiculos', this.query);
  }
  public async listarTrackers(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre identificacion',
      sort: 'nombre',
      limit: 0, // Sin limite
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };

    this.trackers$?.unsubscribe();
    this.trackers$ = this.listados
      .subscribe<IListado<ITracker>>('trackers', query)
      .subscribe((data) => {
        this.tracker.elementos = data.datos;
        this.tracker.elementos.forEach(
          (e) => (e.idUnicoTraccar = e.traccar?.id),
        );
        console.log(`listado de trackers`, data);
      });
    await this.listados.getLastValue('trackers', query);
  }
  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }
  //
  public async completaUltimoReporte(): Promise<void> {
    if (!this.activos.length) return;

    const idsActivos = this.activos.map((v) => v._id);
    const filter: IFilter<IReporte> = {
      idActivo: { $in: idsActivos },
      tipo: 'Vehiculo',
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      includeChildren: true,
    };

    this.reportes$?.unsubscribe();
    this.reportes$ = this.listados
      .subscribe<IListado<IReporte>>('ultimoReportes', query)
      .subscribe((data) => {
        console.log(`Listado de reportes`, data);
        for (const activo of this.activos) {
          // const findReporte = data.find((r) => r.idActivo === activo._id);
          const findReporte = data.datos.find((r) => r.idActivo === activo._id);
          activo.ultimoReporte = findReporte;
          if (activo.ultimoReporte) {
            const timeReporte = new Date(
              activo.ultimoReporte.fechaCreacion,
            ).getTime();
            const timeNow = new Date().getTime();
            const diff = timeNow - timeReporte;
            // 1 hora
            if (diff > 1000 * 60 * 60) {
              activo.color = 'red';
            } else if (diff > 1000 * 60 * 30) {
              activo.color = 'yellow';
            } else {
              activo.color = 'green';
            }
          }
        }
        console.log(`listado de activos`, this.activos);
      });
    await this.listados.getLastValue('ultimoReportes', query);
  }

  // Acciones

  public async ubicar(c: IActivoTabla) {
    if (!c.ultimoReporte?.geojson?.coordinates) return;
    const coord = fromLonLat([
      c.ultimoReporte?.geojson?.coordinates[0],
      c.ultimoReporte?.geojson?.coordinates[1],
    ]);
    const data: MapaFacilData = {
      markers: [{ coor: coord, label: 'vehiculo' }],
    };
    const config: MatDialogConfig = {
      data,
      maxHeight: '600px',
      maxWidth: '600px',
    };
    this.dialog.open(MapaFacilDialogComponent, config);
  }

  // Acciones
  public async crear() {
    this.paramsService.setParams({});
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(vehiculo: IActivo) {
    this.paramsService.setParams({ vehiculo });
    this.navigator.navigate(['editar'], {
      relativeTo: this.route,
    });
  }

  public async detalles(activo: IActivo) {
    this.navigator.navigate(['detalles', activo._id], {
      relativeTo: this.route,
    });
  }
  public async configs(data: IActivo) {
    this.navigator.navigate(
      ['main/administracion/configEventos', data.idTracker],
      {},
    );
  }

  public async modoEstacionado(activo: IActivo) {
    this.dialog.open(ModoEstacionadoComponent, {
      data: { idEntidad: activo._id },
    });
  }

  public async eliminar(activo: IActivo) {
    const res = await this.dialogService.confirm(
      'Eliminar Activo',
      `¿Desea eliminar el activo ${activo.identificacion}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(activo._id!);
      this.helper.notifSuccess('Activo eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  public async eventoTecnico(activo: IActivo) {
    const data: ICEETData = {
      id: null, // Evento nuevo
      categoria: 'Tracker',
      idActivo: activo._id,
      idsCQAET:
        activo.idsClientesQuePuedenAtender ||
        this.clientePropio.config?.idsClientesQuePuedenAtenderEventosTecnicos ||
        null,
    };
    this.dialog.open(CrearEditarEventosTecnicosComponent, {
      data,
      width: '850px',
    });
  }

  // Tabla

  private setColumnas() {
    const columnas: IColumnas<IActivoTabla>[] = [
      // Identificacion
      {
        header: {
          label: 'Identificacion',
          sortable: true,
        },
        row: {
          field: 'identificacion',
        },
      },
      // Tipo
      {
        header: {
          label: 'Tipo',
          sortable: true,
        },
        row: {
          parse(dato) {
            return dato.vehiculo?.tipo;
          },
        },
      },
      // Funcion
      {
        header: {
          label: 'Función',
          sortable: true,
        },
        row: {
          parse(dato) {
            return dato.funcion;
          },
        },
      },
      // Tracker
      {
        header: {
          label: 'Tracker',
        },
        row: {
          parse(dato) {
            return dato.tracker?.nombre;
          },
        },
      },
      // Ultima Comunicacion
      {
        header: {
          label: 'Última Comunicación',
        },
        row: {
          html: (dato) => {
            if (dato.ultimoReporte) {
              const time = new Date(dato.ultimoReporte?.fechaCreacion);
              const fecha = HelperService.parseDate(time);

              let html = `<div style="display: flex; gap: 1em; align-items: center">`;
              if (dato.color) {
                html += `<span style="color: ${dato.color}; font-size: 2em; overflow: visible"> &#x2B24; </span>`;
              }
              html += `<span>${fecha.toLocaleString()} </span>`;
              html += `</div>`;
              return html;
            }
            return '';
          },
          noData: 'Sin Comunicación',
        },
      },
    ];

    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        columnas.push({
          header: {
            label: 'Cliente',
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }

    // Acciones
    columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        accionesGrouped: [
          {
            tipo: 'material',
            color: 'accent',
            icon: 'edit',
            tooltip: 'Editar',
            click: (dato) => this.editar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'primary',
            icon: 'search',
            tooltip: 'Detalles',
            click: (dato) => this.detalles(dato),
          },
          {
            tipo: 'material',
            color: 'warn',
            icon: 'do_not_disturb_on',
            tooltip: 'Modo Estacionado',
            click: (dato) => this.modoEstacionado(dato),
          },
          {
            tipo: 'material',
            color: 'accent',
            icon: 'engineering',
            tooltip: 'Servicio Técnico',
            click: (dato) => this.eventoTecnico(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'warn',
            icon: 'delete',
            tooltip: 'Eliminar',
            click: (dato) => this.eliminar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
        ],
      },
    });

    this.columnas = columnas;
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();
    await this.listarTodo();
  }

  async ngOnDestroy() {
    this.activos$?.unsubscribe();
  }
}
