import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListadoCronogramasComponent } from './cronogramas/listado-cronogramas/listado-cronogramas.component';
import { CrearEditarCronogramaComponent } from './cronogramas/crear-editar-cronograma/crear-editar-cronograma.component';
import { ListadoComponent } from './despachos/listado/listado.component';
import { ListadoColectivosComponent } from './colectivos/listado-colectivos/listado-colectivos.component';
import { CrearEditarColectivoComponent } from './colectivos/crear-editar-colectivo/crear-editar-colectivo.component';
import { ListadoProveedoresComponent } from './proveedores/listado-proveedores/listado-proveedores.component';
import { CrearEditarProveedorComponent } from './proveedores/crear-editar-proveedor/crear-editar-proveedor.component';
import { ListadoRecordatoriosComponent } from './recordatorios/listado-recordatorios/listado-recordatorios.component';
import { CrearEditarRecordatorioComponent } from './recordatorios/crear-editar-recordatorio/crear-editar-recordatorio.component';
import { ListadoRecorridosComponent } from './recorridos/listado-recorridos/listado-recorridos.component';
import { CrearEditarRecorridoComponent } from './recorridos/crear-editar-recorrido/crear-editar-recorrido.component';
import { ListadoServiciosComponent } from './servicios/listado-servicios/listado-servicios.component';
import { CrearEditarServicioComponent } from './servicios/crear-editar-servicios/crear-editar-servicio.component';
import { MapaColectivosComponent } from './mapa-colectivos/mapa-colectivos.component';
import { ListadoModelosDispositivosComponent } from '../../standalone/modelos-dispositivos/listado-modelos-dispositivos/listado-modelos-dispositivos.component';
import { CrearEditarModeloDispositivoComponent } from '../../standalone/modelos-dispositivos/crear-editar-modelo-dispositivo/crear-editar-modelo-dispositivo.component';
import { ListadoCodigosDispositivoComponent } from '../../standalone/codigos-dispositivos/listado-codigos-dispositivos/listado-codigos-dispositivos.component';
import { CrearEditarCodigosDispositivosComponent } from '../../standalone/codigos-dispositivos/crear-editar-codigos-dispositivos/crear-editar-codigos-dispositivos.component';
import { ListadoGruposComponent } from '../../standalone/grupos/listado-grupos/listado-grupos.component';
import { CrearEditarUbicacionComponent } from '../../standalone/ubicaciones/crear-editar-ubicacion/crear-editar-ubicacion.component';
import { ListadoUbicacionesComponent } from '../../standalone/ubicaciones/listado-ubicaciones/listado-ubicaciones.component';
import { DetallesColectivoComponent } from './colectivos/detalles-colectivo/detalles-colectivo.component';
import { ListadoChoferesComponent } from '../../standalone/choferes/listado-choferes/listado-choferes.component';

const routes: Routes = [
  // Mapa
  {
    path: 'mapaColectivos',
    component: MapaColectivosComponent,
    canActivate: [],
  },
  // Choferes
  {
    path: 'choferes',
    component: ListadoChoferesComponent,
    canActivate: [],
  },
  // Cronogramas
  {
    path: 'cronogramas',
    component: ListadoCronogramasComponent,
    canActivate: [],
  },
  {
    path: 'cronogramas/crear',
    component: CrearEditarCronogramaComponent,
    canActivate: [],
  },
  {
    path: 'cronogramas/editar/:id',
    component: CrearEditarCronogramaComponent,
    canActivate: [],
  },
  // Despachos
  {
    path: 'despachos',
    component: ListadoComponent,
    canActivate: [],
  },

  // Lineas
  {
    path: 'grupos/:categoria',
    component: ListadoGruposComponent,
    canActivate: [],
  },
  // Colectivos
  {
    path: 'colectivos',
    component: ListadoColectivosComponent,
    canActivate: [],
  },
  {
    path: 'colectivos/crear',
    component: CrearEditarColectivoComponent,
    canActivate: [],
  },
  {
    path: 'colectivos/editar/:id',
    component: CrearEditarColectivoComponent,
    canActivate: [],
  },
  {
    path: 'colectivos/editar/:id',
    component: CrearEditarColectivoComponent,
    canActivate: [],
  },
  {
    path: 'colectivos/detalles/:id',
    component: DetallesColectivoComponent,
    canActivate: [],
  },
  // Proveedores
  {
    path: 'proveedores',
    component: ListadoProveedoresComponent,
    canActivate: [],
  },
  {
    path: 'proveedores/crear',
    component: CrearEditarProveedorComponent,
    canActivate: [],
  },
  {
    path: 'proveedores/editar/:id',
    component: CrearEditarProveedorComponent,
    canActivate: [],
  },
  // Recordatorio
  {
    path: 'recordatorios',
    component: ListadoRecordatoriosComponent,
    canActivate: [],
  },
  {
    path: 'recordatorios/crear',
    component: CrearEditarRecordatorioComponent,
    canActivate: [],
  },
  {
    path: 'recordatorios/editar/:id',
    component: CrearEditarRecordatorioComponent,
    canActivate: [],
  },
  // Recorridos
  {
    path: 'recorridos',
    component: ListadoRecorridosComponent,
    canActivate: [],
  },
  {
    path: 'recorridos/crear',
    component: CrearEditarRecorridoComponent,
    canActivate: [],
  },
  {
    path: 'recorridos/editar/:id',
    component: CrearEditarRecorridoComponent,
    canActivate: [],
  },
  // Servicios
  {
    path: 'servicios',
    component: ListadoServiciosComponent,
    canActivate: [],
  },
  {
    path: 'servicios/crear',
    component: CrearEditarServicioComponent,
    canActivate: [],
  },
  {
    path: 'servicios/editar/:id',
    component: CrearEditarServicioComponent,
    canActivate: [],
  },
  // Terminales
  {
    path: 'ubicaciones/:categoria',
    component: ListadoUbicacionesComponent,
    canActivate: [],
  },
  {
    path: 'ubicaciones/:categoria/crear',
    component: CrearEditarUbicacionComponent,
    canActivate: [],
  },
  {
    path: 'ubicaciones/:categoria/editar/:id',
    component: CrearEditarUbicacionComponent,
    canActivate: [],
  },
  // Modelos Dispositivos
  {
    path: 'modelosDispositivos/:tipo',
    component: ListadoModelosDispositivosComponent,
    canActivate: [],
  },
  {
    path: 'modelosDispositivos/:tipo/crear',
    component: CrearEditarModeloDispositivoComponent,
    canActivate: [],
  },
  {
    path: 'modelosDispositivos/:tipo/editar/:id',
    component: CrearEditarModeloDispositivoComponent,
    canActivate: [],
  },
  // Codigos Dispositivos
  {
    path: 'codigosDispositivos/:tipo',
    component: ListadoCodigosDispositivoComponent,
    canActivate: [],
  },
  {
    path: 'codigosDispositivos/:tipo/crear',
    component: CrearEditarCodigosDispositivosComponent,
    canActivate: [],
  },
  {
    path: 'codigosDispositivos/:tipo/editar/:id',
    component: CrearEditarCodigosDispositivosComponent,
    canActivate: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ColectivosRoutingModule {}
