import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  IDispositivoAlarma,
  IQueryParam,
  IFilter,
  IPopulate,
  IListado,
  IGeoJSONPoint,
  ICamara,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import {
  MapaFacilData,
  MarkerMapa,
} from '../../../../auxiliares/mapa-facil/interfaces';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../auxiliares/servicios/listados.service';
import { OpenLayersService } from '../../../../auxiliares/servicios/openLayers.service';

@Component({
  selector: 'app-detalles-alarma-info',
  templateUrl: './detalles-alarma-info.component.html',
  styleUrl: './detalles-alarma-info.component.scss',
  standalone: false,
})
export class DetallesAlarmaInfoComponent implements OnInit {
  public id: string | null = null;
  public title = '';
  public alarma: IDispositivoAlarma;
  public alarmas$: Subscription;
  public datoMapa?: MapaFacilData;

  public camaraElegida: ICamara;
  public canalElegido: string;
  public camaraLista: boolean = true;

  public loading = true;
  private query: IQueryParam = {};
  constructor(
    public helper: HelperService,
    private listados: ListadosService,
    private route: ActivatedRoute,
  ) {}

  public async listar(): Promise<void> {
    const filter: IFilter<IDispositivoAlarma> = this.query.filter
      ? JSON.parse(this.query.filter)
      : {};
    const polulate: IPopulate[] = [
      {
        path: 'cliente',
        select: 'nombre',
      },
      {
        path: 'modelo',
        select: 'modelo marca',
      },
      {
        path: 'domicilio',
        select: 'geojson direccion',
      },
      {
        path: 'comunicador',
      },
      {
        path: 'camaras',
      },
    ];
    filter._id = this.id;
    this.query.filter = JSON.stringify(filter);
    this.query.populate = JSON.stringify(polulate);
    this.alarmas$?.unsubscribe();
    this.alarmas$ = this.listados
      .subscribe<
        IListado<IDispositivoAlarma>
      >('dispositivosAlarmas', this.query)
      .subscribe((data) => {
        this.alarma = data.datos[0];
        console.log(`listado de dispositivosAlarmas`, data);
      });
    await this.listados.getLastValue('dispositivosAlarmas', this.query);
  }

  public async datadelmapa(): Promise<void> {
    if (this.alarma?.domicilio) {
      const cordinate = OpenLayersService.lonLatToCoordinate(
        (this.alarma?.domicilio?.geojson as IGeoJSONPoint).coordinates[0],
        (this.alarma?.domicilio?.geojson as IGeoJSONPoint).coordinates[1],
      );
      const marker: MarkerMapa = {
        label: 'puntoSimple',
        coor: cordinate,
      };
      const mapaFacil: MapaFacilData = { markers: [marker] };
      this.datoMapa = mapaFacil;
    }
  }

  public camarasData(dato: IDispositivoAlarma) {
    return dato.camaras.map((camara) => camara.identificacion).join(', ');
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    await this.listar();
    await this.datadelmapa();
    this.loading = false;
  }
}
