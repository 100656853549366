<div
  style="
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  "
>
  <app-tabla
    [totalCount]="totalCount"
    [datos]="documentos"
    [columnas]="columnas"
    [name]="name"
    [pageSizeOptions]="helper.pageSizeOptions"
    [search]="search"
    [(query)]="query"
    (queryChange)="listar()"
    [paginatorArriba]="false"
    [crear]="botonCrear"
    [showHijos]="false"
  ></app-tabla>
  @if (!idEntidad) {
    <!-- Botones -->
    <div class="row-flex-end" style="gap: 10px" align="end">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="helper.volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
    </div>
  }
</div>
