import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ICliente,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  IRecordatorio,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { RecordatoriosService } from '../recordatorios.service';
import { LoginService } from '../../../../login/login.service';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';

interface IRecordatorioTabla extends IRecordatorio {
  identificacion?: string;
  usuarioIdentificacion?: string;
}

@Component({
  selector: 'app-listado-recordatorios',
  templateUrl: './listado-recordatorios.component.html',
  styleUrl: './listado-recordatorios.component.scss',
  standalone: false,
})
export class ListadoRecordatoriosComponent implements OnInit, OnDestroy {
  public idUsuario = LoginService.getUsuario()?._id;
  //// Tabla nueva
  public name = ListadoRecordatoriosComponent.name;
  public recordatorios: IRecordatorioTabla[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<IRecordatorioTabla>[];
  //Subs
  public recordatorios$?: Subscription;
  public clientes$?: Subscription;
  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;
  //
  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeEditar(),
    tooltip: 'Crear Recordatorio',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };

  public search: IRegExpSearch = {
    fields: ['tipo'],
  };
  // FILTROS
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  public filtros: IFiltroTabla[] = [];
  //Query
  public filter: IFilter<IRecordatorio> = {};
  public populate: IPopulate[] = [
    {
      path: 'usuario',
      select: 'nombre',
    },
    {
      path: 'documentacion',
      populate: {
        path: 'activo chofer',
        select: 'identificacion usuario',
      },
    },
    {
      path: 'activo',
      select: 'identificacion',
    },
    {
      path: 'cliente',
      select: 'nombre',
    },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: RecordatoriosService,
    private navigator: Router,
    private route: ActivatedRoute,
  ) {}
  // Listars
  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      Promise.all([this.listar(), this.listarClientes()]);
    } else {
      await this.listar();
    }
  }
  public async listar(): Promise<void> {
    this.recordatorios$?.unsubscribe();
    this.recordatorios$ = this.listados
      .subscribe<IListado<IRecordatorio>>('recordatorios', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.recordatorios = data.datos;
        console.log(`listado de recordatorios`, data);
      });
    await this.listados.getLastValue('recordatorios', this.query);
  }
  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(recordatorio: IRecordatorio) {
    this.navigator.navigate(['editar', recordatorio._id], {
      relativeTo: this.route,
    });
  }

  public async eliminar(recordatorio: IRecordatorio) {
    const res = await this.dialogService.confirm(
      'Eliminar Recordatorio',
      `¿Desea eliminar el recordatorio ${recordatorio.detallesDelMantenimiento}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(recordatorio._id!);
      this.helper.notifSuccess('Recordatorio eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Entidad',
          sortable: true,
        },
        row: {
          parse(dato) {
            if (dato.idActivo) {
              return dato.activo.identificacion;
            }
            if (dato.documentacion) {
              if (dato.documentacion.idActivo) {
                return dato.documentacion.activo.identificacion;
              }
              if (dato.documentacion.idChofer) {
                return dato.documentacion.chofer.usuario;
              }
            }
            return '';
          },
        },
      },
      {
        header: {
          label: 'Recordar Por',
          sortable: true,
        },
        row: {
          parse(dato) {
            const text: string[] = [];
            dato.tipo.forEach((r) => {
              if (r == 'fecha') text.push('Vencimiento');
              if (r == 'km') text.push('Kilometraje');
            });
            return text.toString();
          },
          noData: 'Sin Tipo',
        },
      },
      {
        header: {
          label: 'Notificado',
          sortable: true,
        },
        row: {
          parse(dato) {
            if (dato.notificado) {
              return 'Notificado';
            } else {
              return 'No Notificado';
            }
          },
        },
      },
      {
        header: {
          label: 'Recordar Cuando',
          sortable: true,
        },
        row: {
          parse(dato) {
            const text: string[] = [];
            if (dato.fechaLimite) {
              const date = new Date(dato.fechaLimite);
              text.push(date.toLocaleDateString());
            }
            if (dato.kmLimite) text.push(dato.kmLimite.toString());
            return text.toString();
          },
        },
      },
    ];
    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        this.columnas.push({
          header: {
            label: 'Cliente',
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }
    // Acciones
    this.columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        acciones: [
          {
            tipo: 'material',
            color: 'accent',
            icon: 'edit',
            tooltip: 'Editar',
            click: (dato) => this.editar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'warn',
            icon: 'delete',
            tooltip: 'Eliminar',
            click: (dato) => this.eliminar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
        ],
      },
    });
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();
    await this.listar();
  }

  ngOnDestroy() {
    this.recordatorios$?.unsubscribe();
  }
}
