import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  ICliente,
  IFilter,
  IListado,
  IQueryParam,
  IUpdateUsuario,
  IUsuario,
} from 'modelos/src';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../auxiliares/auxiliares.module';
import { ListadosService } from '../../../auxiliares/servicios';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { Subscription } from 'rxjs';
import { UsuariosService } from '../../entidades/administracion/usuarios/usuarios.service';

@Component({
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-permiso-por-usuario',
  templateUrl: './permiso-por-usuario.component.html',
  styleUrl: './permiso-por-usuario.component.scss',
  standalone: true,
})
export class PermisoPorUsuarioComponent implements OnChanges {
  @Input() public clientes?: ICliente[] = [];
  @Input() public idEntidad: string = null;
  @Input() public guardar?: boolean = false;
  @Input() public usuarioCreado?: string;
  public name = PermisoPorUsuarioComponent.name;
  public usuarios: IUsuario[] = [];
  public usuarios$: Subscription;
  public usuariosElegidos: IUsuario[] = [];
  //

  //Query
  public query: IQueryParam = {
    sort: 'nombre',
    includeChildren: true,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private service: UsuariosService,
    public dialog: MatDialog,
  ) {}

  public async listar(): Promise<void> {
    if (this.clientes.length === 0) return;
    const filter: IFilter<IUsuario> = {
      idCliente: { $in: this.clientes.map((c) => c._id) },
    };
    this.query.filter = JSON.stringify(filter);
    this.usuarios$?.unsubscribe();
    this.usuarios$ = this.listados
      .subscribe<IListado<IUsuario>>('usuarios', this.query)
      .subscribe((data) => {
        this.usuarios = data.datos;
        if (this.usuariosElegidos.length === 0) {
          this.usuariosElegidos = this.usuarios?.filter((u) =>
            u?.permisos?.idsEntidades?.includes(this.idEntidad),
          );
        }
        if (this.usuarioCreado) {
          const u = this.usuarios.find((u) => u._id === this.usuarioCreado);
          if (u) {
            this.usuariosElegidos.push(u);
          }
          this.usuariosElegidos = [...this.usuariosElegidos];
        }
        console.log(`listado de usuarios`, data);
        console.log(this.usuariosElegidos);
      });
    await this.listados.getLastValue('usuarios', this.query);
  }

  private async guardarPermisos() {
    if (this.usuariosElegidos.length === 0) return;
    let permisos = null;
    let idsEntidades = [];
    this.usuarios.forEach(async (user) => {
      permisos = user.permisos;
      idsEntidades = permisos?.idsEntidades || [];
      if (this.usuariosElegidos.find((u) => u._id === user._id)) {
        if (!idsEntidades.includes(this.idEntidad)) {
          idsEntidades.push(this.idEntidad);
          permisos.idsEntidades = idsEntidades;
          const update: IUpdateUsuario = { permisos };
          await this.service.update(user._id, update);
        }
      } else {
        if (idsEntidades.includes(this.idEntidad)) {
          idsEntidades = idsEntidades.filter((id) => id !== this.idEntidad);
          permisos.idsEntidades = idsEntidades;
          const update: IUpdateUsuario = { permisos };
          await this.service.update(user._id, update);
        }
      }
    });
  }

  // Hooks
  async ngOnChanges(changes: SimpleChanges) {
    if (changes['clientes']) {
      await this.listar();
    }
    if (changes['guardar']) {
      if (this.guardar) {
        await this.guardarPermisos();
      }
    }
  }
}
