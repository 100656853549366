<div style="display: flex; align-items: center; justify-content: space-around">
  <vg-player>
    <video
      [id]="name()"
      autoplay
      muted
      crossorigin
      style="width: 100%; height: auto"
    ></video>
  </vg-player>
  @if (loading()) {
    <div style="position: absolute">
      <mat-progress-spinner
        mode="indeterminate"
        diameter="50"
        style="margin: 0 auto"
      ></mat-progress-spinner>
    </div>
  }
</div>
