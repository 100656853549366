import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { ICamara } from 'modelos/src';
import { firstValueFrom, Subscription } from 'rxjs';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { IParamEventoUsuario } from '../../../../standalone/config-evento-usuario/crear-editar-config-evento-usuario-dialog/crear-editar-config-evento-usuario-dialog.component';

@Component({
  selector: 'app-detalles-camara',
  templateUrl: './detalles-camara.component.html',
  styleUrl: './detalles-camara.component.scss',
  standalone: false,
})
export class DetallesCamaraComponent implements OnInit {
  public selectedTab = 0;
  public idCamara: string;
  public camara: ICamara;
  private camara$: Subscription;
  public loading: boolean = true;

  public params?: IParamEventoUsuario;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private listados: ListadosService,
  ) {}

  public tabChanged(event: MatTabChangeEvent): void {
    // Setea el query param  'tab' con el valor del tab seleccionado
    this.selectedTab = event.index;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { tab: this.selectedTab },
      queryParamsHandling: 'merge',
    });
  }

  private async listarCamara() {
    this.camara$?.unsubscribe();
    this.camara$ = this.listados
      .subscribe<ICamara>('camara', this.idCamara)
      .subscribe((data) => {
        this.camara = data;
        console.log(`listado de camara`, data);
      });
    await this.listados.getLastValue('camara', this.idCamara);
  }

  public camaras() {
    this.router.navigate(['main/alarmas/camaras'], {});
  }

  async ngOnInit(): Promise<void> {
    const params = await firstValueFrom(this.activatedRoute.paramMap);
    this.idCamara = params.get('id');
    await this.listarCamara();
    // Obtiene el query param 'tab' y lo asigna al tab seleccionado
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['tab']) {
        this.selectedTab = parseInt(params['tab']);
      }
    });
    this.loading = false;
  }
}
