import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICliente, IParada, IReporte } from 'modelos/src';
import { LoadingService } from '../../../../../auxiliares/servicios/loading.service';
import { LoginService } from '../../../../login/login.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-isla-lite',
  templateUrl: './isla-lite.component.html',
  styleUrl: './isla-lite.component.scss',
  standalone: false,
})
export class IslaLiteVehiculoComponent {
  @Input() public reporte?: IReporte;
  @Output() public reporteChange = new EventEmitter<IReporte>();
  @Output() public reporteVerChange = new EventEmitter<boolean>();
  @Output() public botonSeguirChange = new EventEmitter<boolean>();
  @Input() public botonSeguir: boolean = false;

  public parada?: IParada;
  public idxParada?: number;
  public cliente?: ICliente;

  constructor(
    public loading: LoadingService,
    public dialog: MatDialog,
  ) {
    this.cliente = LoginService.getCliente();
  }

  public close() {
    this.reporte = undefined;
    this.reporteChange.emit(this.reporte);
  }
  public reporteVer() {
    this.reporteVerChange.emit(true);
  }
  public seguir() {
    this.botonSeguir = !this.botonSeguir;
    this.botonSeguirChange.emit(this.botonSeguir);
  }

  public color() {
    return this.botonSeguir
      ? 'height: 90%; transform: scale(0.7); background-color: red'
      : 'height: 90%; transform: scale(0.7)';
  }

  public getFoto() {
    return (
      `url("${this.reporte?.chofer?.datosPersonales?.foto}")` ||
      `url("https://static.vecteezy.com/system/resources/previews/018/765/757/original/user-profile-icon-in-flat-style-member-avatar-illustration-on-isolated-background-human-permission-sign-business-concept-vector.jpg")`
    );
  }
}
