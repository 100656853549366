<div class="flotante-contenedor" style="width: 100%">
  @if (vehiculos) {
    <div class="flotante-vehiculos">
      <span>Vehículos:</span>
      <span>{{ vehiculos }}</span>
    </div>
  }
  <div class="flotante-vehiculos" align="end">
    <ng-select
      [(ngModel)]="cantMapas"
      style="width: 70px"
      [items]="[1, 2, 3, 4, 5, 6, 7, 8, 9]"
      placeholder="Mapas"
      (change)="cantMapasChangeNg($event)"
      [clearable]="false"
      [searchable]="false"
    ></ng-select>
  </div>
</div>
