/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  IConfigEventoUsuario,
  IFilter,
  IGrupo,
  IListado,
  IQueryParam,
  IUsuario,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import {
  IColumnas,
  ICrearBoton,
  IRowIcon,
} from '../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../auxiliares/tabla/filtro/filtro.component';
import { DialogService } from '../../../../auxiliares/dialog/dialog.service';
import { ConfigEventoUsuarioService } from '../../../../auxiliares/servicios/http/config-evento-usuario.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../auxiliares/servicios';
import {
  CrearEditarConfigEventoUsuarioDialogComponent,
  IParamEventoUsuario,
} from '../crear-editar-config-evento-usuario-dialog/crear-editar-config-evento-usuario-dialog.component';
import { ParamsService } from '../../../../auxiliares/servicios/params.service';

@Component({
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-listado-config-evento-usuario',
  templateUrl: './listado-config-evento-usuario.component.html',
  styleUrls: ['./listado-config-evento-usuario.component.scss'],
})
export class ListadoConfigEventoUsuarioComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = 'ListadoComponentConfigEventoUsuarioes';
  public datos?: IConfigEventoUsuario[] = [];
  public columnas?: IColumnas<IConfigEventoUsuario>[];
  public totalCount = 0;
  public botonCrear: ICrearBoton = {
    mostrar: false,
    tooltip: 'Crear Configuración de Notificación',
    color: 'accent',
    icon: 'add',
    accion: () => this.crear(),
    tipo: 'material',
  };
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  public grupo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idGrupo',
    },
    label: 'Grupo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public agrupacion: IFiltroTabla = {
    elementos: [
      // { label: 'Global', value: 'Global' },
      { label: 'Grupo', value: 'Grupo' },
      { label: 'Entidad', value: 'Entidad' },
    ],
    filter: {
      field: 'agrupacion',
    },
    label: 'Agrupación',
    selectLabel: 'label',
    selectValue: 'value',
    tipo: 'select',
  };
  public tipoEnvio: IFiltroTabla = {
    elementos: [
      { label: 'SMS', value: 'SMS' },
      { label: 'WhatsApp', value: 'WhatsApp' },
      { label: 'Llamada', value: 'Llamada' },
      { label: 'Notificación Push', value: 'Notificación Push' },
    ],
    filter: {
      field: 'tipoEnvio',
    },
    label: 'Tipo de envío',
    selectLabel: 'label',
    selectValue: 'value',
    tipo: 'select',
  };

  public filtros: IFiltroTabla[] = [];

  // QUERY+
  private populate = [
    {
      path: 'grupo',
      select: 'nombre',
    },
    {
      path: 'usuarios',
      select: 'usuario',
    },
    {
      path: 'activo',
      select: 'identificacion',
    },
    {
      path: 'alarma',
      select: 'nombre idModelo',
      populate: {
        path: 'modelo',
        select: 'idCodigos',
        populate: {
          path: 'codigos',
          select: 'codigos',
        },
      },
    },
    {
      path: 'condicion.activo.ubicacion.ubicacion',
      select: 'identificacion',
    },
    {
      path: 'categoriaEvento',
      select: 'nombre color',
    },
    {
      path: 'tipoEvento',
      select: 'nombre',
    },
  ];
  public filter: IFilter<IConfigEventoUsuario> = {
    condicion: { $exists: true },
  };
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    populate: JSON.stringify(this.populate),
  };

  @Input() public params?: IParamEventoUsuario;

  // Listado Continuo
  public datos$?: Subscription;
  public grupos?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: ConfigEventoUsuarioService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    private router: Router,
    private route: ActivatedRoute,
    private paramsService: ParamsService,
  ) {}

  // ACCIONES
  public async crear(): Promise<void> {
    if (this.params.crear === 'Dialog') {
      this.paramsService.setParams({ dialog: true, params: this.params });
      const config: MatDialogConfig = {
        width: '900px',
        disableClose: true,
      };
      this.matDialog.open(
        CrearEditarConfigEventoUsuarioDialogComponent,
        config,
      );
    } else {
      this.paramsService.setParams({ dialog: false, params: this.params });
      const extras: NavigationExtras = {
        relativeTo: this.route,
      };
      this.router.navigate(['crear'], extras);
    }
  }

  public async editar(data: IConfigEventoUsuario) {
    if (this.params.crear === 'Dialog') {
      this.paramsService.setParams({
        dialog: true,
        params: this.params,
        edit: data,
      });
      const config: MatDialogConfig = {
        width: '900px',
        disableClose: true,
      };
      this.matDialog.open(
        CrearEditarConfigEventoUsuarioDialogComponent,
        config,
      );
    } else {
      this.params.alarma = data.alarma;
      this.paramsService.setParams({
        dialog: false,
        params: this.params,
        edit: data,
      });
      const extras: NavigationExtras = {
        relativeTo: this.route,
      };
      this.router.navigate(['editar', data._id], extras);
    }
  }

  public tipoIcono(dato: IConfigEventoUsuario): string {
    return dato.activa ? 'check' : 'cross';
  }

  public async cambiarEstado(dato: IConfigEventoUsuario): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿${dato.activa ? 'Desactivar' : 'Activar'} la configuración?`,
    );
    if (confirm) {
      try {
        await this.service.update(dato._id!, { activa: !dato.activa });
        this.helper.notifSuccess(
          `${dato.activa ? 'Desactivado' : 'Activado'}' correctamene`,
        );
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  public async eliminar(dato: IConfigEventoUsuario): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar la configuracion de notificación?`,
    );
    if (confirm) {
      try {
        await this.service.delete(dato._id!);
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  public async listar(): Promise<void> {
    const filter = this.query.filter ? JSON.parse(this.query.filter) : {};
    if (this.params.idEntidad) {
      filter['idEntidad'] = this.params.idEntidad;
    }
    if (this.params.idGrupo) {
      filter['idGrupo'] = this.params.idGrupo;
    }
    this.query.filter = JSON.stringify(filter);

    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<
        IListado<IConfigEventoUsuario>
      >('configEventoUsuarios', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de configEventoUsuarios`, data);
      });
    await this.listadosService.getLastValue('configEventoUsuarios', this.query);
  }

  private async listarGrupos(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.grupos?.unsubscribe();
    this.grupos = this.listadosService
      .subscribe<IListado<IGrupo>>('grupos', query)
      .subscribe((data) => {
        this.grupo.elementos = data.datos;
        console.log(`listado de grupos`, data);
      });
    await this.listadosService.getLastValue('grupos', query);
  }

  private setColumnas() {
    this.columnas = [
      // Activa
      {
        header: {
          label: 'Activa',
          sortable: true,
        },
        row: {
          icono: (dato) => {
            const iconCheck: IRowIcon = {
              icon: 'check',
              color: 'green',
              tipo: 'material',
            };
            const iconClose: IRowIcon = {
              icon: 'close',
              color: 'red',
              tipo: 'material',
            };
            return dato.activa ? iconCheck : iconClose;
          },
        },
      },
      // Categoria de Evento
      {
        header: {
          label: 'Categoría',
        },
        row: {
          html: (dato) => {
            if (dato.categoriaEvento) {
              let html = `<div style="display: flex; gap: 1em; align-items: center">`;
              if (dato.categoriaEvento.color) {
                html += `<span style="color: ${dato.categoriaEvento.color}; font-size: 2em; overflow: visible"> &#x2B24; </span>`;
              }
              html += `<span>${dato.categoriaEvento.nombre} </span>`;
              html += `</div>`;
              return html;
            }
            return '';
          },
          noData: 'Sin Categoría',
        },
      },
      // Tipo de Evento
      {
        header: {
          label: 'Tipo de Evento',
        },
        row: {
          parse(dato) {
            if (dato?.tipoEvento) return dato.tipoEvento.nombre;
            else return '';
          },
          noData: 'Sin tipo de Evento',
        },
      },
    ];
    if (!this.params?.tipoEntidad) {
      this.columnas.push(
        // Tipos de Dispositivo
        {
          header: {
            label: 'Tipo de Entidad',
            sortable: true,
          },
          row: {
            field: 'tipoEntidad',
          },
        },
      );
    }

    this.columnas = this.columnas.concat([
      // Agrupación
      {
        header: {
          label: 'Agrupación',
        },
        row: {
          field: 'agrupacion',
        },
      },
      // Nombre
      {
        header: {
          label: 'Nombre',
        },
        row: {
          html: (dato) => this.parseNombre(dato),
        },
      },
      // Condicion
      {
        header: {
          label: 'Condición',
        },
        row: {
          html: (dato) => {
            let html = '<div>';
            switch (dato.tipoEntidad as any) {
              case 'Vehiculo':
              case 'Activo': {
                // Condicion Ubicacion
                if (dato.condicion?.activo?.ubicacion) {
                  const dentro = dato.condicion?.activo?.ubicacion?.dentro;
                  const fuera = dato.condicion?.activo?.ubicacion?.fuera;
                  const ubicacion =
                    dato.condicion?.activo?.ubicacion?.ubicacion
                      ?.identificacion;
                  html += `Ubicación: ${ubicacion} `;
                  html += `Dentro ${dentro ? 'Sí' : 'No'} `;
                  html += `Fuera ${fuera ? 'Sí' : 'No'} `;
                }
                // Condicion Velocidad
                if (dato.condicion?.activo?.velocidad) {
                  const velocidad =
                    dato.condicion?.activo?.velocidad['superior a'];
                  html += `Velocidad superior a ${velocidad} Km/h`;
                }
                // Condicion Detenido
                if (dato.condicion?.activo?.detenido) {
                  const detenido = dato.condicion?.activo?.detenido['mas de'];
                  html += `Detenido más de ${detenido} minutos`;
                }
                break;
              }
              case 'Alarma': {
                // Condicion Llega evento
                if (dato.condicion?.alarma?.['llega evento']) {
                  const codigo = dato.condicion?.alarma?.['llega evento'][0];
                  html += `Llega evento ${codigo} - ${dato.alarma?.modelo?.codigos?.codigos.find((c) => c.codigo === codigo)?.descripcion}`;
                }
                // Condicion No Llega evento
                if (dato.condicion?.alarma?.['no llega evento']) {
                  const codigo = dato.condicion?.alarma?.['no llega evento'][0];
                  html += `No llega evento ${codigo} - ${dato.alarma?.modelo?.codigos?.codigos.find((c) => c.codigo === codigo)?.descripcion}`;
                }
                break;
              }
              default: {
                break;
              }
            }
            html += '</div>';
            return html;
          },
        },
      },
      // Usuarios
      {
        header: {
          label: 'Usuarios',
        },
        row: {
          html: (dato) => this.parseUsuarios(dato.usuarios),
          noData: 'No se notifica',
        },
      },
      // Medio
      // {
      //   header: {
      //     label: 'Medio',
      //   },
      //   row: {
      //     field: 'tipoEnvio',
      //   },
      // },
      // Frecuencia
      {
        header: {
          label: 'Frecuencia',
        },
        row: {
          html: (dato) => this.parseFrecuencia(dato),
        },
      },
      // Requiere atencion
      {
        header: {
          label: 'Requiere Atención',
          sortable: true,
        },
        row: {
          icono: (dato) => {
            const iconCheck: IRowIcon = {
              icon: 'check',
              color: 'green',
              tipo: 'material',
            };
            const iconClose: IRowIcon = {
              icon: 'close',
              color: 'red',
              tipo: 'material',
            };
            return dato.atender ? iconCheck : iconClose;
          },
        },
      },
      // Acciones
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'material',
              color: 'accent',
              icon: 'edit',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'delete',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'check',
              tooltip: 'Activar',
              oculta: (dato) => dato.activa,
              click: (dato) => this.cambiarEstado(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'close',
              tooltip: 'Desactivar',
              oculta: (dato) => !dato.activa,
              click: (dato) => this.cambiarEstado(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
          ],
        },
      },
    ]);
  }

  private parseNombre(dato: IConfigEventoUsuario) {
    const grupo = dato.grupo?.nombre;

    switch (dato.agrupacion) {
      // case 'Global': {
      //   return 'Todos';
      // }
      case 'Grupo': {
        return `${grupo}`;
      }
      case 'Entidad': {
        switch (dato.tipoEntidad as any) {
          case 'Vehiculo':
          case 'Activo': {
            return `${dato.activo?.identificacion}`;
          }
          case 'Alarma': {
            return `${dato.alarma?.nombre}`;
          }
          default: {
            return '';
          }
        }
      }
      default:
        return '';
    }
  }

  private parseUsuarios(d: IUsuario[]) {
    let str = '';
    for (const u of d) {
      str = str + `<div>${u.usuario}</div>`;
    }
    return str;
  }

  private parseFrecuencia(dato: IConfigEventoUsuario) {
    const generarSiNoSeCumple = dato.generarSiNoSeCumple;
    const generarSoloUnaVez = dato.generarSoloUnaVez;
    const dias = dato.dias;
    const horaInicio = dato.horaInicio;
    const horaFin = dato.horaFin;
    const validaDesde = dato.validaDesde;
    const validaHasta = dato.validaHasta;

    if (dias) {
      return `Días: ${dias.join(', ')} ${horaInicio} - ${horaFin}`;
    }
    if (validaDesde && validaHasta) {
      let html = `Desde ${new Date(validaDesde).toLocaleDateString(undefined, { hour12: false })} hasta ${new Date(validaHasta).toLocaleDateString(undefined, { hour12: false })}`;
      if (generarSiNoSeCumple) {
        html += ' (Generar evento si no se cumple)';
      }
      if (generarSoloUnaVez) {
        html += ' (Generar evento solo una vez)';
      }
      return html;
    }
    if (generarSoloUnaVez) {
      return 'En cualquier momento 1 sola vez';
    }
    return 'Continuo';
  }

  async ngOnInit(): Promise<void> {
    this.setColumnas();

    if (this.params) {
      // Si se recibe el parametro desde el componente padre
      console.log(
        `params desde el padre en listado de config evento usuario`,
        this.params,
      );
      if (this.params.botonCrear) this.botonCrear.mostrar = true;
    } else {
      // Si se recibe el parametro desde la URL
      this.route.queryParams.subscribe((params) => {
        this.params = { ...this.params, ...(params as any) };
        console.log(
          `params desde url en listado de config evento usuario`,
          this.params,
        );
      });
      this.botonCrear.mostrar = true;
    }
    await Promise.all([this.listar(), this.listarGrupos()]);
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
    this.grupos?.unsubscribe();
  }
}
