<div class="isla-contenedor magico-100">
  <div (click)="close()" class="isla-close">
    <mat-icon style="transform: scale(0.7, 0.7)" color="warn">close</mat-icon>
  </div>
  @if (alarma) {
    <!-- Loading -->
    @if (this.loading.getLoading()) {
      <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    }
    <!-- Identificacion -->
    <span
      >🏠
      {{ alarma?.nombre }}
    </span>

    <!-- Cliente -->
    @if (cliente?.tipoCliente != "Final") {
      <div class="isla-dato">
        <div>Cliente:</div>
        <div>
          {{ alarma?.cliente.nombre }}
        </div>
      </div>
    }
    @if (alarma?.idsCamaras) {
      <div
        style="
          max-height: 30vh;
          min-height: 20vh;
          display: flex;
          justify-content: center;
          overflow-y: scroll;
          overflow-x: hidden;
        "
      >
        <app-camaras-multiple [data]="alarma.idsCamaras">
        </app-camaras-multiple>
      </div>
    }
    <!-- Editar -->
    <div class="isla-editar">
      <!-- Editar Activo -->
      <button mat-raised-button color="primary" (click)="editar()">
        Editar
      </button>
    </div>
  }
</div>
