import { Component, OnDestroy, OnInit, Optional, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  EstadoCuenta,
  ICliente,
  ICreateCliente,
  IListado,
  IQueryParam,
  IServicioContratado,
  ITipoCliente,
  IUpdateCliente,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ClientesService } from '../../../../../auxiliares/servicios/http/clientes.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { LoginService } from '../../../../login/login.service';
import { ThemesService } from '../../../../../auxiliares/servicios/themes.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-crear-editar-cliente',
  templateUrl: './crear-editar-cliente.component.html',
  styleUrl: './crear-editar-cliente.component.scss',
  standalone: false,
})
export class CrearEditarClienteComponent implements OnInit, OnDestroy {
  public id: string | null = null;

  public title = '';
  public cliente?: ICliente;
  public cliente$?: Subscription;
  public tipoCliente: ITipoCliente[] = [];

  public estadoCuenta: EstadoCuenta[] = ['Activo', 'Suspendido', 'Moroso'];

  public clientes?: ICliente[];
  public clientes$?: Subscription;

  public urlIcono?: string;
  public urlBanner?: string;
  public urlBannerModoOscuro?: string;

  public form?: FormGroup;

  public clientePropio?: ICliente;
  public editandoPropio = false;

  public costoTotal = signal(0);

  public servicios: IServicioContratado[] = [];
  private servicios$?: Subscription;
  //Geters
  get config() {
    return this.form?.get('config') as FormGroup;
  }

  get imagenes() {
    return this.config?.get('imagenes') as FormGroup;
  }

  get tema() {
    return this.config?.get('tema') as FormGroup;
  }

  get moduloColectivos() {
    return this.config?.get('moduloColectivos') as FormGroup;
  }

  get moduloAlarmasDomiciliarias() {
    return this.config?.get('moduloAlarmasDomiciliarias') as FormGroup;
  }

  get moduloActivos() {
    return this.config?.get('moduloActivos') as FormGroup;
  }

  get moduloVehiculos() {
    return this.config?.get('moduloVehiculos') as FormGroup;
  }

  get moduloAdministracion() {
    return this.config?.get('moduloAdministracion') as FormGroup;
  }

  get moduloEventosTecnicos() {
    return this.config?.get('moduloEventosTecnicos') as FormGroup;
  }

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<CrearEditarClienteComponent>,
    private route: ActivatedRoute,
    private listados: ListadosService,
    private service: ClientesService,
    public helper: HelperService,
    public themesService: ThemesService,
    private dialogService: DialogService,
  ) {}

  public async listar(): Promise<void> {
    this.cliente$?.unsubscribe();
    this.cliente$ = this.listados
      .subscribe<ICliente>('cliente', this.id!)
      .subscribe((data) => {
        this.cliente = data;
        console.log(`listado de cliente`, data);
      });
    await this.listados.getLastValue('cliente', this.id!);
  }

  public async listarServicios(): Promise<void> {
    this.servicios$?.unsubscribe();
    this.servicios$ = this.listados
      .subscribe<IListado<IServicioContratado>>('servicioContratados', {})
      .subscribe((data) => {
        this.servicios = data.datos;
        console.log(`listado de servicioContratados`, data);
      });
    await this.listados.getLastValue('servicioContratados', {});
  }

  public async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      limit: 0,
      select: 'nombre',
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  private getData() {
    const data: ICreateCliente | IUpdateCliente = {
      nombre: this.form?.get('nombre')?.value,
      tipoCliente: this.form?.get('tipoCliente')?.value,
      idServiciosContratados: this.form?.get('idServiciosContratados')?.value,
      numeroCliente: this.form?.get('numeroCliente')?.value,
      estadoDeCuenta: this.form?.get('estadoDeCuenta')?.value,
      config: {
        imagenes: {
          icono: this.urlIcono,
          banner: this.urlBanner,
          bannerModoOscuro: this.urlBannerModoOscuro,
        },
        tema: {
          primaryColor: this.tema?.get('primaryColor')?.value,
          accentColor: this.tema?.get('accentColor')?.value,
          warnColor: this.tema?.get('warnColor')?.value,
          // typography: this.tema?.get('typography')?.value,
        },
        moduloColectivos: {
          activo: this.moduloColectivos?.get('activo')?.value,
          crearDispositivos:
            this.moduloColectivos?.get('crearDispositivos')?.value,
          compartirFlota: this.moduloColectivos?.get('compartirFlota')?.value,
          derivarEventos: this.moduloColectivos?.get('derivarEventos')?.value,
        },
        moduloAlarmasDomiciliarias: {
          activo: this.moduloAlarmasDomiciliarias?.get('activo')?.value,
          crearDispositivos:
            this.moduloAlarmasDomiciliarias?.get('crearDispositivos')?.value,
          compartirAlarmas:
            this.moduloAlarmasDomiciliarias?.get('compartirAlarmas')?.value,
          derivarEventos:
            this.moduloAlarmasDomiciliarias?.get('derivarEventos')?.value,
        },
        moduloActivos: {
          activo: this.moduloActivos?.get('activo')?.value,
          crearDispositivos:
            this.moduloActivos?.get('crearDispositivos')?.value,
          compartirActivos: this.moduloActivos?.get('compartirActivos')?.value,
          derivarEventos: this.moduloActivos?.get('derivarEventos')?.value,
        },
        moduloVehiculos: {
          activo: this.moduloVehiculos?.get('activo')?.value,
          crearVehiculos: this.moduloVehiculos?.get('crearVehiculos')?.value,
          compartirVehiculos:
            this.moduloVehiculos?.get('compartirVehiculos')?.value,
          derivarEventos: this.moduloVehiculos?.get('derivarEventos')?.value,
        },
        moduloAdministracion: {
          activo: this.moduloAdministracion?.get('activo')?.value,
          crearUsuarios: this.moduloAdministracion?.get('crearUsuarios')?.value,
          crearServicios:
            this.moduloAdministracion?.get('crearServicios')?.value,
          crearApikeys: this.moduloAdministracion?.get('crearApikeys')?.value,
        },
        moduloEventosTecnicos: {
          activo: this.moduloAdministracion?.get('activo')?.value,
        },
        idsClientesQuePuedenAtenderEventos: this.config?.get(
          'idsClientesQuePuedenAtenderEventos',
        )?.value,
        idsClientesQuePuedenAtenderEventosTecnicos: this.config?.get(
          'idsClientesQuePuedenAtenderEventosTecnicos',
        )?.value,
      },
    };
    return data;
  }

  private createForm() {
    this.form = new FormGroup({
      nombre: new FormControl(this.cliente?.nombre, Validators.required),
      tipoCliente: new FormControl(
        this.cliente?.tipoCliente,
        this.id ? Validators.required : null,
      ),
      idServiciosContratados: new FormControl(
        this.cliente?.idServiciosContratados,
      ),
      numeroCliente: new FormControl(this.cliente?.numeroCliente),
      estadoDeCuenta: new FormControl(this.cliente?.estadoDeCuenta),
      config: new FormGroup({
        imagenes: new FormGroup({
          icono: new FormControl(this.cliente?.config?.imagenes?.icono),
          banner: new FormControl(this.cliente?.config?.imagenes?.banner),
          bannerModoOscuro: new FormControl(
            this.cliente?.config?.imagenes?.bannerModoOscuro,
          ),
        }),
        tema: new FormGroup({
          primaryColor: new FormControl(
            this.cliente?.config?.tema?.primaryColor,
          ),
          accentColor: new FormControl(this.cliente?.config?.tema?.accentColor),
          warnColor: new FormControl(this.cliente?.config?.tema?.warnColor),
          // typography: new FormControl(this.cliente?.config?.tema?.typography),
        }),
        moduloColectivos: new FormGroup({
          activo: new FormControl(
            this.cliente?.config?.moduloColectivos?.activo,
          ),
          crearDispositivos: new FormControl(
            this.cliente?.config?.moduloColectivos?.crearDispositivos,
          ),
          compartirFlota: new FormControl(
            this.cliente?.config?.moduloColectivos?.compartirFlota,
          ),
          derivarEventos: new FormControl(
            this.cliente?.config?.moduloColectivos?.derivarEventos,
          ),
        }),
        moduloActivos: new FormGroup({
          activo: new FormControl(this.cliente?.config?.moduloActivos?.activo),
          crearDispositivos: new FormControl(
            this.cliente?.config?.moduloActivos?.crearDispositivos,
          ),
          compartirActivos: new FormControl(
            this.cliente?.config?.moduloActivos?.compartirActivos,
          ),
          derivarEventos: new FormControl(
            this.cliente?.config?.moduloActivos?.derivarEventos,
          ),
        }),
        moduloVehiculos: new FormGroup({
          activo: new FormControl(
            this.cliente?.config?.moduloVehiculos?.activo,
          ),
          crearVehiculos: new FormControl(
            this.cliente?.config?.moduloVehiculos?.crearVehiculos,
          ),
          compartirVehiculos: new FormControl(
            this.cliente?.config?.moduloVehiculos?.compartirVehiculos,
          ),
          derivarEventos: new FormControl(
            this.cliente?.config?.moduloVehiculos?.derivarEventos,
          ),
        }),
        moduloAlarmasDomiciliarias: new FormGroup({
          activo: new FormControl(
            this.cliente?.config?.moduloAlarmasDomiciliarias?.activo,
          ),
          crearDispositivos: new FormControl(
            this.cliente?.config?.moduloAlarmasDomiciliarias?.crearDispositivos,
          ),
          compartirAlarmas: new FormControl(
            this.cliente?.config?.moduloAlarmasDomiciliarias?.compartirAlarmas,
          ),
          derivarEventos: new FormControl(
            this.cliente?.config?.moduloAlarmasDomiciliarias?.derivarEventos,
          ),
        }),
        moduloAdministracion: new FormGroup({
          activo: new FormControl(
            this.cliente?.config?.moduloAdministracion?.activo,
          ),
          crearUsuarios: new FormControl(
            this.cliente?.config?.moduloAdministracion?.crearUsuarios,
          ),
          crearServicios: new FormControl(
            this.cliente?.config?.moduloAdministracion?.crearServicios,
          ),
          crearApikeys: new FormControl(
            this.cliente?.config?.moduloAdministracion?.crearApikeys,
          ),
        }),
        moduloEventosTecnicos: new FormGroup({
          activo: new FormControl(
            this.cliente?.config?.moduloEventosTecnicos?.activo,
          ),
        }),
        idsClientesQuePuedenAtenderEventos: new FormControl(
          this.cliente?.config?.idsClientesQuePuedenAtenderEventos,
        ),
        idsClientesQuePuedenAtenderEventosTecnicos: new FormControl(
          this.cliente?.config?.idsClientesQuePuedenAtenderEventosTecnicos,
        ),
      }),
    });
  }

  public async onSubmit() {
    const res = await this.dialogService.confirm(
      `${this.title} Cliente`,
      `¿Desea ${this.title.toLowerCase()} el cliente?`,
    );
    if (!res) return;
    try {
      let res = null;
      if (this.id) {
        // Update
        if (this.clientePropio?._id !== this.id) {
          this.themesService.rollbackTema();
        }
        const update = this.getData() as IUpdateCliente;
        if (!update) return;
        await this.service.update(this.id, update);
        this.helper.notifSuccess('Cliente actualizado');
      } else {
        // Create
        this.themesService.rollbackTema();
        const create = this.getData() as ICreateCliente;
        if (!create) return;
        res = await this.service.create(create);
        this.helper.notifSuccess('Cliente creado');
      }
      if (this.dialogRef) {
        this.dialogRef.close(res?._id);
      } else {
        this.helper.volver();
      }
    } catch (error) {
      this.helper.notifError('Error al crear/actualizar cliente');
      console.error(`error`, error);
    }
  }

  public onColorChange(event: string, control: string) {
    this.tema?.get(control)?.patchValue(event);
  }

  private getTipoCliente() {
    const tipoPropio = this.clientePropio?.tipoCliente;
    switch (tipoPropio) {
      case 'Mayorista':
        this.tipoCliente = ['Mayorista', 'Minorista', 'Final'];
        return;
      case 'Minorista':
        this.tipoCliente = ['Final'];
        return;
      case 'Final':
        this.tipoCliente = [];
        return;
      default:
        this.helper.notifError('Error al obtener tipo de cliente');
        return;
    }
  }

  public previewTema() {
    this.themesService.setTemaTemporal(this.getData()?.config);
  }
  public rollbackTema() {
    this.themesService.rollbackTema();
  }

  public calcularCostoTotal() {
    const ids = this.form?.get('idServiciosContratados')?.value;
    if (!ids) return;
    const servicios = this.servicios.filter((servicio) =>
      ids.includes(servicio._id),
    );
    let total = 0;
    for (const s of servicios) {
      total += Number(s.costo);
    }
    this.costoTotal.set(total);
  }

  // Hooks
  async ngOnInit() {
    this.route.paramMap.subscribe(async (params) => {
      this.id = params.get('id');
      this.title = this.id ? 'Editar' : 'Crear';
      this.clientePropio = LoginService.getCliente();
      this.editandoPropio = this.id === this.clientePropio?._id;
      this.getTipoCliente();
      await this.listarServicios();
      if (this.id) {
        await this.listar();
        this.urlBanner = this.cliente?.config?.imagenes?.banner;
        this.urlBannerModoOscuro =
          this.cliente?.config?.imagenes?.bannerModoOscuro;
        this.urlIcono = this.cliente?.config?.imagenes?.icono;
      }
      this.createForm();
      this.calcularCostoTotal();
    });
  }

  ngOnDestroy() {
    this.cliente$?.unsubscribe();
    this.servicios$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
