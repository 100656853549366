import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ListadoGruposComponent } from '../../standalone/grupos/listado-grupos/listado-grupos.component';
import { ListadoUbicacionesComponent } from '../../standalone/ubicaciones/listado-ubicaciones/listado-ubicaciones.component';
import { CrearEditarUbicacionComponent } from '../../standalone/ubicaciones/crear-editar-ubicacion/crear-editar-ubicacion.component';
import { ListadoVehiculosComponent } from './vehiculos/listado-vehiculos/listado-vehiculos.component';
import { DetallesVehiculoComponent } from './vehiculos/detalles-vehiculo/detalles-vehiculo.component';
import { CrearEditarVehiculoComponent } from './crear-editar-vehiculo/crear-editar-vehiculo.component';
import { MapasVehiculosComponent } from './mapas-vehiculos/mapas-vehiculos.component';
import { ListadoChoferesComponent } from '../../standalone/choferes/listado-choferes/listado-choferes.component';

const routes: Routes = [
  { path: '', redirectTo: 'mapa', pathMatch: 'full' },
  { path: 'vehiculos', component: ListadoVehiculosComponent, canActivate: [] },
  // Vehiculos
  {
    path: 'vehiculos/crear',
    component: CrearEditarVehiculoComponent,
    canActivate: [],
  },
  {
    path: 'vehiculos/editar',
    component: CrearEditarVehiculoComponent,
    canActivate: [],
  },
  {
    path: 'vehiculos/detalles/:id',
    component: DetallesVehiculoComponent,
    canActivate: [],
  },
  // Mapa
  {
    path: 'mapa',
    component: MapasVehiculosComponent,
    canActivate: [],
  },
  // Ubicaciones
  {
    path: 'ubicaciones/:categoria',
    component: ListadoUbicacionesComponent,
    canActivate: [],
  },
  {
    path: 'ubicaciones/:categoria/crear',
    component: CrearEditarUbicacionComponent,
    canActivate: [],
  },
  {
    path: 'ubicaciones/:categoria/editar/:id',
    component: CrearEditarUbicacionComponent,
    canActivate: [],
  },
  // Agrupaciones
  {
    path: 'grupos/:categoria',
    component: ListadoGruposComponent,
    canActivate: [],
  },
  // Choferes
  {
    path: 'choferes',
    component: ListadoChoferesComponent,
    canActivate: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VehiculosRoutingModule {}
