@if (usuarioPropio.roles.includes("Técnico")) {
  <app-vista-tecnicos></app-vista-tecnicos>
} @else {
  <mat-tab-group>
    <mat-tab label="Servicios en Vivo">
      <app-eventos-tecnicos></app-eventos-tecnicos>
    </mat-tab>
    <mat-tab label="Servicios Finalizados">
      <app-eventos-tecnicos-finalizados></app-eventos-tecnicos-finalizados>
    </mat-tab>
  </mat-tab-group>
}
