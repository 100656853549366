/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { IConfigCliente } from 'modelos/src';
import { Subject } from 'rxjs';

import tinycolor from 'tinycolor2';
import { HelperService } from './helper.service';

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ThemesService {
  /// Default Colors
  public primaryColor = '#e76f51';
  public primaryColorChange: Subject<string> = new Subject<string>();
  public primaryColorPalette: Color[] = [];

  public accentColor = '#f4a261';
  public accentColorChange: Subject<string> = new Subject<string>();
  public accentColorPalette: Color[] = [];

  public warnColor = '#ff1a1a';
  public warnColorChange: Subject<string> = new Subject<string>();
  public warnColorPalette: Color[] = [];

  /// Images
  // public backgroundImage = 'url(assets/images/background.jpg)';
  public logo?: string | null;
  public logoChange: Subject<string | null> = new Subject<string | null>();

  public banner?: string | null;
  public bannerChange: Subject<string | null> = new Subject<string | null>();

  public bannerDarkMode?: string | null;
  public bannerDarkModeChange: Subject<string | null> = new Subject<
    string | null
  >();

  // accessing favicon link tag
  favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');

  constructor() {
    this.primaryColorChange.subscribe((value) => {
      this.primaryColor = value;
    });

    this.accentColorChange.subscribe((value) => {
      this.accentColor = value;
    });

    this.warnColorChange.subscribe((value) => {
      this.warnColor = value;
    });
  }

  private savePrimaryColor() {
    this.primaryColorPalette = this.computeColors(this.primaryColor);

    for (const color of this.primaryColorPalette) {
      const key1 = `--theme-primary-${color.name}`;
      const value1 = color.hex;
      const key2 = `--theme-primary-contrast-${color.name}`;
      // const value2 = color.darkContrast ? 'rgb(0 0 0 / 87%)' : 'white';
      const value2 = HelperService.invertirColor(color.hex);
      document.documentElement.style.setProperty(key1, value1);
      document.documentElement.style.setProperty(key2, value2);
    }
  }

  private saveAccentColor() {
    this.accentColorPalette = this.computeColors(this.accentColor);

    for (const color of this.accentColorPalette) {
      const key1 = `--theme-accent-${color.name}`;
      const value1 = color.hex;
      const key2 = `--theme-accent-contrast-${color.name}`;
      const value2 = HelperService.invertirColor(color.hex);
      // const value2 = color.darkContrast ? 'rgb(0 0 0 / 87%)' : 'white';
      document.documentElement.style.setProperty(key1, value1);
      document.documentElement.style.setProperty(key2, value2);
    }
  }

  private saveWarnColor() {
    this.warnColorPalette = this.computeColors(this.warnColor);

    for (const color of this.warnColorPalette) {
      const key1 = `--theme-warn-${color.name}`;
      const value1 = color.hex;
      const key2 = `--theme-warn-contrast-${color.name}`;
      const value2 = HelperService.invertirColor(color.hex);
      // const value2 = color.darkContrast ? 'rgb(0 0 0 / 87%)' : 'white';
      document.documentElement.style.setProperty(key1, value1);
      document.documentElement.style.setProperty(key2, value2);
    }
  }

  private computeColors(hex: string): Color[] {
    return [
      this.getColorObject(tinycolor(hex).lighten(52), '50'),
      this.getColorObject(tinycolor(hex).lighten(37), '100'),
      this.getColorObject(tinycolor(hex).lighten(26), '200'),
      this.getColorObject(tinycolor(hex).lighten(12), '300'),
      this.getColorObject(tinycolor(hex).lighten(6), '400'),
      this.getColorObject(tinycolor(hex), '500'),
      this.getColorObject(tinycolor(hex).darken(6), '600'),
      this.getColorObject(tinycolor(hex).darken(12), '700'),
      this.getColorObject(tinycolor(hex).darken(18), '800'),
      this.getColorObject(tinycolor(hex).darken(24), '900'),
      this.getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
      this.getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
      this.getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
      this.getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700'),
    ];
  }

  private getColorObject(value: any, name: string): Color {
    const c = tinycolor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight(),
    };
  }

  init() {
    if (
      localStorage.getItem('primaryColor') &&
      localStorage.getItem('accentColor') &&
      localStorage.getItem('warnColor')
    ) {
      this.setPrimary(localStorage.getItem('primaryColor')!);
      this.setaccent(localStorage.getItem('accentColor')!);
      this.setWarn(localStorage.getItem('warnColor')!);
    }
    this.savePrimaryColor();
    this.saveAccentColor();
    this.saveWarnColor();
  }

  // Publics
  public setPrimary(color: string) {
    this.primaryColorChange.next(color);
    this.savePrimaryColor();
  }

  public setaccent(color: string) {
    this.accentColorChange.next(color);
    this.saveAccentColor();
  }

  public setWarn(color: string) {
    this.warnColorChange.next(color);
    this.saveWarnColor();
  }

  public setLogo(logo: string | null) {
    this.logo = logo;
    this.favIcon!.href = logo!;
    this.logoChange.next(logo);
  }

  public setBanner(banner: string | null) {
    this.banner = banner;
    this.bannerChange.next(banner);
  }
  public setBannerDarkMode(banner: string | null) {
    this.bannerDarkMode = banner;
    this.bannerDarkModeChange.next(banner);
  }

  public setTema(config: IConfigCliente) {
    if (config.tema?.primaryColor) {
      this.setPrimary(config.tema.primaryColor);
      localStorage.setItem('primaryColor', config.tema.primaryColor);
    }
    if (config.tema?.accentColor) {
      this.setaccent(config.tema.accentColor);
      localStorage.setItem('accentColor', config.tema.accentColor);
    }
    if (config.tema?.warnColor) {
      this.setWarn(config.tema.warnColor);
      localStorage.setItem('warnColor', config.tema.warnColor);
    }
    if (config.imagenes?.icono) {
      this.setLogo(config.imagenes.icono);
      localStorage.setItem('logo', config.imagenes.icono);
    }
    if (config.imagenes?.banner) {
      this.setBanner(config.imagenes.banner);
      localStorage.setItem('banner', config.imagenes.banner);
    }
    if (config.imagenes?.bannerModoOscuro) {
      this.setBannerDarkMode(config.imagenes.bannerModoOscuro);
      localStorage.setItem('bannerDarkMode', config.imagenes.bannerModoOscuro);
    }
  }

  public setTemaTemporal(config?: IConfigCliente) {
    if (!config) {
      return;
    }
    if (config.tema?.primaryColor) {
      this.setPrimary(config.tema.primaryColor);
    }
    if (config.tema?.accentColor) {
      this.setaccent(config.tema.accentColor);
    }
    if (config.tema?.warnColor) {
      this.setWarn(config.tema.warnColor);
    }
    if (config.imagenes?.icono) {
      this.setLogo(config.imagenes.icono);
    }
    if (config.imagenes?.banner) {
      this.setBanner(config.imagenes.banner);
    }
  }

  public rollbackTema() {
    const primary = localStorage.getItem('primaryColor');
    if (primary) {
      this.setPrimary(primary);
    }
    const accent = localStorage.getItem('accentColor');
    if (accent) {
      this.setaccent(accent);
    }
    const warn = localStorage.getItem('warnColor');
    if (warn) {
      this.setWarn(warn);
    }
    const logo = localStorage.getItem('logo');
    if (logo) {
      this.setLogo(logo);
    }
    const banner = localStorage.getItem('banner');
    if (banner) {
      this.setBanner(banner);
    }
  }
}
