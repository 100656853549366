<mat-card>
  <!-- Headers -->
  <mat-card-header>
    @if (dialogRef) {
      <mat-card-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
        Modo Estacionado
      </mat-card-title>
    } @else {
      <mat-card-title> Modo Estacionado </mat-card-title>
    }
  </mat-card-header>

  <form
    *ngIf="form"
    id="form"
    [formGroup]="form"
    autocomplete="off"
    (ngSubmit)="onSubmit()"
  >
    <!-- Form -->
    <mat-card-content
      style="margin: 2em 0; overflow: auto"
      [class.cardContentDialog]="dialogRef"
      [class.cardContentFullscreen]="!dialogRef"
    >
      <mat-form-field [formGroup]="estacionadoForm">
        <mat-label>Distancia de Aviso</mat-label>
        <input matInput formControlName="distanciaDeAviso" type="number" />
      </mat-form-field>

      <!-- USUARIOS -->
      <ng-select
        [items]="usuarios"
        placeholder="Usuarios"
        loadingText="Cargando..."
        typeToSearchText="Buscar..."
        notFoundText="No se encontraron resultados"
        formControlName="idsUsuarios"
        [multiple]="true"
        [searchFn]="searchUsuario"
        [appendTo]="'body'"
        [closeOnSelect]="false"
        [bindValue]="'_id'"
      >
        <ng-template ng-label-tmp let-item="item">
          <span>{{ item.usuario }} </span>
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <div style="padding-bottom: 5px">
            <span>{{ item.usuario }} </span>
            <span style="font-size: 0.7em">
              ({{ item.datosPersonales?.telefono }})
            </span>
          </div>
        </ng-template>
      </ng-select>
    </mat-card-content>

    <mat-divider></mat-divider>

    <!-- Botones -->
    <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="volver()"
        text=""
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid || loading"
        [color]="form?.value?.activa ? 'warn' : 'accent'"
        [icon]="form?.value?.activa ? 'cancel' : 'check_circle'"
        type="submit"
      ></app-button>
    </mat-card-actions>
  </form>
</mat-card>
