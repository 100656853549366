@if (reporteSeleccionado) {
  <div id="isla" class="ol-isla">
    <app-isla-lite
      [(reporte)]="reporteSeleccionado"
      (reporteChange)="onClose()"
      (reporteVerChange)="reporteVer($event)"
      (botonSeguirChange)="siguendoAlActivo($event)"
      [botonSeguir]="siguendoA ? true : false"
    ></app-isla-lite>
  </div>
}
<div [id]="indiceMap()" class="grow1"></div>
