import { Injectable } from '@angular/core';
import {
  IQueryParam,
  IListado,
  IReporte,
  ICreateReporte,
  IUpdateReporte,
  ICoordenadas,
  ICategoriaActivo,
  TipoVehiculo,
} from 'modelos/src';
import { HelperService } from '../helper.service';
import { HttpClientService } from '../http.service';

export interface IFiltroLastReporte {
  idsActivos?: string[];
  idsTrackers?: string[];
  idsParadas?: string[];
  idsRecorridos?: string[];
  agruparPor?: 'tracker' | 'activo';
  desde?: string;
  hasta?: string;
  includeChildren?: boolean;
  childrenLevel?: number;
  categoriaActivo?: ICategoriaActivo;
  tipoVehiculo?: TipoVehiculo;
}

@Injectable({
  providedIn: 'root',
})
export class ReportesService {
  constructor(private http: HttpClientService) {}

  public getFiltered(queryParams?: IQueryParam): Promise<IListado<IReporte>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/reportes`, { params });
  }

  public getReportesSnappeados(
    queryParams?: IQueryParam,
  ): Promise<ICoordenadas[]> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/reportes/snapped`, { params });
  }

  public getReportesRecorridoActivos(
    queryParams?: IQueryParam,
  ): Promise<ICoordenadas[]> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/reportes/RecorridoActivos`, { params });
  }

  public getById(id: string): Promise<IReporte> {
    return this.http.get(`/reportes/${id}`);
  }

  public create(dato: ICreateReporte): Promise<IReporte> {
    return this.http.post(`/reportes`, dato);
  }

  public update(id: string, dato: IUpdateReporte): Promise<IReporte> {
    return this.http.put(`/reportes/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/reportes/${id}`);
  }
}
