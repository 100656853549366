import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IModeloDispositivo,
  IFilter,
  IListado,
  IQueryParam,
  IPopulate,
  ICliente,
  TipoDispositivo,
} from 'modelos/src';
import { Subscription } from 'rxjs';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../auxiliares/servicios';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../auxiliares/dialog/dialog.service';
import { ModelosDispositivosService } from '../../../../auxiliares/servicios/http/modelos-dispositivos.service';
import { CrearEditarModeloDispositivoComponent } from '../crear-editar-modelo-dispositivo/crear-editar-modelo-dispositivo.component';
import { DetallesCodigosDispositivosComponent } from '../../codigos-dispositivos/detalles-codigos-dispositivos/detalles-codigos-dispositivos.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-listado-modelos-dispositivos',
  templateUrl: './listado-modelos-dispositivos.component.html',
  styleUrl: './listado-modelos-dispositivos.component.scss',
})
export class ListadoModelosDispositivosComponent implements OnInit, OnDestroy {
  public tipo: TipoDispositivo;
  //// Tabla nueva
  public name = ListadoModelosDispositivosComponent.name;
  public modelosDispositivos: IModeloDispositivo[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<IModeloDispositivo>[];
  //Subs
  public modelosDispositivos$?: Subscription;
  public clientes$?: Subscription;
  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;
  //
  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeEditar(),
    tooltip: 'Crear Modelo Alarma',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['marca', 'modelo'],
  };
  // FILTROS
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  public filtros: IFiltroTabla[] = [];
  //Query
  public populate: IPopulate = {
    path: 'codigos',
  };
  public query: IQueryParam = {
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: ModelosDispositivosService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
  ) {}

  // Listados
  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      await Promise.all([this.listar(), this.listarClientes()]);
    } else {
      await this.listar();
    }
  }

  public async listar(): Promise<void> {
    const filter: IFilter<IModeloDispositivo> = this.query.filter
      ? JSON.parse(this.query.filter)
      : {};
    filter.tipo = this.tipo;
    this.query.filter = JSON.stringify(filter);

    //
    this.modelosDispositivos$?.unsubscribe();
    this.modelosDispositivos$ = this.listados
      .subscribe<IListado<IModeloDispositivo>>('modeloDispositivos', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.modelosDispositivos = data.datos;
        console.log(`listado de modeloDispositivos`, data);
      });
    await this.listados.getLastValue('modeloDispositivos', this.query);
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  // Acciones
  public crear() {
    const config: MatDialogConfig = {
      data: {
        tipo: this.tipo,
      },
      minWidth: '600px',
      disableClose: true,
    };
    this.dialog.open(CrearEditarModeloDispositivoComponent, config);
  }

  public async editar(modeloAlarma: IModeloDispositivo) {
    const config: MatDialogConfig = {
      data: {
        edit: modeloAlarma,
        tipo: this.tipo,
      },
      minWidth: '600px',
      disableClose: true,
    };
    this.dialog.open(CrearEditarModeloDispositivoComponent, config);
  }

  public async codigos(codigosAlarma: IModeloDispositivo) {
    const config: MatDialogConfig = {
      data: codigosAlarma?.codigos,
      minWidth: '600px',
      disableClose: true,
    };
    this.dialog.open(DetallesCodigosDispositivosComponent, config);
  }

  public async eliminar(modeloAlarma: IModeloDispositivo) {
    const res = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Desea eliminar el modelo ${modeloAlarma.marca} | ${modeloAlarma.modelo}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(modeloAlarma._id!);
      this.helper.notifSuccess('Eliminado correctamente');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    this.columnas = [
      // Marca
      {
        header: {
          label: 'Marca',
          sortable: true,
        },
        row: {
          field: 'marca',
        },
      },
      // Modelo
      {
        header: {
          label: 'Modelo',
          sortable: true,
        },
        row: {
          field: 'modelo',
        },
      },
      //
      {
        header: {
          label: 'Códigos',
          sortable: true,
        },
        row: {
          parse: (dato) => dato.codigos?.nombre,
        },
      },
    ];

    if (this.tipo === 'Comunicador') {
      this.columnas.push(
        // Formato Mensaje
        {
          header: {
            label: 'Formato Mensaje',
          },
          row: {
            field: 'formatoMensaje',
          },
        },
      );
    }

    this.columnas.push(
      // Acciones
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'material',
              color: 'accent',
              icon: 'edit',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
            {
              tipo: 'material',
              color: 'primary',
              icon: 'search',
              tooltip: 'Codigos',
              click: (dato) => this.codigos(dato),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'delete',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
          ],
        },
      },
    );
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.route.params.subscribe(async (params) => {
      this.tipo = params['tipo'] as TipoDispositivo;
      await this.listarTodo();
    });
    this.setColumnas();
  }

  async ngOnDestroy() {
    this.modelosDispositivos$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
