<div class="version-container">
  <button
    mat-fab
    extended
    color="accent"
    class="text-on-accent"
    (click)="logout()"
  >
    <mat-icon>logout</mat-icon>
    <span>Cerrar Sesión</span>
  </button>
  @if (env !== "prod") {
    <div class="row-center text-on-primary">
      Versión: {{ version }} - {{ env }}
    </div>
  }
</div>
