import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ICliente,
  ICronograma,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';

import { Router, ActivatedRoute } from '@angular/router';

import { CronogramasService } from '../../cronogramas/cronogramas.service';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';

@Component({
  selector: 'app-listado-cronogramas',
  templateUrl: './listado-cronogramas.component.html',
  styleUrl: './listado-cronogramas.component.scss',
  standalone: false,
})
export class ListadoCronogramasComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = ListadoCronogramasComponent.name;
  public cronogramas: ICronograma[] = [];

  public totalCount = 0;
  public columnas?: IColumnas<ICronograma>[];
  //Subs
  public cronogramas$?: Subscription;
  public clientes$?: Subscription;
  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;
  //
  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeEditar(),
    tooltip: 'Crear Cronograma',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  // FILTROS
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  public filtros: IFiltroTabla[] = [];
  //Query
  public filter: IFilter<ICliente> = {};
  public populate: IPopulate = {
    path: 'cliente',
    select: 'nombre',
  };
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: CronogramasService,
    private navigator: Router,
    private route: ActivatedRoute,
  ) {}
  // Listars
  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      Promise.all([this.listar(), this.listarClientes()]);
    } else {
      await this.listar();
    }
  }
  public async listar(): Promise<void> {
    this.cronogramas$?.unsubscribe();
    this.cronogramas$ = this.listados
      .subscribe<IListado<ICronograma>>('cronogramas', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.cronogramas = data.datos;
        console.log(`listado de cronogramas`, data);
      });
    await this.listados.getLastValue('cronogramas', this.query);
  }
  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(cronograma: ICronograma) {
    this.navigator.navigate(['editar', cronograma._id], {
      relativeTo: this.route,
    });
  }

  public async eliminar(cronograma: ICronograma) {
    const res = await this.dialogService.confirm(
      'Eliminar Cronograma',
      `¿Desea eliminar la cronograma ${cronograma.nombre}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(cronograma._id!);
      this.helper.notifSuccess('Cronograma eliminada');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla
  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Nombre',
          sortable: true,
        },
        row: {
          field: 'nombre',
        },
      },
    ];

    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        this.columnas.push({
          header: {
            label: 'Cliente',
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }
    this.columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        acciones: [
          {
            tipo: 'material',
            color: 'accent',
            icon: 'edit',
            tooltip: 'Editar',
            click: (dato) => this.editar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'warn',
            icon: 'delete',
            tooltip: 'Eliminar',
            click: (dato) => this.eliminar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
        ],
      },
    });
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();
    // await this.listarTodo();
    await this.listar();
  }

  ngOnDestroy() {
    this.cronogramas$?.unsubscribe();
  }
}
