import { ParamsService } from './../../../auxiliares/servicios/params.service';
import { Component, Input, OnInit, Optional, OnDestroy } from '@angular/core';
import {
  ICamara,
  ICamaraAlarma,
  IFilter,
  IListado,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../auxiliares/servicios';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { StreamCamaraComponent } from '../stream-camara/stream-camara.component';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../auxiliares/auxiliares.module';

@Component({
  selector: 'app-camaras-multiple',
  templateUrl: './camaras-multiple.component.html',
  styleUrl: './camaras-multiple.component.scss',
  standalone: true,
  imports: [StreamCamaraComponent, CommonModule, AuxiliaresModule],
})
export class CamarasMultipleComponent implements OnInit, OnDestroy {
  public loading: boolean = true;
  public camaras: ICamara[];
  private camaras$: Subscription;
  public camaraActual: ICamara;
  public canalActual: string;
  public maximizado: boolean = false;

  @Input() public data: string[] = [];
  @Input() public zonaInicial: ICamaraAlarma;

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<StreamCamaraComponent>,
    public helper: HelperService,
    private listados: ListadosService,
    public paramsService: ParamsService,
  ) {}

  //

  public async listarCamaras(): Promise<void> {
    const filter: IFilter<ICamara> = {
      _id: { $in: this.data },
    };

    const query: IQueryParam = {
      includeChildren: true,
      filter: JSON.stringify(filter),
    };
    this.camaras$?.unsubscribe();
    this.camaras$ = this.listados
      .subscribe<IListado<ICamara>>('camaras', query)
      .subscribe((data) => {
        this.camaras = data.datos;
        console.log(`listado de camaras`, data);
      });
    await this.listados.getLastValue('camaras', query);
  }

  public select(camara?: ICamara, canal?: string) {
    this.maximizado = !this.maximizado;
    if (this.maximizado) {
      this.camaraActual = camara;
      this.canalActual = canal;
    } else {
      this.camaraActual = null;
      this.canalActual = null;
    }
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  async ngOnInit() {
    if (this.dialogRef && this.paramsService.getParams()['dialog']) {
      this.camaras = this.paramsService.getParams()['camara'];
    } else {
      await this.listarCamaras();
    }

    if (this.zonaInicial) {
      const camara = this.camaras.find(
        (c) => c._id === this.zonaInicial.idCamara,
      );
      const canal = camara.canales.find(
        (can) => can.numero === this.zonaInicial.canal,
      );
      if (camara && canal) {
        this.camaraActual = camara;
        this.canalActual = canal.numero;
        this.maximizado = true;
      }
    }
    this.loading = false;
  }

  async ngOnDestroy() {
    this.camaras$?.unsubscribe();
  }
}
