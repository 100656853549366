import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../auxiliares/auxiliares.module';
import { MapaUnicoPuntoComponent } from '../../../auxiliares/mapa-unico-punto/mapa-unico-punto.component';
import { MapaFacilComponent } from '../../../auxiliares/mapa-facil/mapa-facil.component';
import { DateRangeSelectorComponent } from '../../../auxiliares/date-range-selector/date-range-selector.component';
import { ApikeysComponent } from './apikeys/apikeys/apikeys.component';
import { CrearEditarApikeyComponent } from './apikeys/crear-editar-apikey/crear-editar-apikey.component';
import { CrearEditarClienteComponent } from './clientes/crear-editar-cliente/crear-editar-cliente.component';
import { CartaClienteComponent } from './clientes/clientes/carta-cliente/carta-cliente.component';
import { HijosClienteComponent } from './clientes/clientes/hijos-cliente/hijos-cliente.component';
import { ListadoTrackersComponent } from './trackers/trackers/trackers.component';
import { CrearEditarTrackerComponent } from './trackers/crear-editar-tracker/crear-editar-tracker.component';
import { ListadoUsuariosComponent } from './usuarios/listado-usuarios/listado-usuarios.component';
import { CrearEditarUsuarioComponent } from './usuarios/crear-editar-usuario/crear-editar-usuario.component';
import { MapDrawComponent } from '../../../auxiliares/map-draw/map-draw.component';
import { UploadFileComponent } from '../../../auxiliares/upload-file/upload-file.component';
import { AdministracionRoutingModule } from './routing';
import { DetallesTrackerInfoComponent } from './trackers/detalles-tracker-info/detalles-tracker-info.component';
import { MatMenu } from '@angular/material/menu';
import { DetallesTrackersComponent } from './trackers/detalles-tracker/detalles-trackers.component';
import { DetallesTrackerEventosComponent } from './trackers/detalles-tracker-eventos/detalles-tracker-eventos.component';
import { DetallesTrackerEventosDetallesComponent } from './trackers/detalles-tracker-eventos/detalles-tracker-eventos-detalles/detalles-tracker-eventos-detalles.component';
import { ServiciosComponent } from './servicios-contratados/servicios/servicios.component';
import { CrearEditarServiciosComponent } from './servicios-contratados/crear-editar-servicios/crear-editar-servicios.component';
import { ListadoConfigsEventosComponent } from '../../standalone/configs-eventos/listado-configs-eventos/listado-configs-eventos.component';
import { ClienteNotasComponent } from './clientes/clientes/cliente-notas/cliente-notas.component';
import { ListadoNotasComponent } from '../../standalone/notas/listado-notas/listado-notas.component';
import { DetallesClienteComponent } from './clientes/clientes/detalles-cliente/detalles-cliente.component';
import { ListadoEventosHistoricoComponent } from '../../standalone/eventos-historico/listado-eventos-historico/listado-eventos-historico.component';
import { DetallesClienteInfoComponent } from './clientes/clientes/detalles-cliente-info/detalles-cliente-info.component';
import { ListadoClientesGraficoComponent } from './clientes/clientes/clientes-listado-visual/clientes.component';
import { ListadoClientesTablaComponent } from './clientes/clientes/clientes-listado-tabla/clientes.component';
import { ListadoClientesComponent } from './clientes/clientes/clientes-listado/clientes-listado.component';
import { TecnicosComponent } from './tecnicos/tecnicos/tecnicos.component';
import { CrearEditarTecnicoComponent } from './tecnicos/crear-editar-tecnico/crear-editar-tecnico.component';
import { EventosTecnicosComponent } from './eventos-tecnicos/eventos-tecnicos/eventos-tecnicos.component';
import { CrearEditarEventosTecnicosComponent } from './eventos-tecnicos/crear-editar-eventos-tecnicos/crear-editar-eventos-tecnicos.component';
import { AsignarComponent } from './eventos-tecnicos/asignar/asignar.component';
import { VistaTecnicosComponent } from './eventos-tecnicos/vista-tecnicos/vista-tecnicos.component';
import { MenuTecnicoComponent } from './eventos-tecnicos/menu-tecnico/menu-tecnico.component';
import { TratamientoTecnicoComponent } from './eventos-tecnicos/tratar-evento/tratamiento-tecnico/tratamiento-tecnico.component';
import { AgregarNotaComponent } from './eventos-tecnicos/tratar-evento/agregar-nota/agregar-nota.component';
import { EventosTecnicosFinalizadosComponent } from './eventos-tecnicos/eventos-tecnicos-finalizados/eventos-tecnicos-finalizados.component';
import { LogsDespachosComponent } from './logs-despachos/logs-despachos-listado/logs-despachos.component';

@NgModule({
  declarations: [
    // Apikeys
    ApikeysComponent,
    CrearEditarApikeyComponent,
    // Clientes
    ListadoClientesGraficoComponent,
    ListadoClientesTablaComponent,
    ListadoClientesComponent,
    CrearEditarClienteComponent,
    CartaClienteComponent,
    HijosClienteComponent,
    ClienteNotasComponent,
    DetallesClienteComponent,
    DetallesClienteInfoComponent,
    // Trackers
    ListadoTrackersComponent,
    CrearEditarTrackerComponent,
    DetallesTrackerInfoComponent,
    DetallesTrackersComponent,
    DetallesTrackerEventosComponent,
    DetallesTrackerEventosDetallesComponent,
    // Usuarios
    ListadoUsuariosComponent,
    CrearEditarUsuarioComponent,
    TecnicosComponent,
    CrearEditarTecnicoComponent,
    // Servicios Contratados
    ServiciosComponent,
    CrearEditarServiciosComponent,
    // Evnetos Técnicos
    EventosTecnicosComponent,
    EventosTecnicosFinalizadosComponent,
    CrearEditarEventosTecnicosComponent,
    VistaTecnicosComponent,
    MenuTecnicoComponent,
    TratamientoTecnicoComponent,
    AgregarNotaComponent,
    //Logs Despachos
    LogsDespachosComponent,
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    MapaUnicoPuntoComponent,
    MapaFacilComponent,
    DateRangeSelectorComponent,
    MapDrawComponent,
    UploadFileComponent,
    AdministracionRoutingModule,
    MatMenu,
    ListadoConfigsEventosComponent,
    ListadoNotasComponent,
    ListadoEventosHistoricoComponent,
    AsignarComponent,
  ],
})
export class AdministracionModule {}
