<div>
  <span>Usuarios Permitidos</span>
  <ng-select
    appendTo="body"
    [items]="usuarios"
    bindLabel="usuario"
    placeholder="Usuarios"
    [(ngModel)]="usuariosElegidos"
    [multiple]="true"
  >
  </ng-select>
</div>
