<div class="isla-contenedor magico-100">
  <div (click)="close()" class="isla-close">
    <mat-icon style="transform: scale(0.7, 0.7)" color="warn">close</mat-icon>
  </div>
  @if (reporte?.activo) {
    <!-- Loading -->
    @if (this.loading.getLoading()) {
      <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    }
    <!-- Identificacion -->
    <span
      class="isla-titulo"
      style="cursor: pointer"
      [routerLink]="['../vehiculos/detalles', reporte?.activo?._id]"
    >
      <span>🚐</span>
      {{ reporte?.activo?.identificacion }}
    </span>
    <!-- Cliente -->
    @if (cliente?.tipoCliente != "Final") {
      <div class="isla-dato">
        <div>Cliente:</div>
        <div>
          {{ reporte?.cliente.nombre }}
        </div>
      </div>
    }
    <!-- Grupo -->
    @if (reporte?.grupo?.nombre) {
      <div class="isla-dato">
        <div>Flota:</div>
        <div>{{ reporte?.grupo?.nombre }}</div>
      </div>
    } @else {
      <div class="isla-dato-no-info">
        <span class="no-info">Sin flota asignada</span>
      </div>
    }
    <!-- Fecha  -->
    @if (reporte?.fechaCreacion) {
      <div class="isla-dato">
        <div>Último Reporte:</div>
        <div>{{ reporte?.fechaCreacion | date: "dd/MM/YY, HH:mm:ss" }}</div>
      </div>
    } @else {
      <div class="isla-dato-no-info">
        <span class="no-info">Sin reportar</span>
      </div>
    }
    <!-- Bateria  -->
    @if (reporte?.bateria) {
      <div class="isla-dato">
        <div>Batería:</div>
        <div>
          {{ reporte?.bateria }}
          %
        </div>
      </div>
    } @else {
      <div class="isla-dato-no-info">
        <span class="no-info">Batería no reportada</span>
      </div>
    }

    <!-- Funcion -->
    @if (reporte?.activo.funcion) {
      <div class="isla-dato">
        <div>Función:</div>
        <div>
          {{ reporte?.activo?.funcion }}
        </div>
      </div>
    }

    <!-- Vehiculo -->
    @if (reporte?.activo?.categoria === "Vehículo") {
      <!-- Tipo -->
      @if (reporte?.activo.vehiculo?.tipo) {
        <div class="isla-dato">
          <div>Tipo:</div>
          <div>
            {{ reporte?.activo?.vehiculo?.tipo }}
          </div>
        </div>
      }
      <!-- Patente -->
      @if (reporte?.activo.vehiculo?.patente) {
        <div class="isla-dato">
          <div>Patente:</div>
          <div>
            {{ reporte?.activo.vehiculo?.patente }}
          </div>
        </div>
      }
      <!-- Marca -->
      @if (reporte?.activo.vehiculo?.marca) {
        <!-- Modelo -->
        @if (reporte?.activo.vehiculo?.modelo) {
          <div class="isla-dato">
            <div>Marca y modelo:</div>
            <div>
              {{ reporte?.activo?.vehiculo?.marca }} -
              {{ reporte?.activo?.vehiculo?.modelo }}
            </div>
          </div>
        } @else {
          <div class="isla-dato">
            <div>Marca:</div>
            <div>
              {{ reporte?.activo?.vehiculo?.marca }}
            </div>
          </div>
        }
      }

      <!-- Velocidad  -->
      @if (reporte?.velocidad) {
        <div class="isla-dato">
          <div>Velocidad:</div>
          <div>{{ reporte?.velocidad | number: "1.0-0" }} km/h</div>
        </div>
      } @else {
        <div class="isla-dato-no-info">
          <span class="no-info">Velocidad no reportada</span>
        </div>
      }
      <!-- Odómetro  -->
      @if (reporte?.odometro) {
        <div class="isla-dato">
          <div>Odómetro:</div>
          <div>
            {{ reporte?.odometro / 1000 | number: "1.0-0" }}
            km
          </div>
        </div>
      } @else {
        <div class="isla-dato-no-info">
          <span class="no-info">Odómetro no reportado</span>
        </div>
      }

      <!-- Datos del Chofer  -->
      @if (reporte?.chofer?._id) {
        <div
          class="isla-foto-container"
          style="cursor: pointer"
          [routerLink]="['../choferes/editar', reporte?.idChofer]"
        >
          <div class="isla-foto" [style.background-image]="getFoto()"></div>
        </div>
        <div class="isla-dato">
          <div>Chofer:</div>
          <div>{{ reporte?.chofer?.datosPersonales?.nombre }}</div>
        </div>
      }
    }
    <!-- Editar -->
    <div class="isla-editar">
      <!-- Editar Colectivo -->
      @if (reporte?.activo?.categoria === "Vehículo") {
        <button
          mat-raised-button
          color="primary"
          [routerLink]="['../vehiculos/editar', reporte?.activo?._id]"
        >
          Editar
        </button>
      } @else {
        <!-- Editar Activo -->
        <button
          mat-raised-button
          color="primary"
          (click)="editarActivo(reporte?.activo)"
        >
          Editar
        </button>
      }
    </div>
  }
</div>
