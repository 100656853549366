<!-- Título -->
<div class="row-center">
  <span class="title1">{{ alarma?.nombre }}</span>
</div>
<mat-tab-group
  [(selectedIndex)]="selectedTab"
  (selectedTabChange)="tabChanged($event)"
>
  <!-- Info -->
  <mat-tab label="Info">
    <ng-template matTabContent>
      <app-detalles-alarma-info></app-detalles-alarma-info>
    </ng-template>
  </mat-tab>

  <!-- Historial Eventos -->
  <mat-tab label="Historial Eventos">
    <ng-template matTabContent>
      <app-listado-eventos-historico
        [id]="alarma._id"
        tipo="Alarma"
      ></app-listado-eventos-historico>
    </ng-template>
  </mat-tab>
  @if (alarma?.idsCamaras) {
    <!-- Camaras -->
    <mat-tab label="Cámaras">
      <ng-template matTabContent>
        <br />
        <div
          style="
            height: 70vh;
            display: flex;
            justify-content: center;
            overflow-y: scroll;
            overflow-x: hidden;
          "
        >
          <app-camaras-multiple
            [data]="alarma.idsCamaras"
          ></app-camaras-multiple>
        </div>
      </ng-template>
    </mat-tab>
  }
  <!-- Codigos -->
  <mat-tab label="Códigos Eventos">
    <ng-template matTabContent>
      <app-listado-configs-eventos
        [dispositivo]="alarma"
        tipo="Alarma"
      ></app-listado-configs-eventos>
    </ng-template>
  </mat-tab>
  <!-- Control Horario -->
  <mat-tab label="Control Horario">
    <ng-template matTabContent>
      @if (params) {
        <app-listado-config-evento-usuario
          [params]="params"
        ></app-listado-config-evento-usuario>
      }
    </ng-template>
  </mat-tab>
  <!-- Notas -->
  <mat-tab label="Notas">
    <ng-template matTabContent>
      <app-listado-notas
        [idAsignado]="this.alarma._id"
        tipo="Nota"
        tipoDispositivo="Alarma"
      ></app-listado-notas>
    </ng-template>
  </mat-tab>
  <!-- Contacto -->
  <mat-tab label="Contactos">
    <ng-template matTabContent>
      <app-listado-notas
        [idAsignado]="this.alarma._id"
        tipo="Contacto"
        tipoDispositivo="Alarma"
      ></app-listado-notas>
    </ng-template>
  </mat-tab>
  <!-- Servicios Técnicos -->
  <mat-tab label="Servicios Técnicos">
    <ng-template matTabContent>
      <app-eventos-tecnicos [alarma]="alarma"></app-eventos-tecnicos>
    </ng-template>
  </mat-tab>
</mat-tab-group>
<!-- Botones -->
<div class="row-flex-end" style="gap: 10px">
  <app-button
    matType="mat-fab extended"
    color="warn"
    (click)="this.alarmas()"
    text="Volver"
    icon="arrow_back"
  ></app-button>
</div>
