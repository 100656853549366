import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ICreateDocumentacion,
  ICreateRecordatorio,
  IDocumentacion,
  IUpdateDocumentacion,
  TipoDocumentacion,
} from 'modelos/src';

import { MatDialog } from '@angular/material/dialog';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import { CommonModule } from '@angular/common';
import { DialogService } from '../../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { DocumentosService } from '../../../../auxiliares/servicios/http/documentos.service';
import { ParamsService } from '../../../../auxiliares/servicios/params.service';
import { RecordatoriosService } from '../../../entidades/colectivos/recordatorios/recordatorios.service';
import { UploadFileComponent } from '../../../../auxiliares/upload-file/upload-file.component';

@Component({
  imports: [CommonModule, AuxiliaresModule, UploadFileComponent],
  selector: 'app-crear-editar-documento',
  templateUrl: './crear-editar-documento.component.html',
  styleUrl: './crear-editar-documento.component.scss',
})
export class CrearEditarDocumentoComponent implements OnInit {
  public form?: FormGroup;
  public title?: string;
  public tipos: TipoDocumentacion[] = ['Seguro', 'Licencia'];
  public id?: string | null;
  public tipo?: string | null;
  public documento?: IDocumentacion;
  public loading: boolean = false;
  constructor(
    private service: DocumentosService,
    public helper: HelperService,
    private serviceR: RecordatoriosService,
    private dialogService: DialogService,
    private paramsService: ParamsService,
    public dialog: MatDialog,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      idChofer: new FormControl(this.documento?.idChofer),
      tipo: new FormControl(this.documento?.tipo, Validators.required),
      idActivo: new FormControl(this.documento?.idActivo),
      idCliente: new FormControl(this.documento?.idCliente),
      descripcion: new FormControl(this.documento?.descripcion),
      emision: new FormControl(this.documento?.emision),
      vencimiento: new FormControl(this.documento?.vencimiento),
      imagenes: new FormControl(this.documento?.imagenes),
    });
  }

  get documentoImagenes() {
    return this.form?.get('imagenes') as FormGroup;
  }

  //
  public async onSubmit() {
    const res = await this.dialogService.confirm(
      `Confirme la acción`,
      `¿Desea ${this.id ? 'editar' : 'crear '} el documento?`,
    );
    if (!res) return;
    this.loading = true;
    try {
      const data = this.getData();
      if (this.id) {
        // Update
        await this.service.update(this.id, data);
        this.helper.notifSuccess('Documento actualizado');
        this.dialog.closeAll();
      } else {
        // Create
        const doc = await this.service.create(data);
        const recordatorio: ICreateRecordatorio = {
          tipo: ['fecha'],
          fechaLimite: doc.vencimiento,
          idDocumentacion: doc._id,
        };
        await this.serviceR.create(recordatorio);
        this.helper.notifSuccess('Documento creado');
        this.dialog.closeAll();
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public getData(): ICreateDocumentacion | IUpdateDocumentacion {
    const data: ICreateDocumentacion | IUpdateDocumentacion = this.form?.value;
    return data;
  }

  public loadingFile(bool: boolean) {
    this.loading = bool;
  }
  public onUrlChange(url: string) {
    const imagenes = this.form?.value.imagenes;
    if (imagenes) {
      imagenes.push(url);
      this.form?.patchValue({ imagenes: imagenes });
    } else this.form?.patchValue({ imagenes: [url] });
  }

  public eliminarImagen(index: number) {
    const imagenes = this.form?.value.imagenes;
    imagenes.splice(index, 1);
    this.form?.patchValue({ imagenes: imagenes });
  }

  async ngOnInit() {
    const params = this.paramsService.getParams();
    console.log(params);
    this.documento = params['documento'];
    this.id = this.documento?._id;
    this.tipo = params['tipoEntidad'];
    this.createForm();
    if (!this.id) {
      //CREAR
      this.form.patchValue({
        idCliente: params['idCliente'],
      });
      this.title = 'Crear Documento';
      if (this.tipo === 'usuario' && this.form)
        this.form.patchValue({
          idChofer: params['idEntidad'],
        });
      if (this.tipo === 'vehiculo' && this.form) {
        this.tipos = ['Seguro'];
        this.form.patchValue({
          idActivo: params['idEntidad'],
        });
      }
    } else {
      //EDITAR
      this.title = 'Editar Documento';
    }
  }
}
