/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Feature, Map, View } from 'ol';
import {
  IDispositivoAlarma,
  IFilter,
  IGeoJSONPoint,
  IListado,
  IPopulate,
  IQueryParam,
  IReporte,
  ITrackeo,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../../auxiliares/servicios/listados.service';
import { Geometry, LineString, Point } from 'ol/geom';
import { OpenLayersService } from '../../../../auxiliares/servicios/openLayers.service';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import VectorSource from 'ol/source/Vector';
import { MapaService } from '../mapa.service';

@Component({
  selector: 'app-mapa-ol',
  templateUrl: './mapa-ol.component.html',
  styleUrl: './mapa-ol.component.scss',
  standalone: false,
})
export class MapaOlComponent implements OnInit, OnDestroy {
  public ctrl = false;
  //Vehiculos
  public reportesColectivos?: IReporte[] = [];
  public reportesColectivos$?: Subscription;

  //Activos
  public reportesActivos?: IReporte[] = [];
  public reportesActivos$?: Subscription;

  //Trackers
  public reportesTrackers: IReporte[] = [];
  public reportesTrackers$?: Subscription;

  //Vehiculos
  public reportesVehiculos?: IReporte[] = [];
  public reportesVehiculos$?: Subscription;
  // private activosMostrar: boolean = true;

  //Alarmas
  public alarmas?: IDispositivoAlarma[] = [];
  public alarmas$?: Subscription;

  public alarmaSeleccionada?: IDispositivoAlarma;
  public reporteSeleccionado?: IReporte;

  public trackeo?: ITrackeo;
  public trackeo$?: Subscription;

  ///Ocultos
  private alarmasOcultas = false;
  private colectivosOcultos = false;
  private vehiculosOcultos = false;
  private trackersOcultos = false;
  private activosOcultos = false;

  /// OPENLAYERS
  public map?: Map;
  // Layer de Markers
  public markerVectorOptions = OpenLayersService.vehiculosVectorLayer();
  public orientationVectorOptions =
    OpenLayersService.vehiculosCompassVectorLayer();
  public alarmasMarkers = OpenLayersService.alarmaCasaVectorLayer();
  public vehiculosMarkers = OpenLayersService.vehiculosNormalesVectorLayer();
  public orientationVehiculos = OpenLayersService.vehiculosCompassVectorLayer();
  public trackersMarkers = OpenLayersService.trackersVectorLayer();
  public orientationTrackers = OpenLayersService.vehiculosCompassVectorLayer();
  // Punto Activo
  public activoSimpleLayer = OpenLayersService.activoVectorLayer();
  /// Centro
  private puntos: [number, number][] = [];
  orientationVehiculosOptions: any;

  constructor(
    private listados: ListadosService,
    private helper: HelperService,
    private mapaService: MapaService,
  ) {}

  private suscribeSiguiendo() {
    this.mapaService.siguiendoChange.subscribe((siguiendo) => {
      if (!siguiendo) return;
      console.log(`Siguiendo: ${siguiendo}`);
      const activo = this.reportesActivos?.find(
        (r) => r.idActivo === siguiendo,
      );
      const colectivo = this.reportesColectivos?.find(
        (r) => r.idActivo === siguiendo,
      );
      const vehiculo = this.reportesVehiculos?.find(
        (r) => r.idActivo === siguiendo,
      );
      const geojson =
        activo?.geojson || colectivo?.geojson || vehiculo?.geojson;
      if (!geojson?.coordinates) return;
      // Centra el mapa en el activo
      this.centerMap(geojson, 17);
    });
  }

  // Listados

  private async listarAlarmas(): Promise<void> {
    const populate: IPopulate[] = [
      {
        path: 'domicilio',
      },
      {
        path: 'cliente',
        select: 'nombre',
      },
    ];
    const query: IQueryParam = {
      populate: JSON.stringify(populate),
      includeChildren: true,
    };

    this.alarmas$?.unsubscribe();
    this.alarmas$ = this.listados
      .subscribe<IListado<IDispositivoAlarma>>('dispositivosAlarmas', query)
      .subscribe(async (data) => {
        this.alarmas = data.datos;
        this.addMarkersAlarmas();
        console.log(
          `listado de dispositivosAlarmas ${new Date().toISOString()}`,
          data,
        );

        if (this.alarmaSeleccionada) {
          const seleccionado = this.alarmas.find(
            (r) => r._id === this.alarmaSeleccionada._id,
          );
          if (seleccionado) {
            this.reporteSeleccionado = seleccionado;
          }
        }
      });
    await this.listados.getLastValue('dispositivosAlarmas', query);
  }

  private async listarReportesColectivos(): Promise<void> {
    const filter: IFilter<IReporte> = {
      tipo: 'Colectivo',
    };
    const populate: IPopulate = [
      {
        path: 'activo',
        select: 'identificacion categoria vehiculo',
        populate: {
          path: 'vehiculo.recorridos',
          select: 'nombre',
        },
      },
      {
        path: 'grupo',
        select: 'nombre color',
      },
      {
        path: 'tracker',
        select: 'nombre tipo identificacion',
      },
      {
        path: 'cliente',
        select: 'nombre tipoCliente',
      },
      {
        path: 'recorrido',
        select: 'nombre',
      },
    ];
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      includeChildren: true,
    };

    this.reportesColectivos$?.unsubscribe();
    this.reportesColectivos$ = this.listados
      .subscribe<IListado<IReporte>>('ultimoReportes', query)
      .subscribe(async (data) => {
        this.reportesColectivos = data.datos;
        this.addMarkersColectivos();
        console.log(
          `listado de ultimoReportes vehiculo en mapa general ${new Date().toISOString()}`,
          data,
        );

        if (this.reporteSeleccionado) {
          const seleccionado = this.reportesColectivos.find(
            (r) => r.idActivo === this.reporteSeleccionado.idActivo,
          );
          if (seleccionado) {
            this.reporteSeleccionado = seleccionado;
          }
        }

        if (this.mapaService.siguiendo) {
          console.log('ie ie ie ie, esta siguendo ie ie ');
          const activo = this.reportesColectivos.find(
            (r) => r.idActivo === this.mapaService.siguiendo,
          );
          console.log(activo);
          if (activo) {
            console.log('centrando en el activosqui');
            this.centerMap(activo.geojson);
          }
        }
      });
    await this.listados.getLastValue('ultimoReportes', query);
  }

  private async listarReportesTrackers(): Promise<void> {
    try {
      const filter: IFilter<IReporte> = {
        tipo: 'Tracker',
      };
      const populate: IPopulate = [
        {
          path: 'tracker',
          select: 'nombre tipo identificacion',
        },
        {
          path: 'cliente',
          select: 'nombre tipoCliente',
        },
      ];
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        populate: JSON.stringify(populate),
        includeChildren: true,
      };

      this.reportesTrackers$?.unsubscribe();
      this.reportesTrackers$ = this.listados
        .subscribe<IListado<IReporte>>('ultimoReportes', query)
        .subscribe(async (data) => {
          this.reportesTrackers = data.datos;
          this.addMarkersTrackers();
          console.log(
            `listado de ultimoReportes trackers en mapa general ${new Date().toISOString()}`,
            data,
          );
          if (this.reporteSeleccionado) {
            const seleccionado = this.reportesColectivos.find(
              (r) => r.idActivo === this.reporteSeleccionado.idActivo,
            );
            if (seleccionado) {
              this.reporteSeleccionado = seleccionado;
            }
          }

          if (this.mapaService.siguiendo) {
            const activo = this.reportesActivos.find(
              (r) => r.idActivo === this.mapaService.siguiendo,
            );
            if (activo) {
              this.centerMap(activo.geojson);
            }
          }
        });
      await this.listados.getLastValue('ultimoReportes', query);
    } catch (error: any) {
      this.helper.notifError('Error al listar activos');
    }
  }

  private async listarReportesActivos(): Promise<void> {
    try {
      const filter: IFilter<IReporte> = {
        tipo: 'Activo',
      };
      const populate: IPopulate = [
        {
          path: 'activo',
          select: 'nombre color categoria identificacion',
        },
        {
          path: 'tracker',
          select: 'nombre tipo identificacion',
        },
        {
          path: 'cliente',
          select: 'nombre tipoCliente',
        },
      ];
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        populate: JSON.stringify(populate),
        includeChildren: true,
      };

      this.reportesActivos$?.unsubscribe();
      this.reportesActivos$ = this.listados
        .subscribe<IListado<IReporte>>('ultimoReportes', query)
        .subscribe(async (data) => {
          this.reportesActivos = data.datos;
          this.addMarkersActivos();
          console.log(
            `listado de ultimoReportes activos en mapa general ${new Date().toISOString()}`,
            data,
          );
          if (this.reporteSeleccionado) {
            const seleccionado = this.reportesColectivos.find(
              (r) => r.idActivo === this.reporteSeleccionado.idActivo,
            );
            if (seleccionado) {
              this.reporteSeleccionado = seleccionado;
            }
          }

          if (this.mapaService.siguiendo) {
            console.log('ie ie ie ie, esta siguendo ie ie ');
            const activo = this.reportesColectivos.find(
              (r) => r.idActivo === this.mapaService.siguiendo,
            );
            console.log(activo);
            if (activo) {
              console.log('centrando en el activosqui');
              this.centerMap(activo.geojson);
            }
          }
        });
      await this.listados.getLastValue('ultimoReportes', query);
    } catch (error: any) {
      this.helper.notifError('Error al listar activos');
    }
  }

  private async listarReportesVehiculos(): Promise<void> {
    try {
      const filter: IFilter<IReporte> = {
        tipo: 'Vehiculo',
      };
      const populate: IPopulate = [
        {
          path: 'activo',
          select: 'nombre color categoria vehiculo identificacion',
        },
        {
          path: 'tracker',
          select: 'nombre tipo identificacion',
        },
        {
          path: 'cliente',
          select: 'nombre tipoCliente',
        },
      ];
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        populate: JSON.stringify(populate),
        includeChildren: true,
      };

      this.reportesVehiculos$?.unsubscribe();
      this.reportesVehiculos$ = this.listados
        .subscribe<IListado<IReporte>>('ultimoReportes', query)
        .subscribe(async (data) => {
          this.reportesVehiculos = data.datos;
          this.addMarkersVehiculos();
          console.log(
            `listado de ultimoReportes vehiculos en mapa general ${new Date().toISOString()}`,
            data,
          );
          if (this.reporteSeleccionado) {
            const seleccionado = this.reportesVehiculos.find(
              (r) => r.idActivo === this.reporteSeleccionado.idActivo,
            );
            if (seleccionado) {
              this.reporteSeleccionado = seleccionado;
            }
          }

          if (this.mapaService.siguiendo) {
            console.log(this.reportesVehiculos);
            const activo = this.reportesVehiculos.find(
              (r) => r.idActivo === this.mapaService.siguiendo,
            );

            if (activo) {
              this.centerMap(activo.geojson);
            }
          }
        });
      await this.listados.getLastValue('ultimoReportes', query);
    } catch (error: any) {
      this.helper.notifError('Error al listar vehiculos');
    }
  }

  private async listarUltimoTrackeo(idActivo: string) {
    try {
      this.trackeo$?.unsubscribe();
      this.trackeo$ = this.listados
        .subscribe<ITrackeo>('ultimoTrackeosPorIdActivo', idActivo)
        .subscribe(async (data) => {
          this.trackeo = data;
          console.log(
            `listado de ultimoTrackeos en mapa general ${new Date().toISOString()}`,
            data,
          );
        });
      await this.listados.getLastValue('ultimoTrackeosPorIdActivo', idActivo);
    } catch (error: any) {
      this.helper.notifError(
        `Error al listar ultimo trackeo del activo ${idActivo}`,
      );
    }
  }

  // Mapa

  private addMarkersAlarmas() {
    const source = this.alarmasMarkers.getSource();
    if (!source) return;
    source.clear();
    if (this.alarmas && !this.alarmasOcultas) {
      for (const alarma of this.alarmas) {
        if (!alarma.domicilio?.geojson) continue;
        this.puntos.push(
          (alarma.domicilio.geojson as IGeoJSONPoint).coordinates,
        );
        // marker
        const coord = OpenLayersService.lonLatToCoordinate(
          (alarma.domicilio.geojson as IGeoJSONPoint).coordinates[0],
          (alarma.domicilio.geojson as IGeoJSONPoint).coordinates[1],
        );
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(coord),
          data: alarma,
          tipo: 'alarma',
        });
        source.addFeature(feature);
      }
    }
  }

  private addMarkersColectivos() {
    const source = this.markerVectorOptions.getSource();
    if (!source) return;
    source.clear();
    const orientationSource = this.orientationVectorOptions.getSource();
    if (!orientationSource) return;
    orientationSource.clear();
    if (this.reportesColectivos && !this.colectivosOcultos) {
      for (const reporte of this.reportesColectivos) {
        if (
          this.reporteSeleccionado &&
          this.reporteSeleccionado.activo?.vehiculo &&
          this.reporteSeleccionado.activo?._id === reporte.activo?._id
        ) {
          this.reporteSeleccionado.fechaCreacion = reporte.fechaCreacion;
        }
        if (!reporte.geojson?.coordinates) return;
        // Solo mostrar los vehiculos que estén en los recorridos seleccionados
        this.puntos.push(reporte.geojson.coordinates);
        // marker
        const coord = OpenLayersService.lonLatToCoordinate(
          reporte.geojson.coordinates[0],
          reporte.geojson.coordinates[1],
        );
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(coord),
          data: reporte,
          tipo: 'colectivo',
        });
        source.addFeature(feature);
        // orientation
        if (reporte.orientacion) {
          const radian = (reporte.orientacion * Math.PI) / 180;
          const featureOrientation: Feature<Geometry> = new Feature({
            geometry: new Point(coord),
            data: reporte,
            tipo: 'colectivo',
          });
          const style = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: 'assets/map/brujula.png',
              height: 40,
              width: 40,
              rotation: radian,
            }),
          });
          featureOrientation.setStyle(style);
          orientationSource.addFeature(featureOrientation);
        } else {
          const featureOrientation: Feature<Geometry> = new Feature({
            geometry: new Point(coord),
            data: reporte,
            tipo: 'colectivo',
          });
          const style = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: 'assets/map/circle.png',
              height: 40,
              width: 40,
            }),
          });
          featureOrientation.setStyle(style);
          orientationSource.addFeature(featureOrientation);
        }
      }
    }
  }

  private addMarkersVehiculos() {
    const source = this.vehiculosMarkers.getSource();
    if (!source) return;
    source.clear();
    const orientationSource = this.orientationVehiculos.getSource();
    if (!orientationSource) return;
    orientationSource.clear();
    if (this.reportesVehiculos && !this.vehiculosOcultos) {
      for (const reporte of this.reportesVehiculos) {
        // if (
        //   this.reporteSeleccionado &&
        //   this.reporteSeleccionado.activo?.vehiculo &&
        //   this.reporteSeleccionado.activo?._id === reporte.activo?._id
        // ) {
        //   this.reporteSeleccionado.fechaCreacion = reporte.fechaCreacion;
        // }
        if (!reporte.geojson?.coordinates) return;
        // Solo mostrar los vehiculos que estén en los recorridos seleccionados
        this.puntos.push(reporte.geojson.coordinates);
        // marker
        const coord = OpenLayersService.lonLatToCoordinate(
          reporte.geojson.coordinates[0],
          reporte.geojson.coordinates[1],
        );
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(coord),
          data: reporte,
          tipo: 'vehiculo',
        });
        source.addFeature(feature);
        // orientation
        if (reporte.orientacion) {
          const radian = (reporte.orientacion * Math.PI) / 180;
          const featureOrientation: Feature<Geometry> = new Feature({
            geometry: new Point(coord),
            data: reporte,
            tipo: 'vehiculo',
          });
          const style = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: 'assets/map/brujula.png',
              height: 40,
              width: 40,
              rotation: radian,
            }),
          });
          featureOrientation.setStyle(style);
          orientationSource.addFeature(featureOrientation);
        } else {
          const featureOrientation: Feature<Geometry> = new Feature({
            geometry: new Point(coord),
            data: reporte,
            tipo: 'vehiculo',
          });
          const style = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: 'assets/map/circle.png',
              height: 40,
              width: 40,
            }),
          });
          featureOrientation.setStyle(style);
          orientationSource.addFeature(featureOrientation);
        }
      }
    }
  }

  private addMarkersTrackers() {
    const source = this.trackersMarkers.getSource();
    if (!source) return;
    source.clear();
    const orientationSource = this.orientationTrackers.getSource();
    if (!orientationSource) return;
    orientationSource.clear();
    if (this.reportesTrackers && !this.trackersOcultos) {
      for (const reporte of this.reportesTrackers) {
        // if (
        //   this.reporteSeleccionado &&
        //   this.reporteSeleccionado.activo?.vehiculo &&
        //   this.reporteSeleccionado.activo?._id === reporte.activo?._id
        // ) {
        //   this.reporteSeleccionado.fechaCreacion = reporte.fechaCreacion;
        // }
        if (!reporte.geojson?.coordinates) return;
        // Solo mostrar los vehiculos que estén en los recorridos seleccionados
        this.puntos.push(reporte.geojson.coordinates);
        // marker
        const coord = OpenLayersService.lonLatToCoordinate(
          reporte.geojson.coordinates[0],
          reporte.geojson.coordinates[1],
        );
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(coord),
          data: reporte,
          tipo: 'tracker',
        });
        source.addFeature(feature);
        // orientation
        if (reporte.orientacion) {
          const radian = (reporte.orientacion * Math.PI) / 180;
          const featureOrientation: Feature<Geometry> = new Feature({
            geometry: new Point(coord),
            data: reporte,
            tipo: 'tracker',
          });
          const style = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: 'assets/map/brujula.png',
              height: 40,
              width: 40,
              rotation: radian,
            }),
          });
          featureOrientation.setStyle(style);
          orientationSource.addFeature(featureOrientation);
        } else {
          const featureOrientation: Feature<Geometry> = new Feature({
            geometry: new Point(coord),
            data: reporte,
            tipo: 'tracker',
          });
          const style = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: 'assets/map/circle.png',
              height: 40,
              width: 40,
            }),
          });
          featureOrientation.setStyle(style);
          orientationSource.addFeature(featureOrientation);
        }
      }
    }
  }

  private addMarkersActivos() {
    const source = this.activoSimpleLayer.getSource();
    if (!source) return;
    source.clear();
    // if (!this.activosMostrar) return;
    if (this.reportesActivos && !this.activosOcultos) {
      for (const reporte of this.reportesActivos) {
        if (
          this.reporteSeleccionado &&
          this.reporteSeleccionado.activo &&
          this.reporteSeleccionado.idActivo === reporte.idActivo
        ) {
          this.reporteSeleccionado = reporte;
        }
        if (!reporte.geojson?.coordinates) return;
        this.puntos.push(reporte.geojson.coordinates);
        // punto
        const coord = OpenLayersService.lonLatToCoordinate(
          reporte.geojson.coordinates[0],
          reporte.geojson.coordinates[1],
        );
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(coord),
          data: reporte,
          tipo: 'activo',
        });
        source.addFeature(feature);
      }
    }
  }

  private async initMap() {
    const position = await OpenLayersService.getCurrentPosition();
    this.map = new Map({
      interactions: OpenLayersService.interactions(),
      target: 'map',
      controls: [],
      layers: [
        OpenLayersService.mapTile(),
        this.orientationVectorOptions,
        this.orientationVehiculos,
        this.orientationTrackers,
        this.markerVectorOptions,
        this.vehiculosMarkers,
        this.trackersMarkers,
        this.alarmasMarkers,
        this.activoSimpleLayer,
      ],
      view: new View({
        center: position,
        zoom: 16,
      }),
    });
    this.map.on('pointerdrag', () => {
      this.mapaService.siguiendo = null;
    });

    this.map.on('error', (e) => {
      console.error('Error en el mapa', e);
    });

    await this.handleClick();
    const sourceres: VectorSource<Feature<Geometry>> = new VectorSource();
    if (this.reportesColectivos?.length) {
      const sourceMarker = this.markerVectorOptions.getSource();
      sourceMarker.forEachFeature((f) => sourceres.addFeature(f));
    }
    if (this.reportesVehiculos?.length) {
      const sourceMarker = this.vehiculosMarkers.getSource();
      sourceMarker.forEachFeature((f) => sourceres.addFeature(f));
    }
    if (this.reportesActivos?.length) {
      const sourcePoint = this.activoSimpleLayer.getSource();
      sourcePoint.forEachFeature((f) => sourceres.addFeature(f));
    }
    if (this.alarmas?.length) {
      const sourceMarker = this.alarmasMarkers.getSource();
      sourceMarker.forEachFeature((f) => sourceres.addFeature(f));
    }
    if (sourceres) {
      this.setBounds(sourceres);
    }
  }

  private centerMap(geojson: IGeoJSONPoint, zoom?: number) {
    if (!geojson?.coordinates) return;
    const coord = OpenLayersService.lonLatToCoordinate(
      geojson.coordinates[0],
      geojson.coordinates[1],
    );
    this.map?.getView().setCenter(coord);
    if (zoom) {
      this.map?.getView().setZoom(zoom);
    }
  }

  private async handleClick() {
    if (!this.map) return;
    this.reporteSeleccionado = undefined;
    this.alarmaSeleccionada = undefined;
    this.trackeo = undefined;
    this.trackeo$?.unsubscribe();
    this.map.on('singleclick', async (evt) => {
      const feature = this.map?.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          const geometry = feature.getGeometry();
          if (geometry instanceof Point) {
            if (feature.get('tipo') === 'colectivo') {
              /// ES UN COLECTIVO
              return feature;
            }
            if (feature.get('tipo') === 'activo') {
              /// ES UN ACTIVO
              return feature;
            }
            if (feature.get('tipo') === 'alarma') {
              /// ES UNA ALARMA
              return feature;
            }
            if (feature.get('tipo') === 'vehiculo') {
              /// ES UNA Vehiculo
              return feature;
            }
            if (feature.get('tipo') === 'tracker') {
              /// ES UNA Tracker
              return feature;
            }
            return null;
          } else if (geometry instanceof LineString) {
            return null;
          }
          return null;
        },
      );

      if (!feature) return;

      const tipo = feature.get('tipo');
      const data = feature.get('data');
      if (tipo === 'colectivo') {
        this.reporteSeleccionado = data;
        this.listarUltimoTrackeo(this.reporteSeleccionado.idActivo);
        if (!this.reporteSeleccionado?.geojson?.coordinates) return;
      }
      if (tipo === 'vehiculo') {
        this.reporteSeleccionado = data;
        if (!this.reporteSeleccionado?.geojson?.coordinates) return;
      }
      if (tipo === 'tracker') {
        this.reporteSeleccionado = data;
        if (!this.reporteSeleccionado?.geojson?.coordinates) return;
      }
      if (tipo === 'activo') {
        this.reporteSeleccionado = data;
        if (!this.reporteSeleccionado?.geojson?.coordinates) return;
      }
      if (tipo === 'alarma') {
        this.reporteSeleccionado = undefined;
        this.alarmaSeleccionada = data;
        if (!this.alarmaSeleccionada?.domicilio?.geojson?.coordinates) return;
      } else {
        this.alarmaSeleccionada = undefined;
      }
    });
  }

  private setBounds(source: VectorSource<Feature<Geometry>> | null) {
    if (!source) return;
    const extent = source.getExtent();
    if (!extent) return;
    this.map?.getView().fit(extent, { padding: [200, 200, 200, 200] });
    if (this.map?.getView().getZoom() > 17) {
      this.map?.getView().setZoom(17);
    }
  }

  public onClose() {
    this.reporteSeleccionado = undefined;
    this.alarmaSeleccionada = undefined;
    this.trackeo = undefined;
  }

  public ocultar(e: string) {
    if (e === 'Colectivos') this.colectivosOcultos = !this.colectivosOcultos;
    if (e === 'Alarmas') this.alarmasOcultas = !this.alarmasOcultas;
    if (e === 'Activos') this.activosOcultos = !this.activosOcultos;
    if (e === 'Vehiculos') this.vehiculosOcultos = !this.vehiculosOcultos;
    if (e === 'Trackers') this.trackersOcultos = !this.trackersOcultos;
    this.addMarkersTrackers();
    this.addMarkersVehiculos();
    this.addMarkersColectivos();
    this.addMarkersActivos();
    this.addMarkersAlarmas();
  }
  ///
  async ngOnInit() {
    await Promise.all([
      await this.listarReportesTrackers(),
      await this.listarReportesColectivos(),
      await this.listarReportesActivos(),
      await this.listarReportesVehiculos(),
      await this.listarAlarmas(),
    ]);
    await this.initMap();
    this.suscribeSiguiendo();
  }

  ngOnDestroy() {
    this.reportesTrackers$?.unsubscribe();
    this.reportesColectivos$?.unsubscribe();
    this.reportesActivos$?.unsubscribe();
    this.reportesVehiculos$?.unsubscribe();
    this.alarmas$?.unsubscribe();
    this.trackeo$?.unsubscribe();
    this.mapaService.siguiendo = '';
  }
}
