import { Component, OnDestroy, OnInit } from '@angular/core';
import { ListadosService } from '../../../../../auxiliares/servicios';
import { EventosTecnicosComponent } from '../eventos-tecnicos/eventos-tecnicos.component';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import {
  IEventoTecnico,
  IFilter,
  IPopulate,
  IQueryParam,
  IListado,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import {
  IRegExpSearch,
  IFiltroTabla,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { IColumnas } from '../../../../../auxiliares/tabla/tabla.component';
import { LoginService } from '../../../../login/login.service';
import { TratamientoTecnicoComponent } from '../tratar-evento/tratamiento-tecnico/tratamiento-tecnico.component';

@Component({
  selector: 'app-vista-tecnicos',
  standalone: false,
  templateUrl: './vista-tecnicos.component.html',
  styleUrl: './vista-tecnicos.component.scss',
})
export class VistaTecnicosComponent implements OnInit, OnDestroy {
  private usuarioPropio = LoginService.getUsuario();
  //// Tabla nueva
  public name = EventosTecnicosComponent.name;
  public datos: IEventoTecnico[] = [];
  public datos$?: Subscription;
  public totalCount = 0;
  public columnas?: IColumnas<IEventoTecnico>[];

  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  public filtros: IFiltroTabla[] = [];

  public filter: IFilter<IEventoTecnico> = {
    idTecnicoAsignado: this.usuarioPropio._id,
    estado: {
      $in: ['Asignado', 'En Atención', 'Pendiente de Aprobación'],
    },
  };
  public populate: IPopulate[] = [
    {
      path: 'cliente',
      select: 'nombre',
    },
    {
      path: 'activo',
      select: 'identificacion',
    },
    {
      path: 'alarma',
      select: 'nombre idDomicilio',
      populate: {
        path: 'domicilio',
        select: 'geojson',
      },
    },
    {
      path: 'tracker',
      select: 'identificacion',
    },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: '-fechaCreacion',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
  };

  constructor(
    public helper: HelperService,
    private listados: ListadosService,
    private dialog: MatDialog,
  ) {}

  public async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listados
      .subscribe<IListado<IEventoTecnico>>('eventoTecnicos', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de eventoTecnicos`, data);
      });
    await this.listados.getLastValue('eventoTecnicos', this.query);
  }

  // Acciones
  public async atender(data: IEventoTecnico) {
    this.dialog.open(TratamientoTecnicoComponent, {
      data,
      hasBackdrop: false,
      minWidth: '1100px',
      maxWidth: '1500px',
    });
  }

  // Tabla

  private setColumnas() {
    const columnas: IColumnas<IEventoTecnico>[] = [
      // Fecha
      {
        header: {
          label: 'Fecha',
          sortable: true,
        },
        row: {
          field: 'fechaCreacion',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      // Estado
      {
        header: {
          label: 'Estado',
          sortable: true,
        },
        row: {
          field: 'estado',
        },
      },
      // Título
      {
        header: {
          label: 'Título',
          sortable: true,
        },
        row: {
          field: 'titulo',
          noData: 'Sin Título',
        },
      },
      // Categoría
      {
        header: {
          label: 'Categoría',
          sortable: true,
        },
        row: {
          field: 'categoria',
        },
      },
      // Tracker o Alarma o Activo
      {
        header: {
          label: 'Elemento',
          sortable: true,
        },
        row: {
          parse(dato) {
            if (dato.tracker) {
              return dato.tracker.identificacion;
            }
            if (dato.alarma) {
              return dato.alarma.nombre;
            }
            if (dato.activo) {
              return dato.activo.identificacion;
            }
            return undefined;
          },
          noData: 'Sin Elemento',
        },
      },
    ];

    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        columnas.push({
          header: {
            label: 'Cliente',
            sortable: true,
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }

    // Acciones
    columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        acciones: [
          {
            tipo: 'material',
            color: 'accent',
            icon: 'troubleshoot',
            tooltip: 'Atender',
            click: (dato) => this.atender(dato),
          },
        ],
      },
    });

    this.columnas = columnas;
  }

  // Hooks

  async ngOnInit() {
    this.setColumnas();
    await this.listar();
  }

  ngOnDestroy() {
    this.datos$?.unsubscribe();
  }
}
