<mat-card>
  @if (form) {
    <!-- Headers -->
    <mat-card-header>
      @if (dialogRef) {
        <!-- Header para dialog con drag -->
        <mat-card-title
          cdkDrag
          cdkDragHandle
          cdkDragRootElement=".cdk-overlay-pane"
        >
          {{ title }}
        </mat-card-title>
      } @else {
        <!-- Header para fullscreen sin drag -->
        <mat-card-title>
          {{ title }}
        </mat-card-title>
      }
    </mat-card-header>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-card-content
        style="margin: 2em 0; overflow: auto"
        [class.cardContentDialog]="dialogRef"
        [class.cardContentFullscreen]="!dialogRef"
      >
        <div class="row-center" style="gap: 8px">
          <!-- Identificación-->
          <mat-form-field style="width: 100%">
            <mat-label>Identificación</mat-label>
            <input matInput formControlName="identificacion" />
          </mat-form-field>
        </div>

        <!-- Dirección -->
        <app-autocomplete-direccion
          [form]="form"
          formControlDireccion="direccion"
          formControlGeoJSON="circleGeoJSON"
          [direccion]="ubicacion?.direccion"
          (geoJSONChange)="centrarA = $event"
        ></app-autocomplete-direccion>

        <!-- Formulario para dibujar polígonos -->
        <app-map-draw
          [form]="geojson"
          tipo="Polygon"
          [centrarA]="centrarA"
        ></app-map-draw>
      </mat-card-content>
      <mat-divider></mat-divider>
      <!-- Botones  -->
      <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
        <app-button
          matType="mat-fab extended"
          color="warn"
          (click)="volver()"
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="!this.form.valid || loading"
          color="accent"
          text="Guardar"
          icon="save"
          type="submit"
        ></app-button>
      </mat-card-actions>

      <!-- DEBUG -->
      <!-- <pre>{{ form.value | json }}</pre> -->
    </form>
  }
</mat-card>
