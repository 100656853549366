@if (tecnicos) {
  <mat-card class="m-2" fxLayout="column" fxLayoutAlign="space-between start">
    <!-- Header -->
    <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
      <h2 mat-dialog-title cdkDragHandle>Asignar Técnico</h2>
    </ng-container>

    <!-- Content -->
    <mat-card-content>
      <div class="row-center" style="width: 100%; gap: 8px">
        <ng-select
          [items]="tecnicos"
          bindValue="_id"
          [ngModel]="tecnicoSeleccionado"
          placeholder="Técnico"
          [notFoundText]="'No hay técnicos creados'"
          style="width: 90%"
          multiple="false"
          searchable="true"
          appendTo="body"
          (change)="onChange($event)"
          (clear)="onClear()"
        >
          <!-- Tag -->
          <ng-template ng-label-tmp let-item="item">
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0.5em;
              "
            >
              <!-- AVATAR - NOMBRE -->
              @if (item.datosPersonales?.foto) {
                <div class="preview-container">
                  <div
                    class="preview-tag"
                    [style.background-image]="
                      'url(' + item.datosPersonales.foto + ')'
                    "
                  ></div>
                </div>
              }
              <span>{{ item.datosPersonales?.nombre || item?.usuario }}</span>
            </div>
          </ng-template>
          <!-- Opción -->
          <ng-template ng-option-tmp let-item="item">
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0.5em;
              "
            >
              <!-- AVATAR - NOMBRE -->
              @if (item.datosPersonales?.foto) {
                <div class="preview-container">
                  <div
                    class="preview"
                    [style.background-image]="
                      'url(' + item.datosPersonales.foto + ')'
                    "
                  ></div>
                </div>
              }

              <span>{{ item.datosPersonales?.nombre || item?.usuario }}</span>
            </div>
          </ng-template>
        </ng-select>
        <app-button
          matType="mat-fab"
          color="primary"
          icon="add"
          (click)="crear()"
        ></app-button>
      </div>
    </mat-card-content>

    <mat-divider style="margin-top: 1em"></mat-divider>

    <!-- Acciones -->
    <mat-dialog-actions
      style="display: flex; justify-content: flex-end; gap: 1em"
    >
      <app-button
        matType="mat-fab extended"
        color="warn"
        mat-dialog-close
        text="Cerrar"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!loading()"
        color="accent"
        text="Guardar"
        icon="engineering"
        type="button"
        (click)="asignar()"
      ></app-button>
    </mat-dialog-actions>
  </mat-card>
} @else {
  <div class="row-center">
    <mat-progress-spinner
      mode="indeterminate"
      diameter="20"
    ></mat-progress-spinner>
  </div>
}
