<div id="map" class="map-container">
  <div id="flotante" class="ol-flotante">
    <app-flotante-ol
      (ocultar)="ocultar($event)"
      [colectivos]="reportesColectivos?.length"
      [activos]="reportesActivos?.length"
      [alarmas]="alarmas?.length"
      [vehiculos]="reportesVehiculos?.length"
      [trackers]="reportesTrackers?.length"
    ></app-flotante-ol>
  </div>
  @if (reporteSeleccionado || alarmaSeleccionada) {
    <div id="isla" class="ol-isla">
      <app-isla-ol
        [(reporte)]="reporteSeleccionado"
        [(alarma)]="alarmaSeleccionada"
        (alarmaChange)="onClose()"
        (reporteChange)="onClose()"
        [trackeo]="trackeo"
      ></app-isla-ol>
    </div>
  }
</div>
