@if (!loading) {
  <br />
  <div class="row-center">
    <span class="subtitle1">Información de la camara</span>
  </div>
  <br />
  <div class="row-center" style="gap: 15px">
    <div>
      <span class="datoName" style="padding-right: 5px">Identificación:</span>
      <span class="dato">{{ camara?.identificacion }}</span>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Cliente:</span>
      <span class="dato">{{ camara?.cliente?.nombre }}</span>
    </div>
  </div>

  <div class="row-center" style="gap: 15px">
    <div>
      <span class="datoName" style="padding-right: 5px">Host:</span>
      <span class="dato">{{ camara?.host }}</span>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Puerto HTTP:</span>
      <span class="dato">{{ camara?.puertoHTTP }}</span>
    </div>
    <div>
      <span class="datoName" style="padding-right: 5px">Puerto RTSP:</span>
      <span class="dato">{{ camara?.puertoRTSP }}</span>
    </div>
  </div>
}
