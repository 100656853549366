import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ICategoriaUbicacion,
  ICliente,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  IUbicacion,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../auxiliares/servicios';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../auxiliares/dialog/dialog.service';
import { UbicacionService } from '../../../../auxiliares/servicios/http/ubicacion.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-listado-ubicaciones',
  templateUrl: './listado-ubicaciones.component.html',
  styleUrl: './listado-ubicaciones.component.scss',
})
export class ListadoUbicacionesComponent implements OnInit, OnDestroy {
  public categoria?: ICategoriaUbicacion;
  //// Tabla nueva
  public name = ListadoUbicacionesComponent.name;
  public datos: IUbicacion[] = [];

  public totalCount = 0;
  public columnas?: IColumnas<IUbicacion>[];
  //Subs
  public ubicacion$?: Subscription;
  public clientes$?: Subscription;
  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;
  //
  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeEditar(),
    tooltip: 'Crear',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['identificacion'],
  };
  // FILTROS
  private cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  public filtros: IFiltroTabla[] = [];
  //Query

  public populate: IPopulate = {
    path: 'cliente',
    select: 'nombre',
  };
  public query: IQueryParam = {
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: UbicacionService,
    private navigator: Router,
    private route: ActivatedRoute,
  ) {}
  // Listars
  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      Promise.all([this.listar(), this.listarClientes()]);
    } else {
      await this.listar();
    }
  }
  public async listar(): Promise<void> {
    const filter: IFilter<IUbicacion> = this.query.filter
      ? JSON.parse(this.query.filter)
      : {};
    filter.categoria = this.categoria;
    this.query.filter = JSON.stringify(filter);

    //
    this.ubicacion$?.unsubscribe();
    this.ubicacion$ = this.listados
      .subscribe<IListado<IUbicacion>>('ubicacions', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de ubicacions`, data);
      });
    await this.listados.getLastValue('ubicacions', this.query);
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(ubicacion: IUbicacion) {
    this.navigator.navigate(['editar', ubicacion._id], {
      relativeTo: this.route,
    });
  }

  public async eliminar(ubicacion: IUbicacion) {
    const res = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Desea eliminar la ubicacion ${ubicacion.identificacion}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(ubicacion._id!);
      this.helper.notifSuccess('Eliminado correctamente');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    const columnas: IColumnas<IUbicacion>[] = [
      // Identificacion
      {
        header: {
          label: 'Identificación',
          sortable: true,
        },
        row: {
          field: 'identificacion',
        },
      },
    ];
    // Cliente
    if (HelperService.getTipoCliente() !== 'Final') {
      {
        columnas.push({
          header: {
            label: 'Cliente',
          },
          row: {
            parse(dato) {
              return dato.cliente?.nombre;
            },
          },
        });
      }
    }

    columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        acciones: [
          {
            tipo: 'material',
            color: 'accent',
            icon: 'edit',
            tooltip: 'Editar',
            click: (dato) => this.editar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'warn',
            icon: 'delete',
            tooltip: 'Eliminar',
            click: (dato) => this.eliminar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
        ],
      },
    });
    this.columnas = columnas;
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();

    this.route.params.subscribe(async (params) => {
      this.categoria = params?.['categoria'];
      await this.listar();
    });
  }

  ngOnDestroy() {
    this.ubicacion$?.unsubscribe();
  }
}
