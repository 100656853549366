<app-tabla
  [totalCount]="totalCount"
  [datos]="logsDespachos"
  [columnas]="columnas"
  [name]="name"
  [pageSizeOptions]="helper.pageSizeOptions"
  [(query)]="query"
  [filtros]="filtros"
  (queryChange)="listar()"
  [paginatorArriba]="false"
></app-tabla>
