import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICanalesCamara } from 'modelos/src';

@Component({
  selector: 'app-detalles-canales-camaras',
  templateUrl: './detalles-canales.component.html',
  styleUrl: './detalles-canales.component.scss',
  standalone: false,
})
export class DetallesCanalesCamarasComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ICanalesCamara) {}
}
