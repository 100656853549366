import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IFilter,
  IListado,
  IQueryParam,
  IPopulate,
  ICamara,
  IDispositivoAlarma,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../../../login/login.service';
import { CamarasService } from '../../../../../auxiliares/servicios/http/camara.service';
import {
  IParamsService,
  ParamsService,
} from '../../../../../auxiliares/servicios/params.service';
import { CamarasMultipleComponent } from '../../../../standalone/camaras-multiple/camaras-multiple.component';

@Component({
  selector: 'app-listado-camaras',
  templateUrl: './listado-camaras.component.html',
  styleUrl: './listado-camaras.component.scss',
  standalone: false,
})
export class ListadoCamarasComponent implements OnInit, OnDestroy {
  public clientePropio = LoginService.getCliente();
  //// Tabla nueva
  public name = ListadoCamarasComponent.name;
  public datos: ICamara[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<ICamara>[];
  //Subs
  public datos$?: Subscription;
  public alarmas$?: Subscription;
  //
  public botonCrear: ICrearBoton = {
    mostrar:
      this.helper.puedeEditar() &&
      HelperService.getConfigCliente().moduloAlarmasDomiciliarias
        ?.crearDispositivos,
    tooltip: 'Crear Cámara',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  // FILTROS
  public search: IRegExpSearch = {
    fields: ['identificacion'],
  };
  private cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  public modelo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idModelo',
    },
    label: 'Modelo',
    selectLabel: 'marca',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarMarcas$',
    searchOn: ['marca', 'modelo'],
  };
  public filtros: IFiltroTabla[] = [this.modelo];
  //Query
  public filter: IFilter<ICamara> = {};
  public populate: IPopulate[] = [
    {
      path: 'modeloDispositivo',
      select: 'marca modelo',
    },
    {
      path: 'cliente',
      select: 'nombre',
    },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'identificacion',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: CamarasService,
    public dialog: MatDialog,
    private navigator: Router,
    private route: ActivatedRoute,
    private paramsService: ParamsService,
  ) {}
  // Listados

  public async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listados
      .subscribe<IListado<ICamara>>('camaras', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de camaras`, data);
      });
    await this.listados.getLastValue('camaras', this.query);
  }

  public async listarAlarma(id: string) {
    const filter: IFilter<IDispositivoAlarma> = {
      idsCamaras: { $in: [id] },
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
    };
    this.alarmas$?.unsubscribe();
    let asignado = '';
    this.alarmas$ = this.listados
      .subscribe<IListado<IDispositivoAlarma>>('dispositivosAlarmas', query)
      .subscribe((data) => {
        console.log(`listado de dispositivoAlarma`, data);
        if (data.datos.length > 0) {
          asignado = data.datos[0].nombre;
        } else {
          asignado = '';
        }
      });
    await this.listados.getLastValue('dispositivosAlarmas', query);
    return asignado;
  }

  // Acciones
  public async crear() {
    const params: IParamsService = {};
    this.paramsService.setParams(params);
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(camara: ICamara) {
    const params: IParamsService = {
      edit: camara,
    };
    this.paramsService.setParams(params);
    this.navigator.navigate(['editar', camara._id], {
      relativeTo: this.route,
    });
  }

  public async detalles(camara: ICamara) {
    this.navigator.navigate(['detalles', camara._id], {
      relativeTo: this.route,
    });
  }

  public async verStream(camara: ICamara) {
    this.paramsService.setParams({ camara: [camara], dialog: true });
    const dialogs = this.dialog.openDialogs;
    const idAbierto = dialogs.find(
      (d) => d.componentInstance.data._id === camara._id,
    );
    if (!idAbierto) {
      this.dialog.open(CamarasMultipleComponent, {
        width: '60vh',
        maxWidth: '100vh',
        height: '60vh',
        maxHeight: '100vh',
      });
    }
  }

  public async eliminar(camara: ICamara) {
    const asignado = await this.listarAlarma(camara._id);
    console.log(camara);
    let res = await this.dialogService.confirm(
      'Eliminar Cámara',
      `¿Desea eliminar la cámara ${camara.identificacion} (${camara.modeloDispositivo?.marca || 'Marca'}, ${camara.modeloDispositivo?.modelo || 'Modelo'})?`,
    );
    if (!res) return;
    if (asignado) {
      res = await this.dialogService.confirm(
        'Eliminar Cámara',
        `La cámara esta asignada a la alarma ${asignado} ¿Desea eliminarla igualmente?`,
      );
      if (!res) return;
    }
    try {
      await this.service.delete(camara._id!);
      this.helper.notifSuccess('Eliminado correctamente');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    const columnas: IColumnas<ICamara>[] = [
      // Identificacion
      {
        header: {
          label: 'Identificación',
        },
        row: {
          field: 'identificacion',
        },
      },
    ];

    if (HelperService.getTipoCliente() !== 'Final') {
      // Cliente
      columnas.push({
        header: {
          label: 'Cliente',
          sortable: true,
        },
        row: {
          parse(dato) {
            if (dato.cliente?.nombre) {
              let text = dato.cliente?.nombre;
              if (text.length >= 50) {
                text = text.slice(0, 50);
                text += '...';
              }
              return text;
            }
            return '';
          },
        },
      });
    }

    // Acción
    columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        accionesGrouped: [
          {
            tipo: 'material',
            color: 'primary',
            icon: 'search',
            tooltip: 'Detalles',
            click: (dato) => this.detalles(dato),
          },
          {
            tipo: 'material',
            color: 'primary',
            icon: 'videocam',
            tooltip: 'Ver Stream',
            click: (dato) => this.verStream(dato),
          },
          {
            tipo: 'material',
            color: 'accent',
            icon: 'edit',
            tooltip: 'Editar',
            click: (dato) => this.editar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'warn',
            icon: 'delete',
            tooltip: 'Eliminar',
            click: (dato) => this.eliminar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
        ],
      },
    });

    this.columnas = columnas;
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();
    await this.listar();
  }

  async ngOnDestroy() {
    this.datos$?.unsubscribe();
  }
}
