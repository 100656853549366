import { Component } from '@angular/core';
import { LoginService } from '../../../../login/login.service';

@Component({
  selector: 'app-menu-tecnico',
  standalone: false,
  templateUrl: './menu-tecnico.component.html',
  styleUrl: './menu-tecnico.component.scss',
})
export class MenuTecnicoComponent {
  public usuarioPropio = LoginService.getUsuario();
}
