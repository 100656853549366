import { Injectable } from '@angular/core';
import {
  ICreateUsuario,
  IDocumentacion,
  IListado,
  IQueryParam,
  IUpdateUsuario,
} from 'modelos/src';
import { HttpClientService } from '../http.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentosService {
  constructor(private http: HttpClientService) {}

  public getFiltered(
    queryParams?: IQueryParam,
  ): Promise<IListado<IDocumentacion>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/documentos`, { params });
  }

  public getById(id: string): Promise<IDocumentacion> {
    return this.http.get(`/documentos/${id}`);
  }

  public create(dato: ICreateUsuario): Promise<IDocumentacion> {
    return this.http.post(`/documentos`, dato);
  }

  public update(id: string, dato: IUpdateUsuario): Promise<IDocumentacion> {
    return this.http.put(`/documentos/${id}`, dato);
  }

  public delete(id: string): Promise<void> {
    return this.http.delete(`/documentos/${id}`);
  }
}
