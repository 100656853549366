import { HttpParams } from '@angular/common/http';
import {
  Inject,
  Injectable,
  Renderer2,
  RendererFactory2,
  signal,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IConfigCliente,
  IConfigUsuario,
  ICoordenadas,
  IParada,
  IQueryParam,
  ITipoCliente,
} from 'modelos/src';
import { HttpClientService } from './http.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, map, shareReplay } from 'rxjs';
import { DOCUMENT } from '@angular/common';

/// GEOLIB
import * as geolib from 'geolib';
import { OpenLayersService } from './openLayers.service';
import { Coordinate } from 'ol/coordinate';
import { LoginService } from '../../modulos/login/login.service';

export interface IParadaCerca {
  punto: ICoordenadas;
  distancia: number;
  index: number;
  parada?: IParada;
}

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public pageSizeOptions = [5, 10, 15, 25, 50, 100];
  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );
  private renderer?: Renderer2;
  public darkTheme = !!localStorage.getItem('dark-theme');
  static darkTheme = !!localStorage.getItem('dark-theme');
  /// Permisos
  public esNivel0 = signal(false);
  public esClienteFinal = signal(false);
  public verModuloAlarmas = signal(false);
  public verModuloColectivos = signal(false);
  public verModuloActivos = signal(false);
  public verModuloAdministracion = signal(false);
  public verModuloEventosTecnicos = signal(false);
  public verModuloVehiculos = signal(false);
  public puedeEditar = signal(false);
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
    private snackBar: MatSnackBar,
    private http: HttpClientService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.initTheme();
  }

  static isDefined(value: unknown): boolean {
    return value !== null && value !== undefined;
  }

  static stringToObject(s?: string): Record<string, unknown> {
    if (!s) return {};
    try {
      return JSON.parse(s);
    } catch (error) {
      return {};
    }
  }

  public notifSuccess(message: string, action = 'Cerrar') {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: 'app-notification-success',
    });
  }

  public notifWarn(message: string, action = 'Cerrar') {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: 'app-notification-warn',
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public notifError(error: any, action = 'Cerrar', texto = false) {
    if (typeof error === 'string') {
      error = { mensaje: error };
    }

    let message =
      error?.error?.message ||
      error?.error?.mensaje ||
      error?.mensaje ||
      error?.message ||
      'Error desconocido, ver logs';
    if (texto) {
      message = error;
    }
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: 'app-notification-error',
    });
  }

  public static getQueryParams(queryParams?: IQueryParam) {
    let params = new HttpParams();
    if (queryParams) {
      const keysIgnorar = [
        'page',
        'limit',
        'sort',
        'populate',
        'select',
        'filter',
        'includeChildren',
      ];
      if (queryParams?.page) {
        params = params.set('page', queryParams.page.toString());
      }
      if (queryParams?.limit) {
        params = params.set('limit', queryParams.limit.toString());
      }
      if (queryParams?.sort) {
        params = params.set('sort', queryParams.sort);
      }
      if (queryParams?.populate) {
        params = params.set('populate', queryParams.populate);
      }
      if (queryParams?.select) {
        params = params.set('select', queryParams.select);
      }
      if (queryParams?.filter) {
        params = params.set('filter', queryParams.filter);
      }
      if (queryParams?.includeChildren) {
        params = params.set('includeChildren', true);
      }

      for (const key in queryParams) {
        if (!keysIgnorar.includes(key)) {
          params = params.set(key, queryParams[key]);
        }
      }
    }
    return params;
  }

  public static getQueryGenerico(queryParams?: IQueryParam) {
    let params = new HttpParams();
    if (queryParams) {
      for (const key in queryParams) {
        params = params.set(key, queryParams[key]);
      }
    }
    return params;
  }

  static parseDate(date: Date): string {
    const d = new Date(date);
    const day = `0${d.getDate()}`.slice(-2);
    const month = `0${d.getMonth() + 1}`.slice(-2);
    const year = d.getFullYear();
    const hour = `0${d.getHours()}`.slice(-2);
    const minutes = `0${d.getMinutes()}`.slice(-2);
    return `${day}/${month}/${year} ${hour}:${minutes}`;
  }

  /// Subir Imagen

  public subirImagen(
    file: File,
    nombreImagen: string,
    folder = 'imagenes',
  ): Promise<{ url: string }> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', nombreImagen);
    formData.append('folder', folder);

    return this.http.post(`/storage/upload`, formData);
  }

  /// Tema
  public toggleTheme() {
    if (this.document.body.classList.contains('dark-theme')) {
      localStorage.setItem('dark-theme', 'false');
      this.darkTheme = false;
      this.renderer?.removeClass(this.document.body, 'dark-theme');
    } else {
      localStorage.setItem('dark-theme', 'true');
      this.darkTheme = true;
      this.renderer?.addClass(this.document.body, 'dark-theme');
    }
  }

  private initTheme() {
    const darkTheme = localStorage.getItem('dark-theme');
    if (darkTheme === 'true') {
      this.darkTheme = true;
      this.renderer?.addClass(this.document.body, 'dark-theme');
    } else this.darkTheme = false;
  }

  //// VOLVER

  static volver() {
    window.history.back();
  }

  public volver() {
    HelperService.volver();
  }

  ///

  public getPageSize(nombreTabla?: string): number {
    const size =
      localStorage.getItem(`page-${nombreTabla}`) ||
      localStorage.getItem(nombreTabla || 'pageSize');
    return size ? +size : this.pageSizeOptions[2];
  }

  ///

  public async copiarAlPortapapeles(t: string) {
    await navigator.clipboard.writeText(t);
    this.notifSuccess(`${t} al portapapeles`);
  }

  /// MAPAS

  public static async getCurrentPosition(): Promise<ICoordenadas> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const ubicacionBase: ICoordenadas = {
        lat: -35.5836812,
        lng: -58.0128784,
      };
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const ubicacion: ICoordenadas = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            resolve(ubicacion);
          },
          () => {
            console.error('Ubicacion no aceptada');
            resolve(ubicacionBase);
          },
        );
      } else {
        resolve(ubicacionBase);
      }
    });
  }

  /// Distancia entre dos puntos en METROS. Si querés unidades imperiales, multiplicá por 3.28084
  public distanciaEntrePuntos(p1: ICoordenadas, p2: ICoordenadas): number {
    return geolib.getDistance(p1, p2, 1);
  }

  /// distancia mínima entre un punto y una linea
  public distanciaEntrePuntoYLineString(
    p: ICoordenadas,
    polyline: ICoordenadas[],
  ): number {
    let distanciaMinima = Number.POSITIVE_INFINITY;
    // Iterar sobre los segmentos del LineString
    for (let i = 0; i < polyline.length - 1; i++) {
      const puntoA = polyline[i];
      const puntoB = polyline[i + 1];

      const distancia = geolib.getDistanceFromLine(p, puntoA, puntoB);
      distanciaMinima = Math.min(distanciaMinima, distancia);
    }
    return distanciaMinima;
  }

  /// COLORES

  static textToColorHex(text: string): string {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  }
  public colorDeTextoSegunColor(color: string): string {
    if (color?.[0] === '#') {
      return this.esColorClaro(color) ? '#000000' : '#FFFFFF';
    } else {
      return null;
    }
  }
  private esColorClaro(hexColor: string): boolean {
    // Elimina el símbolo '#' si está presente
    const color = hexColor.replace('#', '');

    // Convierte el color a valores RGB
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);

    // Calcula la luminosidad relativa
    const luminosidad = 0.299 * r + 0.587 * g + 0.114 * b;

    // Si la luminosidad es mayor a 186, el color es claro
    return luminosidad > 186;
  }

  ///

  static diaATexto(dia: number): string {
    switch (dia) {
      case 0:
        return 'Domingo';
      case 1:
        return 'Lunes';
      case 2:
        return 'Martes';
      case 3:
        return 'Miércoles';
      case 4:
        return 'Jueves';
      case 5:
        return 'Viernes';
      case 6:
        return 'Sábado';
      default:
        return 'Error';
    }
  }
  public diaATexto(dia: number): string {
    switch (dia) {
      case 0:
        return 'Domingo';
      case 1:
        return 'Lunes';
      case 2:
        return 'Martes';
      case 3:
        return 'Miércoles';
      case 4:
        return 'Jueves';
      case 5:
        return 'Viernes';
      case 6:
        return 'Sábado';
      default:
        return 'Error';
    }
  }

  ///

  static getFecha(time: string) {
    if (time) {
      const date = new Date(time);
      date.setHours(date.getUTCHours() - 3);
      const day = `0${date.getUTCDate()}`.slice(-2);
      const month = `0${date.getUTCMonth() + 1}`.slice(-2);
      const year = date.getUTCFullYear();
      const fecha = day + '/' + month + '/' + year;
      return fecha;
    }
    return '';
  }

  static getHora(time: string, segs = false) {
    if (time) {
      const date = new Date(time);
      date.setUTCHours(date.getUTCHours() - 3);
      const hora = `0${date.getUTCHours()}`.slice(-2);
      const minutos = `0${date.getUTCMinutes()}`.slice(-2);
      const segundos = `0${date.getUTCSeconds()}`.slice(-2);
      if (segs) {
        const horas = `${hora}:${minutos}:${segundos}`;
        return horas;
      } else {
        const horas = `${hora}:${minutos}`;
        return horas;
      }
    }
    return '';
  }

  public getHora(time: string, segs = false) {
    if (time) {
      const date = new Date(time);
      date.setUTCHours(date.getUTCHours() - 3);
      const hora = `0${date.getUTCHours()}`.slice(-2);
      const minutos = `0${date.getUTCMinutes()}`.slice(-2);
      const segundos = `0${date.getUTCSeconds()}`.slice(-2);
      if (segs) {
        const horas = `${hora}:${minutos}:${segundos}`;
        return horas;
      } else {
        const horas = `${hora}:${minutos}`;
        return horas;
      }
    }
    return '';
  }

  static getFechaYHora(time: string) {
    if (time) {
      const date = new Date(time);
      date.setUTCHours(date.getUTCHours() - 3);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const hour = date.getUTCHours();
      const minutes = `0${date.getUTCMinutes()}`.slice(-2);
      const seconds = `0${date.getUTCSeconds()}`.slice(-2);
      const fecha =
        day +
        '/' +
        month +
        '/' +
        year +
        ', ' +
        hour +
        ':' +
        minutes +
        ':' +
        seconds;
      return fecha;
    }
    return '';
  }

  public getCentro(c: ICoordenadas[]): ICoordenadas {
    const centro = geolib.getCenter(c);
    if (centro) {
      return { lat: centro.latitude, lng: centro.longitude };
    } else {
      return { lat: 0, lng: 0 };
    }
  }

  static getCentro2(c: [number, number][]): Coordinate | null {
    const centro = geolib.getCenter(c);

    if (centro) {
      const res = OpenLayersService.lonLatToCoordinate(
        centro.longitude,
        centro.latitude,
      );
      return res;
    } else {
      return null;
    }
  }

  static CoordToGeoJSON(c: ICoordenadas) {
    return [c.lng, c.lat];
  }

  static parseISOString(s: string) {
    // 15/12/2020, 12:00:00
    const date = new Date(s);
    date.setUTCHours(date.getUTCHours() - 3);
    const day = `0${date.getUTCDate()}`.slice(-2);
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const year = date.getUTCFullYear();
    const hour = `0${date.getUTCHours()}`.slice(-2);
    const minutes = `0${date.getUTCMinutes()}`.slice(-2);
    const seconds = `0${date.getUTCSeconds()}`.slice(-2);
    return `${day}/${month}/${year}, ${hour}:${minutes}:${seconds}`;
  }

  static msToKmh(ms: number, floor = false) {
    const kmh = geolib.convertSpeed(ms, 'kmh');
    return floor ? Math.floor(kmh) : kmh;
  }

  static mToKm(m: number, floor = false) {
    const km = geolib.convertDistance(m, 'km');
    return floor ? Math.floor(km) : km;
  }

  public msToKmh(ms: number, floor = false) {
    const kmh = geolib.convertSpeed(ms, 'kmh');
    return floor ? Math.floor(kmh) : kmh;
  }

  public mToKm(m: number, floor = false) {
    const km = geolib.convertDistance(m, 'km');
    return floor ? Math.floor(km) : km;
  }

  public parseMs(ms: number) {
    const unaHora = 1000 * 60 * 60;
    const unMinuto = 1000 * 60;
    const unSegundo = 1000;
    // Formato: HH:MM:SS
    // const horas = Math.floor(ms / unaHora);
    // const minutos = Math.floor((ms % unaHora) / (1000 * 60));
    // const segundos = Math.floor((ms % (1000 * 60)) / 1000);
    // return `${horas}:${minutos}:${segundos}`;

    // Formato: x Hs y z Min o z Min y x seg
    const horas = Math.floor(ms / unaHora);
    const minutos = Math.floor((ms % unaHora) / unMinuto);
    const segundos = Math.floor((ms % unMinuto) / unSegundo);
    if (horas > 0) {
      return `${horas} hs. ${minutos} min.`;
    } else {
      return `${minutos} min. ${segundos} seg.`;
    }
  }

  static padZero(str: string, len: number = 2): string {
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }
  static invertirColor(hex?: string, bw: boolean = true) {
    if (!hex) {
      return '#000000';
    }
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      // https://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    }
    // invert color components
    const r_str = (255 - r).toString(16);
    const g_str = (255 - g).toString(16);
    const b_str = (255 - b).toString(16);
    // pad each with zeros and return
    return (
      '#' +
      HelperService.padZero(r_str) +
      HelperService.padZero(g_str) +
      HelperService.padZero(b_str)
    );
  }

  public static textToColor(text: string): string {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  }

  public static hexToRgba(hex: string, opacity: number): string {
    // Elimina el símbolo '#' si está presente
    hex = hex.replace(/^#/, '');

    // Divide el valor HEX en partes de 2 caracteres
    let r = 0,
      g = 0,
      b = 0;

    if (hex.length === 3) {
      // Si el HEX es del tipo corto (#abc), lo expandimos a 6 caracteres (#aabbcc)
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
      // Si el HEX ya tiene 6 caracteres
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    }

    // Retorna el formato RGBA
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  public static colorNameToHex(color: string): string {
    // Crea un elemento temporal en el DOM
    const tempElement = document.createElement('div');

    // Aplica el color al elemento
    tempElement.style.color = color;

    // Agrega el elemento al cuerpo del documento (pero oculto)
    document.body.appendChild(tempElement);

    // Computa el estilo aplicado al elemento
    const computedColor = window.getComputedStyle(tempElement).color;

    // Elimina el elemento temporal
    document.body.removeChild(tempElement);

    // Convierte el valor RGB obtenido a HEX
    const rgbMatch = computedColor.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+).*\)$/,
    );
    if (!rgbMatch) {
      return HelperService.textToColor(color); // No es un color válido hago uno con las palabra
    }

    const r = parseInt(rgbMatch[1]).toString(16).padStart(2, '0');
    const g = parseInt(rgbMatch[2]).toString(16).padStart(2, '0');
    const b = parseInt(rgbMatch[3]).toString(16).padStart(2, '0');

    return `#${r}${g}${b}`;
  }

  // Permisos y tipo de cliente
  static getTipoCliente(): ITipoCliente {
    const cliente = LoginService.getCliente();
    return cliente?.tipoCliente;
  }

  static getConfigCliente(): IConfigCliente {
    const cliente = LoginService.getCliente();
    return cliente?.config;
  }
  static getConfigUsuario(): IConfigUsuario {
    const usuario = LoginService.getUsuario();
    console.log(usuario);
    return usuario?.config;
  }

  // Fechas
  public hora(hora: number, minutos = 0) {
    const d = new Date();
    d.setHours(hora, minutos, 0, 0);
    return d;
  }
}
