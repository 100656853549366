import { Component, OnDestroy, OnInit } from '@angular/core';
import { ILogDespacho, IListado, IQueryParam, IPopulate } from 'modelos/src';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { IColumnas } from '../../../../../auxiliares/tabla/tabla.component';
import { DatePipe } from '@angular/common';
import { IFiltroTabla } from '../../../../../auxiliares/tabla/filtro/filtro.component';

@Component({
  selector: 'app-logs-despachos',
  templateUrl: './logs-despachos.component.html',
  styleUrl: './logs-despachos.component.scss',
  standalone: false,
})
export class LogsDespachosComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = LogsDespachosComponent.name;
  public logsDespachos: ILogDespacho[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<ILogDespacho>[];
  //
  public logsDespachos$?: Subscription;
  public clientes$?: Subscription;

  // FILTROS
  public clienteFiltro: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect-async',
    asyncFunction: 'listarClientes$',
    searchOn: ['nombre'],
  };
  public filtros: IFiltroTabla[] = [];
  private populate: IPopulate = {
    path: 'cliente',
    select: 'nombre',
  };

  public query: IQueryParam = {
    populate: JSON.stringify(this.populate),
    sort: '-fechaCreacion',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: true,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private navigator: Router,
    private route: ActivatedRoute,
  ) {}

  public async listar(): Promise<void> {
    this.logsDespachos$?.unsubscribe();
    this.logsDespachos$ = this.listados
      .subscribe<IListado<ILogDespacho>>('logsDespachos', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.logsDespachos = data.datos;
        console.log(`listado de logsDespachos`, data);
      });
    await this.listados.getLastValue('logsDespachos', this.query);
  }

  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }
  // Tabla
  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Fecha Creación',
          sortable: true,
        },
        row: {
          field: 'fechaCreacion',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      {
        header: {
          label: 'Fecha',
          sortable: true,
        },
        row: {
          field: 'fecha',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      {
        header: {
          label: 'ID Vehiculo Externo',
        },
        row: {
          field: 'idExternoVehiculo',
        },
      },
      {
        header: {
          label: 'ID Recorrido Externo',
        },
        row: {
          field: 'idExternoRecorrido',
        },
      },
      {
        header: {
          label: 'ID Chofer Externo',
        },
        row: {
          field: 'idExternoChofer',
        },
      },
      {
        header: {
          label: 'Cliente',
        },
        row: {
          parse(dato) {
            return dato.cliente?.nombre;
          },
        },
      },
    ];
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.clienteFiltro);
    }
    this.setColumnas();
    await this.listar();
  }

  ngOnDestroy() {
    this.logsDespachos$?.unsubscribe();
  }
}
