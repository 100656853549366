/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICliente, IListado, IPopulate, IQueryParam } from 'modelos/src';
import { Subscription } from 'rxjs';

import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/modules/treemap';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogService } from '../../../../../../auxiliares/dialog/dialog.service';
import { ListadosService } from '../../../../../../auxiliares/servicios';
import { HelperService } from '../../../../../../auxiliares/servicios/helper.service';
import { ClientesService } from '../../../../../../auxiliares/servicios/http/clientes.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../../auxiliares/tabla/filtro/filtro.component';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../../auxiliares/tabla/tabla.component';
import { MatDialog } from '@angular/material/dialog';
import { ParamsService } from '../../../../../../auxiliares/servicios/params.service';
import { LoginService } from '../../../../../login/login.service';
HC_more(Highcharts);

@Component({
  selector: 'app-clientes-tabla',
  templateUrl: './clientes.component.html',
  styleUrl: './clientes.component.scss',
  standalone: false,
})
export class ListadoClientesTablaComponent implements OnInit, OnDestroy {
  public datos: ICliente[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<ICliente>[];
  public botonCrear: ICrearBoton = {
    mostrar:
      HelperService.getTipoCliente() !== 'Final' || this.helper.puedeEditar(),
    tooltip: 'Crear Tracker',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };

  public name = ListadoClientesTablaComponent.name;
  private populate: IPopulate = {
    path: 'serviciosContratados',
  };
  public query: IQueryParam = {
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: true,
    childrenLevel: 0,
  };

  // Suscripciones
  public clientes$?: Subscription;

  public search: IRegExpSearch = {
    fields: ['nombre', 'numeroCliente'],
  };

  public filtros: IFiltroTabla[] = [];

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: ClientesService,
    private navigator: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public paramsService: ParamsService,
  ) {}

  // Listados para filtros
  public async listar(): Promise<void> {
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos.filter(
          (d) => d._id !== LoginService.getCliente()._id,
        );
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', this.query);
  }

  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(cliente: ICliente) {
    this.navigator.navigate(['editar', cliente._id], {
      relativeTo: this.route,
    });
  }
  public async notas(cliente: ICliente) {
    this.navigator.navigate(['detalles', cliente._id], {
      relativeTo: this.route,
    });
  }
  public async cambiarEstado(dato: ICliente): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿${dato.habilitado ? 'Deshabilitar' : 'Habilitar'} el Cliente?`,
    );
    if (confirm) {
      try {
        await this.service.update(dato._id!, { habilitado: !dato.habilitado });
        this.helper.notifSuccess(
          `${dato.habilitado ? 'Habilitado' : 'Deshabilitado'}' correctamene`,
        );
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  public async eliminar(cliente: ICliente) {
    const res = await this.dialogService.confirm(
      'Eliminar Cliente',
      `¿Desea eliminar el Cliente ${cliente.nombre}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(cliente._id!);
      this.helper.notifSuccess('Cliente Eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    const columnas: IColumnas<ICliente>[] = [
      // Habilitado
      {
        header: {
          label: 'Habilitado',
          sortable: true,
        },
        row: {
          parse(dato) {
            return dato.habilitado ? '✔️' : '❌';
          },
        },
      },
      // Nombre
      {
        header: {
          label: 'Nombre',
          sortable: true,
        },
        row: {
          field: 'nombre',
        },
      },
      // Numero Cliente
      {
        header: {
          label: 'Numero de Cliente',
          sortable: true,
        },
        row: {
          field: 'numeroCliente',
        },
      },
      // Estado de cuenta
      {
        header: {
          label: 'Estado de Cuenta',
          sortable: true,
        },
        row: {
          field: 'estadoDeCuenta',
        },
      },
      // Servicios Contratados
      {
        header: {
          label: 'Servicios',
        },
        row: {
          parse(dato) {
            let text = '';
            dato?.serviciosContratados?.map((s, index) => {
              index < dato.serviciosContratados.length - 1
                ? (text = text + s.nombre + '\n')
                : (text = text + s.nombre);
            });
            return text;
          },
        },
      },
      // Servicios Contratados
      {
        header: {
          label: 'Modulos Habilitados',
        },
        row: {
          parse(dato) {
            let text = '';
            dato.config?.moduloActivos?.activo ? (text = text + 'Activos') : '';
            dato.config?.moduloAdministracion?.activo
              ? (text = text + '\n' + 'Administracion')
              : '';
            dato.config?.moduloAlarmasDomiciliarias?.activo
              ? (text = text + '\n' + 'Alarmas')
              : '';
            dato.config?.moduloColectivos?.activo
              ? (text = text + '\n' + 'Colectivos')
              : '';
            dato.config?.moduloEventosTecnicos?.activo
              ? (text = text + '\n' + 'Eventos Técnicos')
              : '';
            dato.config?.moduloVehiculos?.activo
              ? (text = text + '\n' + 'Eventos Vehículos')
              : '';
            return text;
          },
        },
      },
      // Tipo de cliente
      {
        header: {
          label: 'Tipo de Cliente',
          sortable: true,
        },
        row: {
          field: 'tipoCliente',
        },
      },
      // Acciones
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'material',
              color: 'primary',
              icon: 'search',
              tooltip: 'Detalles',
              click: (dato) => this.notas(dato),
            },
            {
              tipo: 'material',
              color: 'accent',
              icon: 'edit',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
              oculta() {
                return HelperService.getTipoCliente() === 'Final';
              },
              disabled: () => !this.helper.puedeEditar(),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'delete',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
              oculta() {
                return HelperService.getTipoCliente() === 'Final';
              },
              disabled: () => !this.helper.puedeEditar(),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'check',
              tooltip: 'Habilitar',
              oculta: (dato) => dato.habilitado,
              click: (dato) => this.cambiarEstado(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'close',
              tooltip: 'Deshabilitar',
              oculta: (dato) => !dato.habilitado,
              click: (dato) => this.cambiarEstado(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
          ],
        },
      },
    ];

    this.columnas = columnas;
  }

  // Hooks

  async ngOnInit() {
    this.setColumnas();
    await Promise.all([this.listar()]);
  }

  ngOnDestroy() {
    this.clientes$?.unsubscribe();
  }
}
