import { Component, Input, OnInit } from '@angular/core';
import {
  IQueryParam,
  IActivo,
  IReporte,
  IGeoJSONPoint,
  IListado,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import {
  MapaFacilData,
  MarkerMapa,
} from '../../../../../auxiliares/mapa-facil/interfaces';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { OpenLayersService } from '../../../../../auxiliares/servicios/openLayers.service';
import { ParamsService } from '../../../../../auxiliares/servicios/params.service';

@Component({
  selector: 'app-detalles-colectivo-info',
  templateUrl: './detalles-colectivo-info.component.html',
  styleUrl: './detalles-colectivo-info.component.scss',
  standalone: false,
})
export class DetallesColectivoInfoComponent implements OnInit {
  @Input() colectivo?: IActivo;
  public id: string | null = null;
  public title = '';

  public reporte: IReporte = null;
  private reportes$: Subscription;
  public activos$: Subscription;
  public datoMapa?: MapaFacilData;

  public loading = true;
  private query: IQueryParam = {};
  constructor(
    public helper: HelperService,
    private listados: ListadosService,
    private paramsService: ParamsService,
  ) {}

  public async listarUltimoReporte(): Promise<void> {
    const query: IQueryParam = {
      filter: JSON.stringify({ idActivo: this.id }),
    };
    this.reportes$?.unsubscribe();
    this.reportes$ = this.listados
      .subscribe<IListado<IReporte>>('ultimoReportes', query)
      .subscribe(async (data) => {
        if (data.datos[0]) this.reporte = data.datos[0];
        console.log(`listado de reportes ${new Date().toISOString()}`, data);
      });
    await this.listados.getLastValue('ultimoReportes', query);
  }

  public datadelmapa() {
    if (this.reporte) {
      const cordinate = OpenLayersService.lonLatToCoordinate(
        (this.reporte?.geojson as IGeoJSONPoint).coordinates[0],
        (this.reporte?.geojson as IGeoJSONPoint).coordinates[1],
      );
      const marker: MarkerMapa = {
        label: 'puntoSimple',
        coor: cordinate,
      };
      const mapaFacil: MapaFacilData = { markers: [marker] };
      this.datoMapa = mapaFacil;
    }
  }

  async ngOnInit() {
    await Promise.all([this.listarUltimoReporte()]);
    this.datadelmapa();
    this.loading = false;
  }
}
