import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { IActivo } from 'modelos/src';
import { IParamEventoUsuario } from '../../../../standalone/config-evento-usuario/crear-editar-config-evento-usuario-dialog/crear-editar-config-evento-usuario-dialog.component';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { ListadosService } from '../../../../../auxiliares/servicios';

@Component({
  selector: 'app-detalles-colectivo',
  templateUrl: './detalles-colectivo.component.html',
  styleUrl: './detalles-colectivo.component.scss',
  standalone: false,
})
export class DetallesColectivoComponent implements OnInit {
  public selectedTab = 0;
  public idColectivo: string;
  public colectivo: IActivo;
  public loading: boolean = true;
  private colectivo$: Subscription;

  public params?: IParamEventoUsuario;
  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  private async listarColectivo() {
    this.colectivo$?.unsubscribe();
    this.colectivo$ = this.listados
      .subscribe<IActivo>('activo', this.idColectivo)
      .subscribe((data) => {
        this.colectivo = data;
        console.log(`listado de activo`, data);
      });
    await this.listados.getLastValue('activo', this.idColectivo);
  }

  public tabChanged(event: MatTabChangeEvent): void {
    // Setea el query param  'tab' con el valor del tab seleccionado
    this.selectedTab = event.index;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { tab: this.selectedTab },
      queryParamsHandling: 'merge',
    });
  }
  public colectivos() {
    this.router.navigate(['main/colectivos/colectivos'], {});
  }

  async ngOnInit(): Promise<void> {
    const params = await firstValueFrom(this.activatedRoute.paramMap);
    this.idColectivo = params.get('id');

    await this.listarColectivo();

    // Los parametros para el componente config evento usuario
    this.params = {
      tipoEntidad: 'Activo',
      agrupacion: 'Entidad',
      idEntidad: this.idColectivo,
      botonCrear: HelperService.getConfigCliente().moduloColectivos?.activo,
      activo: this.colectivo,
      crear: 'Dialog',
    };
    // Obtiene el query param 'tab' y lo asigna al tab seleccionado
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['tab']) {
        this.selectedTab = parseInt(params['tab']);
      }
    });
    this.loading = false;
  }
}
