import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ICategoriaEvento,
  ICodigoDispositivo,
  ICodigosDispositivo,
  ICreateCodigosDispositivo,
  IListado,
  IQueryParam,
  IUpdateCodigosDispositivo,
  TipoDispositivo,
} from 'modelos/src';
import { firstValueFrom, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import { ListadosService } from '../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { CodigosDispositivosService } from '../../../../auxiliares/servicios/http/codigos-dispositivos.service';

@Component({
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-crear-editar-codigos-dispositivos',
  templateUrl: './crear-editar-codigos-dispositivos.component.html',
  styleUrl: './crear-editar-codigos-dispositivos.component.scss',
})
export class CrearEditarCodigosDispositivosComponent
  implements OnInit, OnDestroy
{
  public loading = false;
  public form?: FormGroup;
  public title?: string;
  public id?: string | null;
  public codigosDispositivo?: ICodigosDispositivo;
  public codigosDispositivo$?: Subscription;

  public categoriasEventos: ICategoriaEvento[] = [];
  public categoriasEventos$?: Subscription;

  public tipo?: TipoDispositivo;

  get arrayCodigos() {
    return this.codigosForm?.controls;
  }
  get codigosForm() {
    return this.form?.get('codigos') as FormArray;
  }

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private service: CodigosDispositivosService,
    private route: ActivatedRoute,
  ) {}

  /// Drag and Drop
  public drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.arrayCodigos, event.previousIndex, event.currentIndex);
    // Patch formArray
    this.codigosForm.patchValue(this.arrayCodigos);
    console.log(this.codigosForm.controls);
    for (let i = 0; i < this.codigosForm.length; i++) {
      const codigo = this.codigosForm.at(i).value?.codigo;
      console.log(`codigo`, codigo);
    }
  }

  private addNewCodigo() {
    const codigos = [];
    if (this.codigosDispositivo?.codigos) {
      for (const r of this.codigosDispositivo.codigos) {
        codigos.push(this.addCodigoFormGroup(r));
      }
    } else {
      codigos.push(this.addCodigoFormGroup());
    }
    return codigos;
  }

  private createForm() {
    this.form = new FormGroup({
      nombre: new FormControl(this.codigosDispositivo?.nombre),
      codigos: new FormArray(this.addNewCodigo()),
      tipo: new FormControl(this.tipo),
    });
  }

  public addCodigo() {
    this.codigosForm.push(this.addCodigoFormGroup());
  }

  private addCodigoFormGroup(codigo?: ICodigoDispositivo) {
    return new FormGroup({
      codigo: new FormControl(codigo?.codigo, [Validators.required]),
      descripcion: new FormControl(codigo?.descripcion, [Validators.required]),
      idCategoriaEvento: new FormControl(codigo?.idCategoriaEvento, [
        Validators.required,
      ]),
      mostrarZona: new FormControl(codigo?.mostrarZona || false, [
        Validators.required,
      ]),
      mostrarUsuario: new FormControl(codigo?.mostrarUsuario || false, [
        Validators.required,
      ]),
      armado: new FormControl(codigo?.armado || false, [Validators.required]),
      desarmado: new FormControl(codigo?.desarmado || false, [
        Validators.required,
      ]),
    });
  }

  public deleteEvento(i: number) {
    this.codigosForm.removeAt(i);
  }

  //
  public async onSubmit() {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.id) {
        // Update
        await this.service.update(this.id, data);
        this.helper.notifSuccess('Actualizado correctamente');
      } else {
        // Create
        await this.service.create(data);
        this.helper.notifSuccess('Creado correctamente');
      }
      this.helper.volver();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public getData(): ICreateCodigosDispositivo | IUpdateCodigosDispositivo {
    const data: ICreateCodigosDispositivo | IUpdateCodigosDispositivo =
      this.form?.value;
    return data;
  }

  public async listarCodigoDispositivo(): Promise<void> {
    if (this.id) {
      this.codigosDispositivo$?.unsubscribe();
      this.codigosDispositivo$ = this.listados
        .subscribe<ICodigosDispositivo>('codigosDispositivo', this.id)
        .subscribe((data) => {
          this.codigosDispositivo = data;
          console.log(`listado de codigoAlarma`, data);
        });
      await this.listados.getLastValue('codigosDispositivo', this.id);
    }
  }

  public async listarCategoriasEventos(): Promise<void> {
    const query: IQueryParam = {
      sort: 'nombre',
    };
    this.categoriasEventos$?.unsubscribe();
    this.categoriasEventos$ = this.listados
      .subscribe<IListado<ICategoriaEvento>>('categoriaEventos', query)
      .subscribe((data) => {
        this.categoriasEventos = data.datos;
        console.log(`listado de categoriaEventos`, data);
      });
    await this.listados.getLastValue('categoriaEventos', query);
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    console.log('Tipo', params.get('tipo'));

    this.tipo = (params.get('tipo') as TipoDispositivo) || 'Alarma';
    if (this.id) this.codigosDispositivo = await this.service.getById(this.id);

    this.title = this.id
      ? `Editar códigos de ${this.tipo?.toLowerCase()}`
      : `Crear códigos de ${this.tipo?.toLowerCase()}`;

    await Promise.all([
      this.listarCodigoDispositivo(),
      this.listarCategoriasEventos(),
    ]);
    this.createForm();
  }

  ngOnDestroy(): void {
    this.codigosDispositivo$?.unsubscribe();
  }
}
