import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ICreateCamara,
  IUpdateCamara,
  ICamara,
  IListado,
  IQueryParam,
  IModeloDispositivo,
  ICliente,
  IFilter,
  TipoDispositivo,
  ICanalesCamara,
  TipoHabilitacion,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { CamarasService } from '../../../../../auxiliares/servicios/http/camara.service';
import { ParamsService } from '../../../../../auxiliares/servicios/params.service';
import { DetallesCanalesCamarasComponent } from './detalles-canales/detalles-canales.component';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { CrearEditarClienteComponent } from '../../../administracion/clientes/crear-editar-cliente/crear-editar-cliente.component';
import { CrearEditarModeloDispositivoComponent } from '../../../../standalone/modelos-dispositivos/crear-editar-modelo-dispositivo/crear-editar-modelo-dispositivo.component';

@Component({
  selector: 'app-crear-editar-camara',
  templateUrl: './crear-editar-camara.component.html',
  styleUrl: './crear-editar-camara.component.scss',
  standalone: false,
})
export class CrearEditarCamaraComponent implements OnInit, OnDestroy {
  public loading = false;
  public form?: FormGroup;
  public title?: string;

  public camara?: ICamara;
  public camara$?: Subscription;
  public modelosDispositivos?: IModeloDispositivo[] = [];
  public modelosDispositivos$?: Subscription;
  public clientes?: ICliente[] = [];
  public clientes$?: Subscription;

  public tiposHabilitacion: TipoHabilitacion[] = ['Siempre', 'Con Evento'];

  get canales() {
    return this.form?.get('canales') as FormArray;
  }

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<CrearEditarCamaraComponent>,
    public helper: HelperService,
    private service: CamarasService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private listados: ListadosService,
    public paramsService: ParamsService,
  ) {}

  private createForm() {
    const canales: FormGroup[] = [];

    if (this.camara?.canales) {
      for (const canal of this.camara.canales) {
        canales.push(this.nuevoCanal(canal));
      }
    }

    this.form = new FormGroup({
      identificacion: new FormControl(
        this.camara?.identificacion,
        Validators.required,
      ),
      idModeloDispositivo: new FormControl(this.camara?.idModeloDispositivo),
      idCliente: new FormControl(this.camara?.idCliente, Validators.required),
      host: new FormControl(this.camara?.host, Validators.required),
      puertoHTTP: new FormControl(this.camara?.puertoHTTP, Validators.required),
      puertoRTSP: new FormControl(this.camara?.puertoRTSP, Validators.required),
      usuario: new FormControl(this.camara?.usuario, Validators.required),
      password: new FormControl(this.camara?.password, Validators.required),
      canales: new FormArray(canales),
    });
  }

  public nuevoCanal(data?: ICanalesCamara) {
    const ids: FormGroup[] = [];
    if (data?.ids) {
      for (const idAct of data.ids) {
        ids.push(
          new FormGroup({
            id: new FormControl(idAct.id),
            width: new FormControl(idAct.width),
            height: new FormControl(idAct.height),
          }),
        );
      }
    } else {
      ids.push(
        new FormGroup({
          id: new FormControl(),
          width: new FormControl(),
          height: new FormControl(),
        }),
      );
    }
    return new FormGroup({
      numero: new FormControl(data?.numero, Validators.required),
      nombre: new FormControl(data?.nombre, Validators.required),
      ids: new FormArray(ids, Validators.required),
      descripcion: new FormControl(data?.descripcion),
      tipoHabilitacion: new FormControl(
        data?.tipoHabilitacion || 'Siempre',
        Validators.required,
      ),
    });
  }

  public addCanal() {
    this.canales.push(this.nuevoCanal());
  }

  public async detectarCanales() {
    const res = await this.dialogService.confirm(
      'Detectar Canales',
      `Al detectar, los canales cargados van a ser sobreescritos ¿Continuar?`,
    );
    if (!res) return;
    if (this.camara && this.camara.puertoHTTP && this.camara.host) {
      const res = await this.service.streamingChannels(this.camara._id);
      if (res.StreamingChannelList?.StreamingChannel?.length > 0) {
        const channels = res.StreamingChannelList.StreamingChannel;
        this.canales.clear();
        const canalData: ICanalesCamara = { numero: '0', ids: [] };
        let canalMultipleData: { id: string; width: number; height: number }[] =
          [];
        for (const channel of channels) {
          if (channel.id._text[0] !== canalData.numero) {
            if (canalData.numero !== '0') {
              canalData.ids = canalMultipleData;
              this.canales.push(this.nuevoCanal(canalData));
            }
            canalMultipleData = [];
            canalData.numero = channel.id._text[0];
            canalMultipleData.push({
              id: channel.id._text,
              width: channel.Video.videoResolutionWidth._text,
              height: channel.Video.videoResolutionHeight._text,
            });
          } else {
            canalMultipleData.push({
              id: channel.id._text,
              width: channel.Video.videoResolutionWidth._text,
              height: channel.Video.videoResolutionHeight._text,
            });
          }
        }
        if (canalData.numero !== '0') {
          canalData.ids = canalMultipleData;
          this.canales.push(this.nuevoCanal(canalData));
        }
      }
    }
  }

  public removeCanal(index: number) {
    this.canales.removeAt(index);
  }

  public async detallesCanal(index: number) {
    this.dialog.open(DetallesCanalesCamarasComponent, {
      data: this.canales.at(index).value,
      width: '40vh',
      height: '40vh',
    });
  }
  // Listados

  public async listarModelosDispositivos(): Promise<void> {
    const tipo: TipoDispositivo = 'Cámara';
    const filter: IFilter<IModeloDispositivo> = { tipo };
    const query: IQueryParam = { filter: JSON.stringify(filter) };
    this.modelosDispositivos$?.unsubscribe();
    this.modelosDispositivos$ = this.listados
      .subscribe<IListado<IModeloDispositivo>>('modeloDispositivos', query)
      .subscribe((data) => {
        this.modelosDispositivos = data.datos;
        console.log(`listado de modelos de alarma`, data);
      });
    await this.listados.getLastValue('modeloDispositivos', query);
  }
  public async listarClientes(): Promise<void> {
    const query: IQueryParam = { includeChildren: true };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  //
  public async onSubmit() {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.camara?._id) {
        // Update
        await this.service.update(this.camara._id, data);
        this.helper.notifSuccess('Actualizado correctamente');
        if (this.dialogRef) {
          this.dialogRef.close();
        } else {
          this.helper.volver();
        }
      } else {
        // Create
        this.camara = await this.service.create(data);
        this.createForm();
        this.title = `Editar ${this.camara?.identificacion}`;
        this.helper.notifSuccess('Creado correctamente');
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public getData(): ICreateCamara | IUpdateCamara {
    const data: ICreateCamara | IUpdateCamara = this.form?.value;
    return data;
  }

  public volver() {
    if (this.dialogRef) {
      this.dialogRef.close(this.camara._id);
    } else {
      HelperService.volver();
    }
  }

  public crearCliente() {
    const dialog = this.dialog.open(CrearEditarClienteComponent);
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.form.patchValue({ idCliente: res });
      }
    });
  }
  public crearModeloCamara() {
    const dialog = this.dialog.open(CrearEditarModeloDispositivoComponent, {
      data: { tipo: 'Cámara' },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.form.patchValue({ idModeloDispositivo: res });
      }
    });
  }

  async ngOnInit() {
    this.camara = this.paramsService.getParams()?.['edit'];
    this.createForm();
    if (this.dialogRef && this.paramsService.getParams()?.['desdeAlarma']) {
      this.form?.patchValue({
        idCliente: this.paramsService.getParams()?.['idCliente'],
      });
    }

    await Promise.all([
      this.listarModelosDispositivos(),
      this.listarClientes(),
    ]);

    this.title = this.camara
      ? `Editar ${this.camara?.identificacion}`
      : 'Crear Cámara';
  }

  ngOnDestroy(): void {
    this.modelosDispositivos$?.unsubscribe();
    this.clientes$?.unsubscribe();
    this.camara$?.unsubscribe();
  }
}
