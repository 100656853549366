/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Type,
  ViewChild,
} from '@angular/core';
import { MatSortModule, Sort } from '@angular/material/sort';
import { IQueryParam } from 'modelos/src';
import { DynamicPipe } from '../pipes/dynamic';
import { MatIconModule } from '@angular/material/icon';

import { ColumnSelectComponent } from './column-select/column-select.component';
import { PaginatorComponent } from './paginator/paginator.component';
import {
  FiltroComponent,
  IFiltroTabla,
  IRegExpSearch,
} from './filtro/filtro.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '../button/button.component';

export interface ICrearBoton {
  mostrar: boolean;
  tooltip?: string;
  tipo?: 'material' | 'img';
  icon?: string;
  accion: () => void;
  color?: string;
}

export interface IExportarBoton {
  mostrar: boolean;
  tooltip?: string;
  tipo?: 'material' | 'img';
  icon?: string;
  accion: () => void;
  color?: string;
}

interface IAcciones<T> {
  tipo?: 'material' | 'img';
  icon: string;
  tooltip: string;
  color?: 'primary' | 'accent' | 'warn';
  click: (dato: T) => void;
  disabled?: (dato: T) => boolean;
  oculta?: (dato: T) => boolean;
}

export interface IRowIcon {
  tipo?: 'material' | 'img';
  icon: string;
  tooltip?: string;
  color?: string;
}

export interface IRowLink {
  link: string;
  tooltip?: string;
  class?: string;
  route?: string[] | string;
  click?: () => void;
}

interface IPipe {
  pipe: Type<any>;
  args?: any;
}

export type IColumnas<T> = {
  header: {
    label: string;
    sort?: string;
    sortable?: boolean;
    hidden?: boolean;
  };
  row: {
    field?: keyof T | 'acciones';
    parse?: (dato: T) => string | number | undefined;
    icono?: (dato: T) => IRowIcon;
    html?: (dato: T) => string;
    suffix?: string;
    prefix?: string;
    pipe?: IPipe;
    acciones?: IAcciones<T>[];
    accionesGrouped?: IAcciones<T>[];
    noData?: string;
    claseCss?: (dato: T) => string | undefined;
    links?: (dato: T) => IRowLink[] | undefined;
    tooltip?: (dato: T) => string | undefined;
    lista?: (dato: T) => string[] | undefined;
  };
  footer?: {
    fn: (dato: IColumnas<T>) => string | number | undefined;
    pipe?: IPipe;
    suffix?: string;
    prefix?: string;
  };
};

@Component({
  imports: [
    CommonModule,
    RouterModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    DynamicPipe,
    MatMenuModule,
    ColumnSelectComponent,
    PaginatorComponent,
    FiltroComponent,
    ButtonComponent,
  ],
  selector: 'app-tabla',
  templateUrl: './tabla.component.html',
  styleUrls: ['./tabla.component.scss'],
})
export class TablaComponent implements OnInit, OnChanges {
  @ViewChild('topPaginator') top?: PaginatorComponent;
  @ViewChild('botPaginator') bottom?: PaginatorComponent;

  // If para lso paginators
  @Input() paginatorArriba = false;
  @Input() paginatorAbajo = true;

  // Crear-Exportar
  @Input() public crear?: ICrearBoton;
  @Input() public exportar?: IExportarBoton;
  // Datos de la tabla
  @Input() public totalCount: number = 0;
  @Input() public datos?: any[] = [];
  public dataSource?: MatTableDataSource<any>;

  // Nombre para guardar las columnas
  @Input() public name?: string;
  @Input() public columnas?: IColumnas<any>[];
  @Input() public buttonColumns?: boolean = true;
  public columnsDef: string[] = [];

  // Filtros
  @Input() filtros: IFiltroTabla[] = [];
  @Input() search?: IRegExpSearch;
  @Input() showHijos?: boolean = true;

  // Paginacion
  @Input() pageSizeOptions: number[] = [];
  @Input() pageSize: number = 10;

  // Query
  @Input() public query: IQueryParam = {};
  @Output() public queryChange = new EventEmitter<IQueryParam>();

  constructor(private dom: DomSanitizer) {}

  trackBy(index: number, list: any): string {
    return JSON.stringify(list);
  }

  public async sortChange(event: Sort): Promise<void> {
    const sort = event.active;
    if (event.direction === 'asc') {
      this.query.sort = sort;
    } else {
      const campos = sort.split(' ');
      campos.forEach((campo, index) => {
        campos[index] = `-${campo}`;
      });
      this.query.sort = campos.join(' ');
    }

    this.queryChange.emit(this.query);
  }

  public columnsChange(event: string[]): void {
    window.requestAnimationFrame(() => (this.columnsDef = event));
  }

  public filterChange() {
    // this.query.page = 0;
    this.queryChange.emit(this.query);
  }

  public paginationChange() {
    this.syncPaginators();
    this.queryChange.emit(this.query);
  }

  public syncPaginators() {
    const length = this.totalCount;
    const pageIndex = +(this.query.page || 0);
    const pageSize = +(this.query.limit || 10);
    if (this.bottom) {
      this.bottom.length = length;
      this.bottom.pageIndex = pageIndex;
      this.bottom.pageSize = pageSize;
    }
    if (this.top) {
      this.top.length = length;
      this.top.pageIndex = pageIndex;
      this.top.pageSize = pageSize;
    }
  }

  private setDataSource() {
    this.dataSource = new MatTableDataSource(this.datos);
  }

  public html(value: string) {
    return this.dom.bypassSecurityTrustHtml(value);
  }

  ngOnInit() {
    this.setDataSource();
  }

  ngOnChanges(changes: any) {
    if (changes.datos) {
      this.setDataSource();
    }
  }
}
