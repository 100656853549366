import { UsuariosService } from './../../administracion/usuarios/usuarios.service';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  IReporte,
  ITrackeo,
  IUpdateUsuario,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { LoginService } from '../../../login/login.service';
@Component({
  selector: 'app-mapas-vehiculos',
  templateUrl: './mapas-vehiculos.component.html',
  styleUrl: './mapas-vehiculos.component.scss',
  standalone: false,
})
export class MapasVehiculosComponent implements OnInit, OnDestroy {
  public ctrl = false;
  //Vehiculos
  public reportes?: IReporte[] = [];
  public reportes$?: Subscription;
  public reporteSeleccionado?: IReporte;
  public reporteSeleccionadoLite?: IReporte[] = [];

  // Trackeo
  public trackeo?: ITrackeo;
  private trackeo$?: Subscription;

  // Cant Mapas
  public cantMapas: number;
  // garcha de angular no tiene un for como la gente
  public indiceDeMapa: number[] = [];
  public loadingMapa: boolean = true;
  constructor(
    private listados: ListadosService,
    private usuariosService: UsuariosService,
  ) {}

  /// Vehiculos
  private async listarReportes(): Promise<void> {
    const filter: IFilter<IReporte> = {
      tipo: 'Vehiculo',
    } as any;
    const populate: IPopulate = [
      {
        path: 'activo',
        select: 'identificacion categoria vehiculo funcion',
      },
      {
        path: 'grupo',
        select: 'nombre color',
      },
      {
        path: 'tracker',
        select: 'nombre tipo identificacion',
      },
      {
        path: 'cliente',
        select: 'nombre tipoCliente',
      },
    ];
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      includeChildren: true,
    };

    this.reportes$?.unsubscribe();
    this.reportes$ = this.listados
      .subscribe<IListado<IReporte>>('ultimoReportes', query)
      .subscribe(async (data) => {
        this.reportes = data.datos;
        this.reportes = this.reportes.filter(
          (r) =>
            r.activo?.categoria === 'Vehículo' &&
            r.activo?.vehiculo?.tipo !== 'Colectivo',
        );
        console.log(
          `listado de ultimoReportes vehiculo en mapa vehiculos ${new Date().toISOString()}`,
          this.reportes,
        );
      });
    await this.listados.getLastValue('ultimoReportes', query);
    this.loadingMapa = false;
  }

  //

  public onClose() {
    this.reporteSeleccionado = undefined;
    this.trackeo = undefined;
    this.trackeo$?.unsubscribe();
  }

  private hexToRgba(hex: string, opacity: number): string {
    // Elimina el símbolo '#' si está presente
    hex = hex.replace(/^#/, '');

    // Divide el valor HEX en partes de 2 caracteres
    let r = 0,
      g = 0,
      b = 0;

    if (hex.length === 3) {
      // Si el HEX es del tipo corto (#abc), lo expandimos a 6 caracteres (#aabbcc)
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
      // Si el HEX ya tiene 6 caracteres
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    }

    // Retorna el formato RGBA
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  public indiceMap(i: number) {
    return `map${i}`;
  }

  public reporteSeleccionadoChange(r: any) {
    this.reporteSeleccionado = r;
  }

  public async cambioCantMapas(event: any) {
    this.cantMapas = event;
    let configUsuario = HelperService.getConfigUsuario();
    configUsuario
      ? (configUsuario.cantMapasVehiculos = this.cantMapas)
      : (configUsuario = { cantMapasVehiculos: this.cantMapas });
    const user: IUpdateUsuario = { config: configUsuario };
    console.log(user);
    const res = await this.usuariosService.update(
      LoginService.getUsuario()._id,
      user,
    );
    LoginService.setUsuario(res);
  }

  ///
  async ngOnInit() {
    this.cantMapas = HelperService.getConfigUsuario()?.cantMapasVehiculos
      ? HelperService.getConfigUsuario().cantMapasVehiculos
      : 1;
    await Promise.all([await this.listarReportes()]);
  }

  ngOnDestroy() {
    this.reportes$?.unsubscribe();
    this.trackeo$?.unsubscribe();
  }
}
