import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-flotante-vehiculos',
  templateUrl: './flotante-vehiculos.component.html',
  styleUrl: './flotante-vehiculos.component.scss',
  standalone: false,
})
export class FlotanteVehiculosComponent {
  @Output() vehiculosChange = new EventEmitter();
  @Output() cantMapasChange = new EventEmitter<number>();
  @Input() cantMapas?: number = 1;
  @Input() vehiculos?: number;
  public activosShow: boolean = true;
  constructor() {}

  public onActivosChange() {
    this.activosShow = !this.activosShow;
    this.vehiculosChange.emit();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public cantMapasChangeNg(cant: any) {
    this.cantMapasChange.emit(cant);
  }
}
