import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICliente, ICreateUsuario, IToken, IUsuario } from 'modelos/src';
import { Observable, firstValueFrom } from 'rxjs';
import { API } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient) {}

  public static getAccessToken() {
    return localStorage.getItem('accessToken') || '';
  }

  public static getRefreshToken(): string {
    return localStorage.getItem('refreshToken') || '';
  }

  public static getUsuario(): IUsuario | undefined {
    const user = localStorage.getItem('usuario');
    let usuario: IUsuario | undefined;
    if (user) {
      usuario = JSON.parse(user) as IUsuario;
    }
    return usuario;
  }

  public static getCliente(): ICliente | undefined {
    const c = localStorage.getItem('cliente');
    let cliente: ICliente | undefined;
    if (c) {
      cliente = JSON.parse(c) as ICliente;
    }
    return cliente;
  }

  public static removeLoginInfo() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('accessTokenExpiresAt');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('refreshTokenExpiresAt');
    localStorage.removeItem('usuario');
  }

  public setCliente(cliente?: ICliente) {
    localStorage.setItem('cliente', JSON.stringify(cliente));
  }

  public static setUsuario(usuario: IUsuario) {
    localStorage.setItem('usuario', JSON.stringify(usuario));
  }

  private setUsuario(usuario: IUsuario) {
    localStorage.setItem('usuario', JSON.stringify(usuario));
  }

  public static setLoginInfo(token: IToken) {
    localStorage.setItem('accessToken', token.accessToken!);
    localStorage.setItem(
      'accessTokenExpiresAt',
      token.accessTokenExpiresAt || '',
    );
    localStorage.setItem('refreshToken', token.refreshToken || '');
    localStorage.setItem(
      'refreshTokenExpiresAt',
      token.refreshTokenExpiresAt || '',
    );
    localStorage.setItem('usuario', JSON.stringify(token.user));
  }

  public getUsuarioPropio(token: string): Observable<IUsuario> {
    const url = `${API}/usuarios/propio`;

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http.get<IUsuario>(url, { headers });
  }

  public getClientePropio(token: string): Observable<ICliente> {
    const url = `${API}/clientes/propio`;

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http.get<ICliente>(url, { headers });
  }

  // LOGIN

  private async getToken(usuario: string, password: string): Promise<IToken> {
    const url = `${API}/auth/login`;
    // const body = { username, password, grant_type: 'password' };
    const headers = new HttpHeaders({
      Authorization: 'Basic ' + btoa(`web:web`),
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const body = `username=${usuario}&password=${password}&grant_type=password`;

    const options = {
      headers,
    };

    const token = await firstValueFrom(
      this.http.post<IToken>(url, body, options),
    );
    LoginService.setLoginInfo(token);
    return token;
  }

  private async getRefreshToken(refresh_token: string): Promise<IToken> {
    const url = `${API}/auth/refresh_token`;
    const headers = new HttpHeaders({
      Authorization: 'Basic ' + btoa(`web:web`),
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const body = `refresh_token=${refresh_token}&grant_type=refresh_token`;

    const options = {
      headers,
    };

    const token = await firstValueFrom(
      this.http.post<IToken>(url, body, options),
    );
    LoginService.setLoginInfo(token);
    return token;
  }

  public async registrar(usuario: ICreateUsuario): Promise<IUsuario> {
    const url = `${API}/usuarios`;

    return await firstValueFrom(this.http.post<IUsuario>(url, usuario));
  }

  public async login(username: string, password: string): Promise<void> {
    const resp = await this.getToken(username, password);
    LoginService.setLoginInfo(resp);

    const usuario = await firstValueFrom(
      this.getUsuarioPropio(resp.accessToken!),
    );
    const cliente = await firstValueFrom(
      this.getClientePropio(resp.accessToken!),
    );
    this.setUsuario(usuario);
    this.setCliente(cliente);
  }

  public async refreshToken() {
    const refresh_token = LoginService.getRefreshToken();
    const resp = await this.getRefreshToken(refresh_token);

    LoginService.setLoginInfo(resp);

    const usuario = await firstValueFrom(
      this.getUsuarioPropio(resp.accessToken!),
    );
    const cliente = await firstValueFrom(
      this.getClientePropio(resp.accessToken!),
    );
    this.setUsuario(usuario);
    this.setCliente(cliente);
    return resp;
  }

  /// AUXILIARES

  public isLogged() {
    if (localStorage.getItem('accessToken')) {
      return true;
    }
    return false;
  }
}
