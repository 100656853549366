import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  IServicioContratado,
} from 'modelos/src';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { Subscription } from 'rxjs';
import {
  IRegExpSearch,
  IFiltroTabla,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import {
  IColumnas,
  ICrearBoton,
  IRowIcon,
} from '../../../../../auxiliares/tabla/tabla.component';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { ServiciosContratadosService } from '../servicios-contratados.service';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrl: './servicios.component.scss',
  standalone: false,
})
export class ServiciosComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = ServiciosComponent.name;
  public datos: IServicioContratado[] = [];
  public servicios$?: Subscription;
  public totalCount = 0;
  public columnas?: IColumnas<IServicioContratado>[];
  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeEditar(),
    tooltip: 'Crear Servicio',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  public filtros: IFiltroTabla[] = [];

  public filter: IFilter<IServicioContratado> = {};
  public populate: IPopulate = {};
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
  };

  constructor(
    public helper: HelperService,
    private listados: ListadosService,
    private navigator: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private service: ServiciosContratadosService,
  ) {}

  public async listar(): Promise<void> {
    this.servicios$?.unsubscribe();
    this.servicios$ = this.listados
      .subscribe<
        IListado<IServicioContratado>
      >('servicioContratados', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de servicioContratados`, data);
      });
    await this.listados.getLastValue('servicioContratados', this.query);
  }

  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(dato: IServicioContratado) {
    this.navigator.navigate(['editar', dato._id], {
      relativeTo: this.route,
    });
  }

  public async eliminar(dato: IServicioContratado) {
    const res = await this.dialogService.confirm(
      'Eliminar Servicio',
      `¿Desea eliminar el servicio ${dato.nombre}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(dato._id!);
      this.helper.notifSuccess('Ubicacion eliminada');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    const columnas: IColumnas<IServicioContratado>[] = [
      // Identificacion
      {
        header: {
          label: 'Nombre',
          sortable: true,
        },
        row: {
          field: 'nombre',
        },
      },
      // Categoría
      {
        header: {
          label: 'Costo',
          sortable: true,
        },
        row: {
          field: 'costo',
        },
      },
      // Ícono
      {
        header: {
          label: 'Ícono',
        },
        row: {
          icono(dato) {
            const icono: IRowIcon = {
              tipo: 'img',
              icon: dato.icono,
            };
            return icono;
          },
        },
      },
    ];

    // // Cliente
    // if (HelperService.getTipoCliente() !== 'Final') {
    //   {
    //     columnas.push({
    //       header: {
    //         label: 'Cliente',
    //         sortable: true,
    //       },
    //       row: {
    //         parse(dato) {
    //           return dato.cliente?.nombre;
    //         },
    //       },
    //     });
    //   }
    // }

    // Acciones
    columnas.push({
      header: {
        label: 'Acciones',
      },
      row: {
        acciones: [
          {
            tipo: 'material',
            color: 'accent',
            icon: 'edit',
            tooltip: 'Editar',
            click: (dato) => this.editar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
          {
            tipo: 'material',
            color: 'warn',
            icon: 'delete',
            tooltip: 'Eliminar',
            click: (dato) => this.eliminar(dato),
            disabled: () => !this.helper.puedeEditar(),
          },
        ],
      },
    });

    this.columnas = columnas;
  }

  // Hooks

  async ngOnInit() {
    this.setColumnas();
    await this.listar();
  }

  ngOnDestroy() {
    this.servicios$?.unsubscribe();
  }
}
